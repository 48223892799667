/*
Purpose: list details of student with use datatable
Designed by HY
Date: 06/01/25 
Project: TCMS
*/

import { useState, useEffect } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { Row, Col, Card, Modal, Form, Button } from "react-bootstrap";
import { security_key, BASE_URL } from "../global";
import { Button as Buttonmui } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as yup from "yup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import Select from 'react-select';
function ReportCentreUnenrolment() {
  const location = useLocation();
  useEffect(() => {
    // Store the previous pathname in sessionStorage
    sessionStorage.setItem("previousPagePathname", location.pathname);
  }, [location.pathname]);
  const [loading, setLoading] = useState(true);
  const [month, setMonth] = useState("");
  const [data, setItems] = useState([]);
  const [centre, setCentre] = useState([]);

  const [selectedCentre, setSelectedCentre] = useState("");

  const [isSubmittingPaid, setIsSubmittingPaid] = useState(false);
  const [showModalPaid, setShowModalPaid] = useState(false);
  const [PaidData, setPaidData] = useState(null);
  const toggleModalPaid = () => setShowModalPaid(!showModalPaid);

  useEffect(() => {
    if (PaidData != null && PaidData != "") {
      setShowModalPaid(true);
    }
  }, [PaidData]);
  console.log(PaidData);

  const initialValuesPaid = {
    lf_transacton_id: "",
    fk_cent_key: PaidData?.pk_cent_key ? PaidData.pk_cent_key : "",

    invoice_key: PaidData?.pk_cps_key ? PaidData.pk_cps_key : "",
    pk_super_admin_key: sessionStorage.getItem("key"),
  };

  const validationSchemaPaid = yup.object().shape({
    lf_transacton_id: yup.string().required("Transaction ID is required."),
  });

  const formikPaid = useFormik({
    initialValues: initialValuesPaid,
    validationSchema: validationSchemaPaid,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSubmittingPaid(true);

      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "addLiensceFeePaidCentre",
          ...values,
          // ...PaidData,
        });

        if (response.data.success === true) {
          // Reset the form

          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! The amount has been paid successfully.", {
              autoClose: 3000, // 3 seconds in this example
            });
            setPaidData(null);
            toggleModalPaid();
            fetchData(month, selectedCentre);
            setIsSubmittingPaid(false); // Re-enable the submit button
          } else {
            toast.error(
              "Failure! Unable to paid the amount123. Try again later",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );

            setIsSubmittingPaid(false); // Re-enable the submit button
          }
        } else {
          toast.error(
            "Failure! Unable to paid the amount456. Try again later",
            {
              autoClose: 3000, // 3 seconds in this example
            }
          );

          setIsSubmittingPaid(false); // Re-enable the submit button
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to paid the amount786. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmittingPaid(false); // Re-enable the submit button
      } finally {
        setIsSubmittingPaid(false); // Re-enable the submit button
      }
    },
  });

  useEffect(() => {
    fetchcentreData();
    const currentMonth = new Date().toISOString().slice(0, 7); // Get the current month in "yyyy-mm" format
    setMonth(currentMonth);

    fetchData(currentMonth, selectedCentre);
  }, []);
  const fetchcentreData = async () => {
    try {
      const response = await axios.post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "getAllCenter",
        pk_super_admin_key: sessionStorage.getItem("key"),
      });

      if (response.data.success === true) {
        const centres = response.data.data
          .filter((centre) => centre.visibility === 1)
          .map((centre) => ({
            label: centre.cent_name,
            value: centre.pk_cent_key,
          }));
          const updatedCentres = [
            { value: "", label: "All" }, 
            ...centres,
          ];

          setCentre(updatedCentres);
        // setCentre(centres);
        setLoading(false);
      } else {
        //alert message provide
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const downloadInvoice = async (row) => {
    try {
      let fk_cent_key = row.fk_cent_key;
      const response = await axios.post(
        BASE_URL + "API/api_index.php",
        {
          security_key: security_key,
          invoice_id1: row.pk_cps_id,
          invoice_id: row.pk_cps_key,
          pk_cent_key: row.fk_cent_key,
          mode: "getDownloadcentreLicenseinvoiceCustom",
        },
        {
          responseType: "blob", // Ensure response type is blob for binary data (PDF)
        }
      );

      // Create a blob object from the PDF Blob data received
      const blob = new Blob([response.data], { type: "application/pdf" });

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `IL_00${row.pk_cps_id}.pdf`;

      // Append the link to the body and click it to trigger download
      document.body.appendChild(a);
      a.click();

      // Clean up: remove the temporary link and URL object
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading invoice:", error);
    }
  };
  const downloadReceipt = async (row) => {
    try {
      const response = await axios.post(
        BASE_URL + "API/api_index.php",
        {
          security_key: security_key,
          invoice_id1: row.pk_cps_id,
          invoice_id: row.pk_cps_key,
          pk_cent_key: row.pk_cent_key,
          mode: "getDownloadcentreLicenseReceiptCustom",
        },
        {
          responseType: "blob", // Ensure response type is blob for binary data (PDF)
        }
      );

      // Create a blob object from the PDF Blob data received
      const blob = new Blob([response.data], { type: "application/pdf" });

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `RL_00${row.pk_cps_id}.pdf`;

      // Append the link to the body and click it to trigger download
      document.body.appendChild(a);
      a.click();

      // Clean up: remove the temporary link and URL object
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading invoice:", error);
    }
  };

  const handleGenerate = () => {
    // Trigger data fetch when Generate button is clicked
    fetchData(month, selectedCentre);
  };
  const fetchData = async (selectedMonth, selectedCentre) => {
    setLoading(true);
    setItems([]);
    try {
      const response = await axios.post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "getmonthwisesubjectsessioncentreReports",
        fk_cent_key: selectedCentre,
        month: selectedMonth,
      });

      if (response.data.success === true) {
        setRecords(response.data.data);
        setItems(response.data.data);
        setLoading(false);
      } else {
        //alert message provide
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Sample columns configuration
  const columns = [
    {
      headerName: "Centre Name",
      field: "Centre Name",
      headerClassName: "super-app-theme--header",
      // flex: 1,
      minWidth: 250,
      valueGetter: (params) => {
        return `${
          params.row.cent_name == null || params.row.cent_name == ""
            ? "-"
            : params.row.cent_name
        }`;
      },
    },
    {
      headerName: "Fixed Fees",
      field: "cent_fixed_fee_month",
      headerClassName: "super-app-theme--header",
      // flex: 1,
      minWidth: 190,
      valueGetter: (params) => {
        return `${
          params.row.cent_fixed_fee_month == null ||
          params.row.cent_fixed_fee_month == ""
            ? "0"
            : params.row.cent_fixed_fee_month
        }`;
      },
    },

    {
      headerName: "No. of Mathematics Scheduled sessions",
      field: "total_math_sessions",
      headerClassName: "super-app-theme--header",
      // flex: 1,
      minWidth: 190,
      valueGetter: (params) => {
        return `${
          params.row.total_math_sessions == null ||
          params.row.total_math_sessions == ""
            ? "0"
            : params.row.total_math_sessions
        }`;
      },
    },
    {
      headerName: "No. of English Scheduled Sessions",
      field: "total_english_sessions",
      headerClassName: "super-app-theme--header",
      // flex: 1,
      minWidth: 180,
      valueGetter: (params) => {
        return `${
          params.row.total_english_sessions == null ||
          params.row.total_english_sessions == ""
            ? "0"
            : params.row.total_english_sessions
        }`;
      },
    },
    {
      headerName: "No. of Other Subject Scheduled sessions",
      field: "total_other_sessions",
      headerClassName: "super-app-theme--header",
      // flex: 1,
      minWidth: 190,
      valueGetter: (params) => {
        return `${
          params.row.total_other_sessions == null ||
          params.row.total_other_sessions == ""
            ? "0"
            : params.row.total_other_sessions
        }`;
      },
    },
    {
      headerName: "No. of Combined Subject Scheduled sessions",
      field: "total_combined_sessions",
      headerClassName: "super-app-theme--header",
      // flex: 1,
      minWidth: 190,
      valueGetter: (params) => {
        return `${
          params.row.total_combined_sessions == null ||
          params.row.total_combined_sessions == ""
            ? "0"
            : params.row.total_combined_sessions
        }`;
      },
    },
    {
      headerName: "Payment Currency",
      field: "cent_payable_currency",
      headerClassName: "super-app-theme--header",
      // flex: 1,
      minWidth: 200,
      valueGetter: (params) => {
        return `${
          params.row.cent_payable_currency == null ||
          params.row.cent_payable_currency == ""
            ? "0"
            : params.row.cent_payable_currency
        }`;
      },
    },
    {
      headerName: "Total Amount Payable",
      field: "total_amount_payable",
      headerClassName: "super-app-theme--header",
      // flex: 1,
      minWidth: 180,
      valueGetter: (params) => {
        return `${
          params.row.total_amount_payable == null ||
          params.row.total_amount_payable == ""
            ? "0"
            : params.row.total_amount_payable
        }`;
      },
    },
    {
      headerName: "Payment Status ",
      field: "payment_status",
      headerClassName: "super-app-theme--header",
      // flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        const row = params.row;
        if (parseInt(row.payment_status) === 3) {
          return (
            <div className="d-flex pad-12">
              <span className="invoice-available-msg">-</span>
            </div>
          ); // If payment status is 3, current_month or upcoming month
        } else {
          return (
            <div className="d-flex pad-12">
              {parseInt(row.payment_status) == 2 ? (
                <>
                  <span className="active-btn my-auto cursor-pointer">
                    <DoneIcon className="icon-blue  btn-icon me-1" />
                    Paid
                  </span>
                </>
              ) : (
                <span className="deactive-btn my-auto cursor-pointer">
                  <CloseIcon className=" btn-icon me-1" />
                  Unpaid
                </span>
              )}
            </div>
          );
        }
      },
    },
    {
      headerName: "Action",
      field: "action",
      headerClassName: "super-app-theme--header",
      // flex: 1,
      minWidth: 300,
      renderCell: (params) => {
        const row = params.row;
        if (parseInt(row.payment_status) === 3) {
          return (
            <div className="d-flex pad-12">
              <span className="invoice-available-msg">
                Invoice Available for Past Months
              </span>
            </div>
          ); // If payment status is 3, current_month or upcoming month
        } else {
          return (
            <div className="d-flex pad-12">
              <Buttonmui
                variant="outlined-red"
                onClick={() => downloadInvoice(row)}
                className="primary-blue  btnmr-2 my-2"
              >
                Invoice
              </Buttonmui>
              {parseInt(row.payment_status) == 2 ? (
                <>
                  <Buttonmui
                    variant="outlined-red"
                    onClick={() => downloadReceipt(row)}
                    className="primary-blue  btnmr-2 my-2"
                  >
                    Receipt
                  </Buttonmui>
                  {/* <span
                    className="active-btn my-auto cursor-pointer"
                    onClick={() => handleDeleteClick(row)}
                  >
                    <DoneIcon className="icon-blue  btn-icon me-1" />
                    Paid
                  </span> */}
                </>
              ) : (
                // <span
                //   className="deactive-btn my-auto cursor-pointer"
                //   onClick={() => handleUnPaidClick(row)}
                // >
                //   <CloseIcon className=" btn-icon me-1" />
                //   Unpaid
                // </span>
                <Buttonmui
                  variant="outlined-red"
                  onClick={() => setPaidData(row)}
                  className="primary-blue my-2"
                >
                  Set as Paid{" "}
                </Buttonmui>
              )}
            </div>
          );
        }
      },
    },
  ];

  const [records, setRecords] = useState(data);

  const [selectedDate, setSelectedDate] = useState(dayjs());
  console.log(selectedDate);
  const handleDateChange = (date) => {
    if (date != null) {
      setSelectedDate(date);
      setMonth(date);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" />

      <div className="">
        <Modal
          show={showModalPaid}
          onHide={toggleModalPaid}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className="page-title"> Payment </h5>
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={formikPaid.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col lg="6" md="6" sm="6" className="position-relative">
                  <Card className="rounded-1 mt-2 card-bdr h-100">
                    <Card.Body>
                      <div className="me-0">
                        <div className="">
                          <div className="cus-pyt-dtls">
                            <h6 className="mb-0">Centre Name:</h6>
                            <p className="mb-0">
                              {PaidData
                                ? PaidData.cent_name != null &&
                                  PaidData.cent_name != ""
                                  ? PaidData.cent_name
                                  : ""
                                : ""}
                            </p>
                          </div>
                          <div className="cus-pyt-dtls">
                            <h6 className="mb-0">Billing Month:</h6>
                            <p className="mb-0">
                              {PaidData && PaidData.month_end_date
                                ? new Date(
                                    PaidData.month_end_date
                                  ).toLocaleString("default", {
                                    month: "long",
                                    year: "numeric",
                                  })
                                : ""}
                            </p>
                          </div>

                          <div className="cus-pyt-dtls">
                            <h6>Total Amount:</h6>
                            <p className="mb-0">
                              {PaidData
                                ? PaidData.total_amount_payable != null &&
                                  PaidData.total_amount_payable != ""
                                  ? PaidData.total_amount_payable
                                  : ""
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="6" md="6" sm="6" className="position-relative">
                  <Card className="rounded-1 mt-2 card-bdr h-100">
                    <Card.Body>
                      <div className="me-0">
                        <div className="">
                          <div className="cus-pyt-dtls mb-2">
                            <h6 className="mb-0">Payment Type:</h6>
                            <p className="mb-0">Offline</p>
                          </div>

                          <div className="mt-3">
                            <Row>
                              <Col
                                lg="12"
                                md="12"
                                sm="12"
                                className="position-relative"
                              >
                                <h6 className="form-lable required-field">
                                  Transaction ID
                                </h6>
                                <Form.Control
                                  type="text"
                                  id="lf_transacton_id"
                                  value={formikPaid.values.lf_transacton_id}
                                  onChange={formikPaid.handleChange}
                                  onBlur={formikPaid.handleBlur}
                                  className={
                                    formikPaid.errors.lf_transacton_id &&
                                    formikPaid.touched.lf_transacton_id
                                      ? "input-error"
                                      : "mb-0"
                                  }
                                />
                                {formikPaid.errors.lf_transacton_id &&
                                  formikPaid.touched.lf_transacton_id && (
                                    <p className="error">
                                      {formikPaid.errors.lf_transacton_id}
                                    </p>
                                  )}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="pt-0">
              <div className="mx-auto d-flex gap-2">
                <Button
                  variant="contained"
                  className="primary-gray"
                  onClick={toggleModalPaid}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  className="primary-filled "
                  disabled={isSubmittingPaid}
                >
                  Submit
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* --------------Page Start--------------- */}

        <div className="mb-3 d-flex justify-content-between align-items-center">
          <div>
            <h5 className="page-title"> Licencing Fees by Centres</h5>

            <div role="presentation">
              <Breadcrumbs aria-label="breadcrumb" className="my-breadcrumbs">
                <NavLink underline="hover" color="inherit" to="/calendar">
                  {" "}
                  Home
                </NavLink>
                <NavLink underline="hover" color="inherit" to="/reports">
                  {" "}
                  Reports
                </NavLink>
                <Typography color="text.primary"> Licencing Fee </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div className="d-flex align-items-center gap-2"></div>
        </div>
        {/* Filter Section */}
        {/* <div
        style={{
            display: "flex",
            alignItems: "center", 
            marginBottom: "20px",  
            backgroundColor: "#618dc1",  
            padding: "29px 0 35px 12px",  
          }}
      >
        <label htmlFor="month" style={{ marginRight: "10px" }}>
          Month:
        </label>
        <input
          type="month"
          id="month"
          value={month}
          onChange={(e) => setMonth(e.target.value)}
          style={{
            marginRight: "50px",
            padding: "5px",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        />
         <label htmlFor="month" style={{ marginRight: "10px" }}>
          Month:
        </label>
        <input
          type="month"
          id="month"
          value={month}
          onChange={(e) => setMonth(e.target.value)}
          style={{
            marginRight: "50px",
            padding: "5px",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        />
        
        <button
         onClick={handleGenerate}
          style={{
            padding: "5px 10px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Generate
        </button>
      </div> */}

        {/* --------------Section Start--------------- */}

        <Card className="card my-card mb-3">
          <Card.Body>
            {/* -------------Content Start--------------- */}
            <div
              style={{
                padding: "20px 12px 20px 12px",
              }}
            >
              {/* <label htmlFor="month1" style={{ marginRight: "10px" }}>
                    Month:
                  </label>
                  <input
                    type="month"
                    id="month"
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                    style={{
                      marginRight: "50px",
                      padding: "5px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      width: "100%",
                    }}
                  /> */}

              <div className="row mb-3">
                {/* First Row - Month Label and Input */}
                <div className="col-6 col-md-3 mb-3">
                  <label htmlFor="month1" style={{ marginRight: "10px" }}>
                    Month:
                  </label>
                  <div>
                    {" "}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        views={["year", "month"]} // Limit to year and month selection
                        value={selectedDate}
                        onChange={handleDateChange}
                        format="MM-YYYY" // Display format as Month-YearN
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-root": { height: "50px" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="month1"
                            size="small"
                            readOnly // Make the input read-only to prevent manual entry
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                <div className="col-6 col-md-3 mb-3">
                  <label htmlFor="centre" style={{ marginRight: "10px" }}>
                    Centre:
                  </label>
                  {/* <select
                    id="centre"
                    value={selectedCentre}
                    onChange={(e) => setSelectedCentre(e.target.value)}
                    style={{
                      marginRight: "50px",
                      padding: "12px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      width: "100%", // Full width on smaller screens
                    }}
                  >
                    <option value="">All</option>
                    {centre.map((centre) => (
                      <option key={centre.value} value={centre.value}>
                        {centre.label}
                      </option>
                    ))}
                  </select> */}
                  <Select
                    menuPlacement="auto"
                    options={centre} // Assuming options_centre is the array of options
                    value={centre.find(
                      (option) => option.value === selectedCentre
                    )} // Select the option based on selectedCentre
                    name="centre"
                    id="centre"
                    onChange={(selectedOption) => {
                      setSelectedCentre(selectedOption.value); // Set the selectedCentre value on change
                    }}
                    onBlur={(event) => {
                      // Handle blur event if needed (optional)
                      // setTouched({ ...touched, centre: true });
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        padding: "6px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        width: "100%", // Full width on smaller screens
                      }),
                      menu: (provided) => ({
                        ...provided,
                        marginTop: 0,
                      }),
                    }}
                  />
                </div>

                <div className="col-6  mt-4">
                  <button
                    onClick={handleGenerate}
                    style={{
                      padding: "12px",
                      backgroundColor: "#277ca0",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      width: "25%", // Full width button
                    }}
                  >
                    Generate
                  </button>
                  <OverlayTrigger
                    key={"placement"}
                    overlay={
                      <Tooltip id={`tooltip`} className="custom-tooltip">
                        <div>
                          <strong>Total Amount Payable Calculation:</strong>
                          <p>
                            Total Amount Payable = Fixed Fee + (English Subject Fee *
                            No. of English Subject Sechduled Sessions) +
                            (Mathematics Subject Fee * No. of Mathematics
                            Sechduled Sessions) + (Other Subject Fee * No. of
                            Other Subject Sechduled Sessions)
                          </p>
                        </div>
                      </Tooltip>
                    }
                  >
                    <Button variant="secondary" className="no-bdr">
                      <i className="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                    </Button>
                  </OverlayTrigger>
                </div>
                {/* <div className="col-2 mt-5">
                  <OverlayTrigger
                    key={"placement"}
                    overlay={
                      <Tooltip id={`tooltip`} className="custom-tooltip">
                        <div>
                          <strong>Total Payable Calculation:</strong>
                          <p>
                            Total Payable = Fixed Fee + (English Subject Fee *
                            No. of English Subject Sechduled Sessions) + (Math
                            Fee * No. of Mathematics Sechduled Sessions) +
                            (Other Subject Fee * No. of Other Subject Sechduled
                            Sessions)
                          </p>
                        </div>
                      </Tooltip>
                    }
                  >
                    <Button variant="secondary" className="no-bdr">
                      <i className="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                    </Button>
                  </OverlayTrigger>
                </div> */}
              </div>
            </div>
            <Row>
              <Col xs="12">
                <div className="">
                  <Row className="justify-content-center">
                    <Box
                      sx={{
                        height: "fit-content",
                        width: "100%",
                        "& .actions": {
                          color: "text.secondary",
                        },
                        "& .textPrimary": {
                          color: "text.primary",
                        },
                        "& .super-app-theme--header": {
                          backgroundColor: "#f4f4f4",
                        },
                        "& .MuiDataGrid-row": {
                          border: "1px solid #eff0f2",
                        },
                        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus":
                          {
                            outline: "none !important",
                          },
                        "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                          {
                            outline: "none !important",
                          },
                      }}
                      style={{ height: "100%", width: "100%" }}
                    >
                      <DataGrid
                        sx={{
                          // disable cell selection style
                          ".MuiDataGrid-cell:focus": {
                            outline: "none",
                          },
                          // pointer cursor on ALL rows
                          "& .MuiDataGrid-row:hover": {
                            cursor: "pointer",
                          },
                        }}
                        //sx={{ border: "none", m: 2 }}
                        autoHeight
                        slots={{
                          loadingOverlay: LinearProgress,
                          //noRowsOverlay: CustomNoRowsOverlay,
                          toolbar: GridToolbar,
                          // toolbar: EditToolbar,
                        }}
                        slotProps={{
                          toolbar: {
                            csvOptions: { fileName: "UnenrolmentReport" },
                            printOptions: {
                              pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`,
                              disableToolbarButton: true,
                              hideFooter: true,
                              hideToolbar: true,
                              fileName: "UnenrolmentReport",
                            },
                            records,
                            showQuickFilter: true,
                          },
                        }}
                        initialState={{
                          ...records.initialState,
                          pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        showQuickFilter={true}
                        pageSizeOptions={[10, 25, 50, 100]}
                        loading={loading}
                        rows={records.map((row, index) => ({
                          ...row,
                          id: `row-${index}`,
                        }))}
                        columns={columns}
                        rowHeight={60}
                        density="compact"
                        disableRowSelectionOnClick
                        showCellVerticalBorder={true}
                        showColumnVerticalBorder={true}
                      />
                    </Box>
                  </Row>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export default ReportCentreUnenrolment;
