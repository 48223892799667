/*
Purpose: list details of student with use datatable
Designed by R.Sanjai
Date:24/08/2023 
Project: TCMS


UpdateBy:KTH
updateDate:30/08/2023 
Purpose: include API
*/

import { useState, useCallback, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import React from 'react';
import { FaCheck, FaTimes } from "react-icons/fa";

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

import { security_key, BASE_URL } from "../global";



function ListTutor() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const handleRowClick = async (e) => {
    navigate(`/edit-tutor`, { state: { pk_tut_key: `${e.pk_tut_key}` } });
  }

  const handleTimeSheetClick = async (e) => {
    navigate(`/ListTutorTimeSheet`, { state: { pk_tut_key: `${e.pk_tut_key}` } });
  }
  

  // Sample columns configuration
  const columns = [
    {
      headerName: 'Name',
      field: "Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.tut_fname == null || params.row.tut_fname == '' ? '-' : params.row.tut_fname+' '+params.row.tut_surname}`;
      },

    },
    {
      headerName: 'Phone',
      field: "Phone",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.tut_home_phone == null || params.row.tut_home_phone == '' ? '-' : params.row.tut_home_phone}`;
      },
    },
    {
      headerName: 'Assigned Sessions',
      field: "Sessions",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.count_session == null || params.row.count_session == '' ? '-' : params.row.count_session}`;
      },
    },
    {

      headerName: 'User Name',
      field: "User Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ta_login_id == null || params.row.ta_login_id == '' ? '-' : params.row.ta_login_id}`;
      },
    },
    {

      field: "actions",
      type: "actions",
      headerName: "Active",
      headerClassName: "super-app-theme--header",
      width: 120,
      //flex: 1,
      disableExport: false,
      disableColumnFilter: false,
      disableColumnMenu: false,
      filterable: true,
      sortable: true,
      headerAlign:"left",
      valueGetter: (params) => {
        return `${parseInt(params.row.visibility)==1? 'Active' : 'Inactive'}`;
      },
      renderCell: (params) => {
        return (<span>{params.row.visibility.toString() === "1" ? <FaCheck className="icon-active" /> : <FaTimes className="icon-inactive" />}</span>);
      }

    },
    {

      field: "Time Sheet",
      type: "actions",
      headerName: "Time Sheet",
      headerClassName: "super-app-theme--header",
      width: 250,
      //flex: 1,
      headerAlign:"left",
      renderCell: (params) => {
        const row = params.row;
        return (<Button variant="outlined-red" className="primary-outlined" onClick={() => handleTimeSheetClick(row)} >Time Sheet </Button>);
      }

    },
  ];

  // Sample data source

  //Api call start
  const [data1, setItems] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
        security_key: security_key,
        mode: "getAllTutorByCenter",
        center_key: sessionStorage.getItem('key')
      });
      
      if (response.data.success === true) {
        setRecords(response.data.data);
        setItems(response.data.data);
        setLoading(false);

      } else {
        //alert message provide
        setLoading(false);

      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  //Api call End


  const [records, setRecords] = useState([]);


  return (
    <div className="page-wrapper">

      {/* --------------Page Start--------------- */}


      {/* --------------Section Start--------------- */}

      <div className="mb-3 d-flex justify-content-between align-items-center">
        <div>
          <h5 className='page-title'> Tutors </h5>

          <div role="presentation" >
            <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
              <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>



              <Typography color="text.primary">Tutors</Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div>
          <div className='d-flex align-items-center gap-2'>
            {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}


            <NavLink className="primary-filled rounded-10 p-2" to='/add-tutor'>
              <AddIcon className="btn-icon" /> New Tutor</NavLink>

          </div>
        </div>
      </div>
      <Card className='my-card p-1 bg-white'>

        <Card.Body>

          {/* -------------Content Start--------------- */}

          <Row>
            <Col xs="12">
              <div className="">

                <Row className="justify-content-center">

                



                  {/* --------------Table Content Start--------------- */}
                  <Col sm={12}>

                    <Box
                      sx={{
                        height: "fit-content",
                        width: "100%",
                        "& .actions": {
                          color: "text.secondary",
                        },
                        "& .textPrimary": {
                          color: "text.primary",
                        },
                        "& .super-app-theme--header": {
                          backgroundColor: "#f4f4f4",
                        },
                      }}
                      style={{ height: '100%', width: "100%" }}
                    >
                     <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                        //sx={{ border: "none", m: 2 }}
                        autoHeight
                        slots={{
                          loadingOverlay: LinearProgress,
                          //noRowsOverlay: CustomNoRowsOverlay,
                          toolbar: GridToolbar,
                          // toolbar: EditToolbar,
                        }}
                        slotProps={{
                          toolbar: {
                            csvOptions: { fileName: "TutorList" },
                            printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "TutorList" },
                            records,
                            showQuickFilter: true
                          },


                        }}
                        initialState={{
                          ...records.initialState,
                          pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        showQuickFilter={true}

                        pageSizeOptions={[10, 25, 50, 100]}

                        loading={loading}
                        rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                        columns={columns}
                        rowHeight={60} density="compact"
                          disableRowSelectionOnClick
                          showCellVerticalBorder= {true}
                          showColumnVerticalBorder= {true}
                        onRowClick={(params) => handleRowClick(params.row)}


                      />
                    </Box>



                  </Col>
                  {/* --------------Table Content Start--------------- */}

                </Row>

              </div>
            </Col>
          </Row>

          {/* --------------Content End--------------- */}


        </Card.Body>

      </Card>

      {/* --------------Section End--------------- */}




      {/* --------------Page End--------------- */}

    </div>
  );
}

export default ListTutor;