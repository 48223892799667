/*
Purpose: list details of report with use datatable
Developer: BY KTH
Date:22/08/2023 
Project: TCMS
*/

import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Chart from "react-apexcharts";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { security_key, BASE_URL } from "../global";

function ReportFinanceMonthlyRevenue() {
    const [allActualsBarChartOptions, setAllActualsBarChartOptions] = useState({
        chart: {
            height: 280,
            type: "bar",
            toolbar: {
                show: true,
            },
        },
        grid: {
            show: false,
        },
        tooltip: {
            y: {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return value + "$";
                },
            },
        },
        // other chart options...
    });

    const [allActualsBarChartData, setAllActualsBarChartData] = useState([
        {
            name: "Revenue by Month",
            data: [],
            color: "#004542",
        },
    ]);
    const [loading, setLoading] = useState(false);


    const columns = [
        {
            name: "Bar Chart",
            selector: "bar_chart",
            sortable: true,
        },
        {
            name: "Revenue by Month",
            selector: "revenue_by_month",
            sortable: true,
        },
    ];

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        // setLoading(true);

        try {
            const response = await axios.post(BASE_URL + "API/api_index.php", {
                security_key: security_key,
                mode: "getMonthlyRevenue",
                fk_cent_key: sessionStorage.getItem("key"),
            });
            const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            if (response.data.success === true) {
                
                if(response.data.data.length > 0){

                // const monthlySums = response.data.data.reduce((acc, item) => {
                //   const date = new Date(item.created * 1000);
                //   const month = monthNames[date.getUTCMonth()];
                
                //   if (!acc[month]) {
                //     acc[month] = 0;
                //   }
                
                //   acc[month] += item.amount_captured;
                
                //   return acc;
                // }, {});
                
                // const result = Object.keys(monthlySums).map(month => ({
                //   label: month,
                //   value: monthlySums[month]
                // }));

                        //const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

                    const monthlySums = monthNames.reduce((acc, month) => {
                    acc[month] = 0; // Initialize all months with 0
                    return acc;
                    }, {});

                    response.data.data.forEach(item => {
                    const date = new Date(item.created * 1000);
                    const month = monthNames[date.getUTCMonth()];
                    monthlySums[month] += item.amount;
                    });

                    const result = Object.keys(monthlySums).map(month => ({
                    label: month,
                    value: monthlySums[month]
                    }));
                


                    var months = result.map((row) => row.label);
                    var amounts = result.map((row) => (row.value / 100).toFixed(2));
    
                    setAllActualsBarChartOptions({
                        ...allActualsBarChartOptions,
                        xaxis: {
                            ...allActualsBarChartOptions.xaxis,
                            categories: months,
                        },
                    });
    
                    setAllActualsBarChartData([
                        {
                            ...allActualsBarChartData[0],
                            data: amounts,
                        },
                    ]);
                    setLoading(false);
                }else{
                    
                   
                    setAllActualsBarChartOptions({
                        ...allActualsBarChartOptions,
                        xaxis: {
                            ...allActualsBarChartOptions.xaxis,
                            categories: monthNames,
                        },
                    });
    
                    setAllActualsBarChartData([
                        {
                            ...allActualsBarChartData[0],
                            data: [0,0,0,0,0,0,0,0,0,0,0,0],
                        },
                    ]);

                    setLoading(false);
                }
                
            } else {
                //alert message provide
                setAllActualsBarChartOptions({
                    ...allActualsBarChartOptions,
                    xaxis: {
                        ...allActualsBarChartOptions.xaxis,
                        categories: monthNames,
                    },
                });

                setAllActualsBarChartData([
                    {
                        ...allActualsBarChartData[0],
                        data: [0,0,0,0,0,0,0,0,0,0,0,0],
                    },
                ]);

                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }

        
    };

    

    
    return (
        <div className="">
            {/* --------------Page Start--------------- */}

            <div className="mb-3 d-flex justify-content-between align-items-center">
                <div>
                    <h5 className="page-title"> Monthly Revenue Reports </h5>

                    <div role="presentation">
                        <Breadcrumbs aria-label="breadcrumb" className="my-breadcrumbs">
                            <NavLink underline="hover" color="inherit" to="/calendar">
                                {" "}
                                Home
                            </NavLink>
                            <NavLink underline="hover" color="inherit" to="/reports">
                                {" "}
                                Reports
                            </NavLink>
                            <Typography color="text.primary">Monthly Revenue Reports </Typography>
                        </Breadcrumbs>
                    </div>
                </div>
                <div className="d-flex align-items-center gap-2">
                    {/* <Button onClick={() => navigate('/reports')} className="secondary-filled" variant="contained">
            Back
          </Button> */}
                </div>
            </div>
            {/* --------------Section Start--------------- */}

            <Card className="card my-card mb-3">
                <Card.Body>
                    {/* -------------Content Start--------------- */}

                    <Row>
                        <Col xs="12">
                            <div className="">
                                <Row className="justify-content-center">
                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        <div className="d-flex flex-wrap justify-content-end mb-3">
                                            <div class="form-group has-search my-auto">
                                                {/* <span class="fa fa-search form-control-feedback"></span>
                                                <input type="text" class="form-control mb-0" /> */}
                                            </div>
                                        </div>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}

                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        {/* <DataTable
                                            progressPending={loading}
                                            columns={columns}
                                            data={records}
                                            fixedHeader
                                            pagination
                                            customStyles={customeStyle}
                                            className="cursor-pointer  list-cus-h"
                                        /> */}
                                        {loading ? (
                                            <div className="d-flex justify-content-center">
                                                <CircularProgress />
                                            </div>
                                        ) : (
                                            <Chart
                                                options={allActualsBarChartOptions}
                                                series={allActualsBarChartData}
                                                height={350}
                                                type="bar"
                                                className="apex-charts"
                                            />
                                        )}
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    {/* --------------Content End--------------- */}
                </Card.Body>
            </Card>

            {/* --------------Section End--------------- */}

            {/* --------------Page End--------------- */}
        </div>
    );
}

export default ReportFinanceMonthlyRevenue;
