/*
Purpose: list details of report with use datatable
Developer: BY KTH
Date:22/08/2023 
Project: TCMS
*/

import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";

import axios from "axios";
import { security_key, BASE_URL } from "../global";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

function ReportFinanceDebtor() {
    const [loading, setLoading] = useState(false);
    

    const columns = [
        {
            headerName: 'Student ID',
            field: "pk_student_id",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
              return `${params.row.pk_student_id && params.row.pk_student_id  != '' ? '#'+params.row.pk_student_id : '-'}`;
            },
        },
        {
            headerName: 'Student Name',
            field: "student_name",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.student_name && params.row.student_name != '' ? params.row.student_name : '-'}`;
            },
        },
        {
            headerName: "Parents / Guardians Name",
            field: "parent_name",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.parent_name && params.row.parent_name != '' ?  params.row.parent_name : '-'}`;
            },
        },
        {
            headerName: "Amount Owing(" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
            field: "amount",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
              return `${params.row.nextPaymentAmount && params.row.nextPaymentAmount != '' ?  params.row.nextPaymentAmount.toFixed(2) : (0).toFixed(2)}`;
            },
        },
        {
            headerName: "Payment Gateway (Y/N)",
            field: "peyment",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return 'Yes';
              },
            renderCell: (params) => {
                return <p className="badge cus-badge-Success mb-0">Yes</p>;
            },
        },
        {
            headerName: "Financial Assistance (Y/N)",
            field: "assistence",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return 'NA';
              },
        },
    ];

    const [data, setItems] = useState([]);

    const [records, setRecords] = useState(data);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);

        try {
            const response = await axios.post(BASE_URL + "API/api_index.php", {
                security_key: security_key,
                mode: "getDebtorReports",
                fk_cent_key: sessionStorage.getItem("key"),
            });
          
            if (response.data.success === true) {
                setRecords(response.data.data);
                setItems(response.data.data);
                setLoading(false);
            } else {
                //alert message provide
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    return (
        <div className="">
            {/* --------------Page Start--------------- */}

            <div className="mb-3 d-flex justify-content-between align-items-center">
                <div>
                    <h5 className="page-title"> Debtor Reports </h5>

                    <div role="presentation">
                        <Breadcrumbs aria-label="breadcrumb" className="my-breadcrumbs">
                            <NavLink underline="hover" color="inherit" to="/calendar">
                                {" "}
                                Home
                            </NavLink>
                            <NavLink underline="hover" color="inherit" to="/reports">
                                {" "}
                                Reports
                            </NavLink>
                            <Typography color="text.primary">Debtor Reports </Typography>
                        </Breadcrumbs>
                    </div>
                </div>
                <div className="d-flex align-items-center gap-2">
                    {/* <Button onClick={() => navigate('/reports')} className="secondary-filled" variant="contained">
            Back
          </Button> */}
                </div>
            </div>
            {/* --------------Section Start--------------- */}

            <Card className="card my-card mb-3">
                <Card.Body>
                    {/* -------------Content Start--------------- */}

                    <Row>
                        <Col xs="12">
                            <div className="">
                                <Row className="justify-content-center">
                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        <Box
                                            sx={{
                                                height: "fit-content",
                                                width: "100%",
                                                "& .actions": {
                                                    color: "text.secondary",
                                                },
                                                "& .textPrimary": {
                                                    color: "text.primary",
                                                },
                                                "& .super-app-theme--header": {
                                                    backgroundColor: "#f4f4f4",
                                                },
                                                "& .MuiDataGrid-row": {
                                                    border: "1px solid #eff0f2",
                                                },
                                                "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                                                    outline: "none !important",
                                                },
                                                "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus": {
                                                    outline: "none !important",
                                                },
                                            }}
                                            style={{ height: "100%", width: "100%" }}
                                        >
                                            <DataGrid
                                                sx={{
                                                    // disable cell selection style
                                                    ".MuiDataGrid-cell:focus": {
                                                        outline: "none",
                                                    },
                                                    // pointer cursor on ALL rows
                                                    "& .MuiDataGrid-row:hover": {
                                                        cursor: "pointer",
                                                    },
                                                }}
                                                //sx={{ border: "none", m: 2 }}
                                                autoHeight
                                                slots={{
                                                    loadingOverlay: LinearProgress,
                                                    //noRowsOverlay: CustomNoRowsOverlay,
                                                    toolbar: GridToolbar,
                                                    // toolbar: EditToolbar,
                                                }}
                                                slotProps={{
                                                    toolbar: {
                                                        csvOptions: { fileName: "StudentAssesmentReport" },
                                                        printOptions: {
                                                            pageStyle: `@media print {
                                                                            @page {
                                                                                size: A4 landscape;
                                                                                margin: 0;
                                                                            }
                                                                            }`,
                                                            disableToolbarButton: true,
                                                            hideFooter: true,
                                                            hideToolbar: true,
                                                            fileName: "StudentAssesmentReport",
                                                        },
                                                        records,
                                                        showQuickFilter: true,
                                                    },
                                                }}
                                                initialState={{
                                                    ...records.initialState,
                                                    pagination: { paginationModel: { pageSize: 10 } },
                                                }}
                                                showQuickFilter={true}
                                                pageSizeOptions={[10, 25, 50, 100]}
                                                loading={loading}
                                                rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                                                columns={columns}
                                                rowHeight={60}
                                                density="compact"
                                                disableRowSelectionOnClick
                                                showCellVerticalBorder={true}
                                                showColumnVerticalBorder={true}
                                            />
                                        </Box>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    {/* --------------Content End--------------- */}
                </Card.Body>
            </Card>

            {/* --------------Section End--------------- */}

            {/* --------------Page End--------------- */}
        </div>
    );
}

export default ReportFinanceDebtor;
