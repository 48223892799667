/*
Purpose: list details of student with use datatable
Designed by HY
Date: 06/0/25 
Project: TCMS
*/

import { useState, useEffect } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { security_key, BASE_URL } from "../global";

import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
function ReportCentreUnenrolment() {
  const location = useLocation();
  useEffect(() => {
    // Store the previous pathname in sessionStorage
    sessionStorage.setItem("previousPagePathname", location.pathname);
  }, [location.pathname]);
  const [loading, setLoading] = useState(true);
  const [month, setMonth] = useState("");
  const [data, setItems] = useState([]);

  const [records, setRecords] = useState(data);

  useEffect(() => {
    const currentMonth = new Date().toISOString().slice(0, 7); // Get the current month in "yyyy-mm" format
    setMonth(currentMonth);

    fetchData(currentMonth);
  }, []);
  const handleGenerate = () => {
    // Trigger data fetch when Generate button is clicked
    fetchData(month);
  };
  const fetchData = async (selectedMonth) => {
    setLoading(true);
    try {
      const response = await axios.post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "getmonthwisesubjectsessionReports",
        fk_cent_key: sessionStorage.getItem("key"),
        month: selectedMonth,
      });

      if (response.data.success === true) {
        setRecords(response.data.data);
        setItems(response.data.data);
        setLoading(false);
      } else {
        //alert message provide
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const calculateTotalSessions = () => {
    let totalMath = 0;
    let totalEnglish = 0;
    let totalOther = 0;
    let totalCombined = 0;

    records.forEach((row) => {
      totalMath += parseInt(row.Maths_Count || 0);
      totalEnglish += parseInt(row.English_Count || 0);
      totalOther += parseInt(row.Other_Count || 0);
      totalCombined += parseInt(row.Combined_Count || 0);
    });

    return { totalMath, totalEnglish, totalOther, totalCombined };
  };

  // Calculate totals for the current page
  const { totalMath, totalEnglish, totalOther, totalCombined } = calculateTotalSessions();

  // Add the total row for summing the sessions
  const totalRow = {
    id: "total-row",
    "": "Total",
    math_sessions: totalMath,
    english_sessions: totalEnglish,
    other_sessions: totalOther,
    combined_sessions: totalCombined,
  };

  // Sample columns configuration
  const columns = [
    {
      headerName: "Student Name",
      field: "Student Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.st_first_name || ""} ${
          params.row.st_surname || ""
        }`;
      },
    },
    {
      headerName: "No. of Scheduled Mathematics sessions",
      field: "Maths_Count",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.Maths_Count == null || params.row.Maths_Count == ""
            ? "-"
            : params.row.Maths_Count
        }`;
      },
    },
    {
      headerName: "No. of Scheduled English sessions",
      field: "English_Count",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.English_Count == null ||
          params.row.English_Count == ""
            ? "-"
            : params.row.English_Count
        }`;
      },
    },
    {
      headerName: "No. of Scheduled Other Subject sessions",
      field: "Other_Count",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.Other_Count == null || params.row.Other_Count == ""
            ? "-"
            : params.row.Other_Count
        }`;
      },
    },
    {
      headerName: "No. of Scheduled Combined Subject sessions",
      field: "Combined_Count",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.Combined_Count == null || params.row.Combined_Count == ""
            ? "-"
            : params.row.Combined_Count
        }`;
      },
    },
  ];


   const [selectedDate, setSelectedDate] = useState(dayjs());
    console.log(selectedDate)
    const handleDateChange = (date) => {
      if (date != null) {
        setSelectedDate(date);
        setMonth(date);
      }
    };

  return (
    <div className="">
      {/* --------------Page Start--------------- */}

      <div className="mb-3 d-flex justify-content-between align-items-center">
        <div>
          <h5 className="page-title"> Monthly Scheduled Sessions</h5>

          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb" className="my-breadcrumbs">
              <NavLink underline="hover" color="inherit" to="/calendar">
                {" "}
                Home
              </NavLink>
              <NavLink underline="hover" color="inherit" to="/reports">
                {" "}
                Reports
              </NavLink>
              <Typography color="text.primary">
                {" "}
                Monthly Scheduled Sessions{" "}
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div className="d-flex align-items-center gap-2"></div>
      </div>
      {/* Filter Section */}

      {/* --------------Section Start--------------- */}

      <Card className="card my-card mb-3">
        <Card.Body>
          {/* -------------Content Start--------------- */}
          <div
            style={{
              padding: "20px 12px 20px 12px",
            }}
          >
            <div className="row mb-3">
              {/* First Row - Month Label and Input */}
              <div className="col-6 col-md-3 mb-3">
                <label htmlFor="month1" style={{ marginRight: "10px" }}>
                  Month:
                </label>
                <div>
                  {" "}
                  <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DatePicker
                      views={["year", "month"]} // Limit to year and month selection
                      value={selectedDate}
                      onChange={handleDateChange}
                      format="MM-YYYY" // Display format as Month-YearN
                      // sx={{ width: "100%" }}
                      sx={{ width: "100%", "& .MuiInputBase-root": { height: "50px" } }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="month1"
                          size="small"
                          readOnly // Make the input read-only to prevent manual entry
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>

              <div className="col-6 col-md-3 mt-4">
                <button
                  onClick={handleGenerate}
                  style={{
                    padding: "12px",
                    backgroundColor: "#277ca0",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    width: "50%", // Full width button
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
          <Row>
            <Col xs="12">
              <div className="">
                <Row className="justify-content-center">
                  <Box
                    sx={{
                      height: "fit-content",
                      width: "100%",
                      "& .actions": {
                        color: "text.secondary",
                      },
                      "& .textPrimary": {
                        color: "text.primary",
                      },
                      "& .super-app-theme--header": {
                        backgroundColor: "#f4f4f4",
                      },
                      "& .MuiDataGrid-row": {
                        border: "1px solid #eff0f2",
                      },
                      "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus":
                        {
                          outline: "none !important",
                        },
                      "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                        {
                          outline: "none !important",
                        },
                    }}
                    style={{ height: "100%", width: "100%" }}
                  >
                    <DataGrid
                      sx={{
                        // disable cell selection style
                        ".MuiDataGrid-cell:focus": {
                          outline: "none",
                        },
                        // pointer cursor on ALL rows
                        "& .MuiDataGrid-row:hover": {
                          cursor: "pointer",
                        },
                      }}
                      //sx={{ border: "none", m: 2 }}
                      autoHeight
                      slots={{
                        loadingOverlay: LinearProgress,
                        //noRowsOverlay: CustomNoRowsOverlay,
                        toolbar: GridToolbar,
                        // toolbar: EditToolbar,
                      }}
                      slotProps={{
                        toolbar: {
                          csvOptions: { fileName: "sessioncountreport" },
                          printOptions: {
                            pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`,
                            disableToolbarButton: true,
                            hideFooter: true,
                            hideToolbar: true,
                            fileName: "sessioncountreport",
                          },
                          records,
                          showQuickFilter: true,
                        },
                      }}
                      initialState={{
                        ...records.initialState,
                        pagination: { paginationModel: { pageSize: 10 } },
                      }}
                      showQuickFilter={true}
                      pageSizeOptions={[10, 25, 50, 100]}
                      loading={loading}
                      rows={records.map((row, index) => ({
                        ...row,
                        id: `row-${index}`,
                      }))}
                      columns={columns}
                      rowHeight={60}
                      density="compact"
                      disableRowSelectionOnClick
                      showCellVerticalBorder={true}
                      showColumnVerticalBorder={true}
                    />
                    <Row style={{ marginTop: "20px" }}>
                      <Col xs="12">
                        <span
                          style={{ fontSize: "16px", fontWeight: "normal" }}
                        >
                          Total Number of Sessions Scheduled
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <div style={{ marginTop: "20px" }}>
                          <table
                            style={{
                              width: "100%",
                              border: "1px solid #eff0f2",
                              borderCollapse: "collapse",
                            }}
                          >
                            <thead>
                              <tr>
                                <th style={{ minWidth: "250px" }}></th>
                                <th>Total of Scheduled Mathematics sessions</th>
                                <th>Total of Scheduled English sessions</th>
                                <th>
                                  Total of Scheduled Other Subject sessions
                                </th>
                                <th>
                                  Total of Scheduled Combined Subject sessions
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  fontWeight: "normal",
                                }}
                              >
                                <td>Total</td>
                                <td>{totalRow.math_sessions}</td>
                                <td>{totalRow.english_sessions}</td>
                                <td>{totalRow.other_sessions}</td>
                                <td>{totalRow.combined_sessions}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>
                  </Box>
                </Row>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ReportCentreUnenrolment;
