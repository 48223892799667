/*
Purpose: list details of student with use datatable
Designed by R.Sanjai
Date:24/08/2023 
Project: TCMS


UpdateBy:KTH
updateDate:30/08/2023 
Purpose: include API
*/

import { useState, useEffect } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import React from 'react';
import Moment from 'moment';

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

import { security_key, BASE_URL } from "../global";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';



function ListTutorTimeSheet() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [Id, setId] = useState(null);
  const [data1, setItems] = useState([]);
  const [tutorData, setTutorData] = useState([]);

  const handleViewPaymentClick = () => {
    // You can use the 'id' value here for further processing
    navigate(`/ListTutorPayment`, { state: { pk_tut_key: `${Id}` } });

  };

  const location = useLocation();
    useEffect(() => {

    const { pk_tut_key } = location.state || {};
    const extractedTId = pk_tut_key;
        if (extractedTId !== '' && extractedTId !== undefined) {
            setId(extractedTId);
            fetchData(extractedTId);
        }else{
          navigate("/list-tutor");
        }
    }, [location]);


  const fetchData = async (id) => {


    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
          security_key: security_key,
          mode: "getSingleTutor_new",
          center_key: sessionStorage.getItem('key'),
          pk_tut_key: id
      });

      if (response.data.success === true) {
          if (response.data.data.length > 0) {
              setTutorData(response.data.data[0]);

              
          }

      } else {
          // Handle unsuccessful response, e.g., show an alert to the user.
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
        security_key: security_key,
        mode: "getTutorTimeSheet",
        center_key: sessionStorage.getItem('key'),
        pk_tut_key: id
      });
      
      if (response.data.success === true) {
        setRecords(response.data.data);
        setItems(response.data.data);
        setLoading(false);

      } else {
        //alert message provide
        setLoading(false);

      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  //Api call End
  

  // Sample columns configuration
  const columns = [
    {
      headerName: 'S.No',
      field: "id",
      headerClassName: "super-app-theme--header",
      flex: 1
      // renderCell: (params) => params.node && params.node.rowNode
      // ? parseInt(params.node.rowNode.id.replace('row-', ''), 10) + 1
      // : ''
    


      // valueGetter: (params) => {
      //   return `${params.row.tut_fname == null || params.row.tut_fname == '' ? '-' : params.row.tut_fname+' '+params.row.tut_surname}`;
      // },

    },
    {
      headerName: 'Date',
      field: "Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ses_date == null || params.row.ses_date == '' ? '-' : Moment(params.row.ses_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }
  
        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');
  
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },
    },
    {
      headerName: 'Total Sessions',
      field: "Total Sessions",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.session_Total_count == null || params.row.session_Total_count == '' ? '0' : params.row.session_Total_count}`;
      },
    },
    // {
    //   headerName: 'Total Sessions Name',
    //   field: "Total Sessions Name",
    //   headerClassName: "super-app-theme--header",
    //   flex: 1,
    //   valueGetter: (params) => {
    //     return `${params.row.session_Total == null || params.row.session_Total == '' ? '-' : params.row.session_Total}`;
    //   },
    // },
    {

      headerName: 'Completed Session',
      field: "Completed Session",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.session_completed_count == null || params.row.session_completed_count == '' ? '0' : params.row.session_completed_count}`;
      },
    },
    // {
    //   headerName: 'Completed Session Name',
    //   field: "Completed Session Name",
    //   headerClassName: "super-app-theme--header",
    //   flex: 1,
    //   valueGetter: (params) => {
    //     return `${params.row.session_completed == null || params.row.session_completed == '' ? '-' : params.row.session_completed}`;
    //   },
    // },
    {
      headerName: 'Total Student',
      field: "Total Student",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.stu_ses_complete_total_count == null || params.row.stu_ses_complete_total_count == '' ? '-' : params.row.stu_ses_complete_total_count}`;
      },
    },
    {
      headerName: 'Attend Student',
      field: "Attend Student",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.stu_ses_complete_count == null || params.row.stu_ses_complete_count == '' ? '-' : params.row.stu_ses_complete_count}`;
      },
    }
    
  ];

  // Sample data source



  const [records, setRecords] = useState(data1);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();


  const handleStartDateChange = (event) => {
    const newStartDate = dayjs(event, 'YYYY-MM-DD').format('YYYY-MM-DD');
    if (endDate && new Date(endDate).getTime() < new Date(newStartDate).getTime()) {
      // You can handle this validation error, for example, by not updating the state
     // console.error('End date cannot be earlier than start date');
     setStartDate('');
      return;
  }
    setStartDate(newStartDate);
    handleDateFilter(newStartDate, endDate);
};

const handleEndDateChange = (event) => {
    //const newEndDate = event.target.value;
    const newEndDate = dayjs(event, 'YYYY-MM-DD').format('YYYY-MM-DD');
     
      if (startDate && new Date(newEndDate).getTime() < new Date(startDate).getTime()) {
       
        setEndDate('');
        return;
    }
    setEndDate(newEndDate);
    handleDateFilter(startDate, newEndDate);
};

const handleDateFilter = (start, end) => {
    const filterStartDate = start ? new Date(start).getTime() : null;
    const filterEndDate = end ? new Date(end).getTime() : null;

    const filteredData = data1.filter((record) => {
        const lessonStartDate = new Date(record.ses_date).getTime();
        const lessonEndDate = new Date(record.ses_date).getTime();

        return (
            (filterStartDate === null || lessonStartDate >= filterStartDate) &&
            (filterEndDate === null || lessonEndDate <= filterEndDate)
        );
    });

    setRecords(filteredData);
   
};

const handleDateReset = () => {
    
    setStartDate('');
    setEndDate('');
    setRecords(data1);

};

  

  return (
    <div className="page-wrapper">

      {/* --------------Page Start--------------- */}


      {/* --------------Section Start--------------- */}

      <div className="mb-3 d-flex justify-content-between align-items-center">
        <div>
          <h5 className='page-title'> Tutor Time Sheet</h5>

          <div role="presentation" >
            <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
              <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>



              <Typography color="text.primary">Tutor Time Sheet</Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div>
          <div className='d-flex align-items-center gap-2'>
            {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}

                <Button variant="contained"  className="primary-filled" onClick={handleViewPaymentClick} >View Payments</Button>
            {/* <NavLink className="primary-filled rounded-10 p-2"  onClick={handleViewPaymentClick}>
              <AddIcon className="btn-icon" />View Payments</NavLink> */}

          </div>
        </div>
      </div>
      <Card className='my-card p-1 bg-white'>

        <Card.Body>
          <Stack gap={3} >
                <div className="mb-3">

                    <div className="cus-les-sec">

                        <div className='cus-les-left'>
                            <div className="cus-les-sd">
                                <Form>

                                    {/* --------------Start Date--------------- */}
                                    <Form.Group className="form-group d-flex align-items-center" controlId="formsdate">
                                        <Form.Label className="pe-2 mb-0">
                                            <p className="mb-0">Start Date</p>
                                        </Form.Label>
                                        <Col className="">
                                            {/* <input type="date" min={"2023-01-01"} max={endDate} className="form-control mb-0" onChange={handleStartDateChange} value={startDate} /> */}
                                            <LocalizationProvider className={'form-control mb-0'} dateAdapter={AdapterDayjs}>
                                                <DatePicker  views={['year', 'month', 'day']}
                                                    value={dayjs(startDate, 'YYYY-MM-DD')}
                                                    onChange={handleStartDateChange}
                                                    maxDate={dayjs(endDate, 'YYYY-MM-DD')}
                                                    format="DD-MM-YYYY"
 />
                                            </LocalizationProvider>
                                        </Col>
                                    </Form.Group>

                                </Form>
                            </div>

                            <div className="cus-les-ed">
                                <Form>

                                    {/* --------------End Date--------------- */}
                                    <Form.Group className="form-group d-flex align-items-center" controlId="formedate">
                                        <Form.Label className="pe-2 mb-0">
                                            <p className="mb-0">End Date</p>
                                        </Form.Label>
                                        <Col className="">
                                            {/* <input type="date" min={startDate} max={new Date().toISOString().split("T")[0]} className="form-control mb-0" onChange={handleEndDateChange} value={endDate} /> */}
                                            <LocalizationProvider className={'form-control mb-0'} dateAdapter={AdapterDayjs}>
                                                <DatePicker  views={['year', 'month', 'day']}
                                                    value={dayjs(endDate, 'YYYY-MM-DD')}
                                                    onChange={handleEndDateChange}
                                                    minDate={dayjs(startDate, 'YYYY-MM-DD')}
                                                    format="DD-MM-YYYY"
      />
                                            </LocalizationProvider>
                                        </Col>
                                    </Form.Group>

                                </Form>
                            </div>
                            <div className="cus-les-rst">
                                <Button variant="secondary" className="btn-sec-outline" onClick={handleDateReset}>
                                    Reset
                                </Button>
                            </div>
                        </div>
                        <div className='cus-les-right'>
                          

                            <div className="cus-les-rst">
                            <span className='ml-5 mt-4'><h5>{tutorData ? tutorData.tut_fname != null && tutorData.tut_fname != '' ? tutorData.tut_fname + ' ' + tutorData.tut_surname  : '' : ''}</h5></span>
                            </div>
                        </div>
                    </div>
                </div>
            </Stack>
          {/* -------------Content Start--------------- */}

          <Row>
            <Col xs="12">
              <div className="">

                <Row className="justify-content-center">

                



                  {/* --------------Table Content Start--------------- */}
                  <Col sm={12}>

                    <Box
                      sx={{
                        height: "fit-content",
                        width: "100%",
                        "& .actions": {
                          color: "text.secondary",
                        },
                        "& .textPrimary": {
                          color: "text.primary",
                        },
                        "& .super-app-theme--header": {
                          backgroundColor: "#f4f4f4",
                        },
                      }}
                      style={{ height: '100%', width: "100%" }}
                    >
                     <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                        //sx={{ border: "none", m: 2 }}
                        autoHeight
                        slots={{
                          loadingOverlay: LinearProgress,
                          //noRowsOverlay: CustomNoRowsOverlay,
                          toolbar: GridToolbar,
                          // toolbar: EditToolbar,
                        }}
                        slotProps={{
                          toolbar: {
                            csvOptions: { fileName: "TimeSheetList" },
                            printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "TimeSheetList" },
                            records,
                            showQuickFilter: true
                          },


                        }}
                        initialState={{
                          ...records.initialState,
                          pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        showQuickFilter={true}

                        pageSizeOptions={[10, 25, 50, 100]}

                        loading={loading}
                        rows={records.map((row, index) => ({ ...row, id: `${index+1}` }))}
                        columns={columns}
                        rowHeight={60} density="compact"
disableRowSelectionOnClick
showCellVerticalBorder= {true}
showColumnVerticalBorder= {true}
                        //onRowClick={(params) => handleRowClick(params.row)}


                      />
                    </Box>



                  </Col>
                  {/* --------------Table Content Start--------------- */}

                </Row>

              </div>
            </Col>
          </Row>

          {/* --------------Content End--------------- */}


        </Card.Body>

      </Card>

      {/* --------------Section End--------------- */}




      {/* --------------Page End--------------- */}

    </div>
  );
}

export default ListTutorTimeSheet;