/*
Purpose: list details of report with use datatable
Developer: BY KTH
Date:22/08/2023 
Project: TCMS
*/



import { useState, useEffect } from "react";
import { useLocation, NavLink } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import {
  security_key, BASE_URL
} from "../global";

function ReportStudentLogin() {
  const location = useLocation();
  useEffect(() => {
    // Store the previous pathname in sessionStorage
    sessionStorage.setItem('previousPagePathname', location.pathname);
  }, [location.pathname]);
  const [loading, setLoading] = useState(true);

  const [data, setItems] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
        security_key: security_key,
        mode: "getStudentLoginReports",
        fk_cent_key: sessionStorage.getItem('key')
      });
     
      if (response.data.success === true) {
        setRecords(response.data.data);
        setItems(response.data.data);
        setLoading(false);
      } else {
        //alert message provide
        setLoading(false);
      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  //Api call End

  const columns = [
    {
      headerName: 'ID#',
      field: "pk_student_id",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.pk_student_id == null || params.row.pk_student_id == '' ? '-' : params.row.pk_student_id}`;
      },

    },
    {
      headerName: 'Student Name',
      field: "Student Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.st_first_name || ''} ${params.row.st_surname || ''}`;
      },

    },
    {

      headerName: 'Username',
      field: "sa_login_id",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sa_login_id == null || params.row.sa_login_id == '' ? '-' : params.row.sa_login_id}`;
      },
    },  {

      headerName: 'Password',
      field: "sa_direct_password",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sa_direct_password == null || params.row.sa_direct_password == '' ? '-' : params.row.sa_direct_password ==null || params.row.sa_direct_password ==''? '-' :params.row.sa_direct_password}`;
      },
    }, 

  ];


  const [records, setRecords] = useState(data);



  return (
    <div className="">

      {/* --------------Page Start--------------- */}

      <div className="mb-3 d-flex justify-content-between align-items-center">
        <div>
          <h5 className='page-title'> Student Login Reports  </h5>

          <div role="presentation" >
            <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
              <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>
              <NavLink underline="hover" color="inherit" to='/reports'> Reports</NavLink>
              <Typography color="text.primary">Login Reports </Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div className='d-flex align-items-center gap-2'>
          {/* <Button onClick={() => navigate('/reports')} className="secondary-filled" variant="contained">
            Back
          </Button> */}

        </div>

      </div>
      {/* --------------Section Start--------------- */}

      <Card className='card my-card mb-3'>

        <Card.Body>

          {/* -------------Content Start--------------- */}

          <Row>
            <Col xs="12">
              <div className="">

                <Row className="justify-content-center">

                  {/* --------------Table Content Start--------------- */}
                  {/* <Col sm={12}>
  
                                      <div className="d-flex flex-wrap justify-content-between mb-3">
                                          <CSVLink
                                            data={csvData}
                                            filename={csvFileName}
                                            // className="btn btn-primary"
                                            target="_blank"
                                          >
                                          <i class="fa-solid fa-file-csv my-auto" style={{ fontSize: '24px' }}></i>
                                        </CSVLink>

                                          <div class="form-group has-search my-auto">
                                              <span class="fa fa-search form-control-feedback"></span>
                                              <input type="text" class="form-control mb-0"  onChange={handleFilter}/>
                                          </div>
  
  
                                      </div>
  
                                  </Col> */}
                  {/* --------------Table Content Start--------------- */}



                  {/* --------------Table Content Start--------------- */}
                  {/* <Col sm={12}>

                    <DataTable
                      progressPending={loading}
                      columns={columns}
                      data={records}
                      fixedHeader
                      pagination
                      customStyles={customeStyle}
                      className="cursor-pointer  list-cus-h"
                    />



                  </Col> */}



                  <Box
                    sx={{
                      height: "fit-content",
                      width: "100%",
                      "& .actions": {
                        color: "text.secondary",
                      },
                      "& .textPrimary": {
                        color: "text.primary",
                      },
                      "& .super-app-theme--header": {
                        backgroundColor: "#f4f4f4",
                      },
                      "& .MuiDataGrid-row": {
                        border: '1px solid #eff0f2'
                      },
                      "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                        outline: "none !important",
                      },
                      "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                      {
                        outline: "none !important",
                      },
                    }}
                    style={{ height: '100%', width: "100%" }}
                  >
                   <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                      //sx={{ border: "none", m: 2 }}
                      autoHeight
                      slots={{
                        loadingOverlay: LinearProgress,
                        //noRowsOverlay: CustomNoRowsOverlay,
                        toolbar: GridToolbar,
                        // toolbar: EditToolbar,
                      }}
                      slotProps={{
                        toolbar: {
                          csvOptions: { fileName: "StudentLoginReport" },
                          printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "StudentLoginReport" },
                          records,
                          showQuickFilter: true
                        },


                      }}
                      initialState={{
                        ...records.initialState,
                        pagination: { paginationModel: { pageSize: 10 } },
                      }}
                      showQuickFilter={true}

                      pageSizeOptions={[10, 25, 50, 100]}

                      loading={loading}
                      rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                      columns={columns}
                      rowHeight={60} density="compact"
                      disableRowSelectionOnClick
                      showCellVerticalBorder={true}
                      showColumnVerticalBorder={true}

                    />

                  </Box>

                  {/* --------------Table Content Start--------------- */}

                </Row>

              </div>
            </Col>
          </Row>

          {/* --------------Content End--------------- */}


        </Card.Body>

      </Card>

      {/* --------------Section End--------------- */}




      {/* --------------Page End--------------- */}

    </div>
  );

}

export default ReportStudentLogin;