/*
Purpose: list details of report with use datatable
Developer: BY KTH
Date:22/08/2023 
Project: TCMS
*/



import { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import axios from "axios";
import { security_key, BASE_URL } from "../global";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

function ReportFinanceDeposits() {
  const [loading, setLoading] = useState(false);
  

  const [data, setItems] = useState([]);
  const [records, setRecords] = useState(data);
  useEffect(() => {
      fetchData();
  }, []);

  const fetchData = async () => {
      try {
          const response = await axios.post(BASE_URL + "API/api_index.php", {
              security_key: security_key,
              mode: "getCentreDepositeReports",
              fk_cent_key: sessionStorage.getItem("key"),
          });
          
          if (response.data.success === true) {
              setRecords(response.data.data);
              setItems(response.data.data);

              setLoading(false);
          } else {
              //alert message provide
              setLoading(false);
          }
      } catch (error) {
          console.error("Error fetching data:", error);
      }
  };


  const columns = [
    {

      headerName: 'Student ID',
      field: "pk_student_id",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.pk_student_id && params.row.pk_student_id  != '' ? '#'+params.row.pk_student_id : '-'}`;
      },
    },
    {
      headerName: 'Student Name',
      field: "student_name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.st_first_name && params.row.st_first_name != '' ? params.row.st_first_name+' '+params.row.st_surname : '-'}`;
      },
    },
    {
      headerName: "Parents / Guardians Name",
      field: "parent_name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.par_firstname && params.row.par_firstname != '' ?  params.row.par_firstname+' '+params.row.par_surname : '-'}`;
      },
    }, 
    {
        headerName: "Deposit Amount, Date, Method(" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
        field: "method",
        headerClassName: "super-app-theme--header",
        flex: 1,
        valueGetter: (params) => {
            return `${params.row.depositAmount && params.row.depositAmount != '' ?  params.row.depositAmount : '0'}`;
        },
    },
    {
        headerName: "Deposit Refund, Date, Method(" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
        field: "Deposit Refund, Date, Method",
        headerClassName: "super-app-theme--header",
        flex: 1,
        valueGetter: (params) => {
          return `${0}`;
        },
      },
      {
        headerName: "Deposit Transfer, Date, Method(" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
        field: "Deposit Transfer, Date, Method",
        headerClassName: "super-app-theme--header",
        flex: 1,
        valueGetter: (params) => {
          return `${0}`;
        },
      }, 
      {
        headerName: "Deposit Balance(" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
        field: "Deposit Balance",
        headerClassName: "super-app-theme--header",
        flex: 1,
        valueGetter: (params) => {
            return `${params.row.depositAmount && params.row.depositAmount != '' ?  params.row.depositAmount : '0'}`;
        },
    },
    {
      headerName: "Total Deposit Held(" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "Total Deposit Held",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
          return `${params.row.depositAmount && params.row.depositAmount != '' ?  params.row.depositAmount : '0'}`;
      },
  }


  ];



    return (
      <div className="">
  
          {/* --------------Page Start--------------- */}
  
          <div className="mb-3 d-flex justify-content-between align-items-center">
              <div>
                  <h5 className='page-title'> Deposits Reports  </h5>
  
                  <div role="presentation" >
                      <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                           <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>
                              <NavLink underline="hover" color="inherit" to='/reports'> Reports</NavLink>
                              <Typography color="text.primary">Deposits Reports </Typography>
                      </Breadcrumbs>
                  </div>
              </div>
              <div className='d-flex align-items-center gap-2'>
                  {/* <Button onClick={() => navigate('/reports')} className="secondary-filled" variant="contained">
            Back
          </Button> */}
  
              </div>
  
          </div>
          {/* --------------Section Start--------------- */}
  
          <Card className='card my-card mb-3'>
  
              <Card.Body>
  
                  {/* -------------Content Start--------------- */}
  
                  <Row>
                      <Col xs="12">
                          <div className="">
  
                              <Row className="justify-content-center">
  
                                  {/* --------------Table Content Start--------------- */}
                                  {/* <Col sm={12}>
  
                                      <div className="d-flex flex-wrap justify-content-end mb-3">
  
                                          <div class="form-group has-search my-auto">
                                              <span class="fa fa-search form-control-feedback"></span>
                                              <input type="text" class="form-control mb-0"  />
                                          </div>
  
  
                                      </div>
  
                                  </Col> */}
                                  {/* --------------Table Content Start--------------- */}
  
  
  
                                  {/* --------------Table Content Start--------------- */}
                                  <Col sm={12}>
  
                                     

                                      <Box
                                        sx={{
                                        height: "fit-content",
                                        width: "100%",
                                        "& .actions": {
                                            color: "text.secondary",
                                        },
                                        "& .textPrimary": {
                                            color: "text.primary",
                                        },
                                        "& .super-app-theme--header": {
                                            backgroundColor: "#e3e6ee",
                                        },
                                        }}
                                        style={{ height: '100%', width: "100%" }}
                                    >
                                    <DataGrid
                                                sx={{
                                                // disable cell selection style
                                                '.MuiDataGrid-cell:focus': {
                                                    outline: 'none'
                                                },
                                                // pointer cursor on ALL rows
                                                '& .MuiDataGrid-row:hover': {
                                                    cursor: 'pointer'
                                                }
                                                }}
                                        //sx={{ border: "none", m: 2 }}
                                        autoHeight
                                        slots={{
                                            loadingOverlay: LinearProgress,
                                            //noRowsOverlay: CustomNoRowsOverlay,
                                            toolbar: GridToolbar,
                                            // toolbar: EditToolbar,
                                        }}
                                        slotProps={{
                                            toolbar: {
                                            csvOptions: { fileName: "DepositsList" },
                                            printOptions: { pageStyle: `@media print {
                                                    @page {
                                                        size: A4 landscape;
                                                        margin: 0;
                                                    }
                                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "DepositsList" },
                                                    records,
                                            showQuickFilter: true
                                            },


                                        }}
                                        initialState={{
                                            ...records.initialState,
                                            pagination: { paginationModel: { pageSize: 10 } },
                                        }}
                                        showQuickFilter={true}

                                        pageSizeOptions={[10, 25, 50, 100]}

                                        loading={loading}
                                        rows={records.map((row, index) => ({ ...row, id: `${index+1}` }))}
                                        columns={columns}
                                        rowHeight={60} density="compact"
                                        disableRowSelectionOnClick
                                        showCellVerticalBorder= {true}
                                        showColumnVerticalBorder= {true}

                                        />


                                    </Box>
  
                                  </Col>
                                  {/* --------------Table Content Start--------------- */}
  
                              </Row>
  
                          </div>
                      </Col>
                  </Row>
  
                  {/* --------------Content End--------------- */}
  
  
              </Card.Body>
  
          </Card>
  
          {/* --------------Section End--------------- */}
  
  
  
  
          {/* --------------Page End--------------- */}
  
      </div>
  );
  
}

export default ReportFinanceDeposits;