/*
Purpose: list details of student with use datatable
Designed by R.Sanjai
Date:24/08/2023 
Project: TCMS
*/

import { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { Row, Col, Card, Modal } from "react-bootstrap";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import React from "react";

import AddIcon from "@mui/icons-material/Add";
import { Button as Buttonmui } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Moment from "moment";
import { security_key, BASE_URL } from "../global";

import "./Student.css";

function ListSessionTime() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [showDelete, setDeleteShow] = useState(false);
  const [data_delete, setDeleteLabelData] = useState([]);
  const [isUserDeleteSubmitting, setIsUserDeleteSubmitting] = useState(false);

  const handleDeleteClick = (row) => {
    const updatedData = { ...row };
    setDeleteLabelData(updatedData);

    setDeleteShow(true);
  };

  //Api call start
  const [data, setItems] = useState([]);

  const [records, setRecords] = useState(data);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "getAllSessionTime",
        fk_cent_key: sessionStorage.getItem("key"),
      });

      if (response.data.success === true) {
        setRecords(response.data.data);
        setItems(response.data.data);
        setLoading(false);
      } else {
        //alert message provide
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //Api call End

  const handleSessionClick = async (e) => {
    navigate(`/list-session`, { state: { pk_sest_key: `${e.pk_sest_key}` } });
  };

  const handleMessageDeleteClick = async () => {
    toast.error(
      "Sorry, this session has been enrolled by a student and cannot be deleted.",
      {
        autoClose: 3000, // 3 seconds in this example
      }
    );
  };
  const handleRowEditSessionClick = async (e) => {
    //console.log(e)
    const responsetime = axios
      .post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        fk_cent_key: sessionStorage.getItem("key"),
        mode: "getCheckTimeZone",
      })
      .then((responsetime) => {
        if (responsetime.data.data.length > 0) {
          if (
            responsetime.data.data[1] != null &&
            responsetime.data.data[1] != ""
          ) {
            const currentDate = new Date(
              responsetime.data.data[0] + "T" + responsetime.data.data[1]
            );

            // Set the session_date (assuming it's a string, convert it to a Date object)
            const session_date = new Date(
              e.sest_from_date + "T" + e.sest_start_time
            );
            const session_end_date = new Date(
              e.sest_to_date + "T" + e.sest_start_time
            );
          
            // Compare dates using getTime() method
            if (currentDate.getTime() <= session_date.getTime() && e.enrolment_count <= 0) {
              navigate(`/edit-session-time`, {
                state: { pk_sest_key: `${e.pk_sest_key}` },
              });
            } 
            // else if (currentDate.getTime() <= session_end_date.getTime()) {
            //   navigate(`/edit-existing-session-time`, {
            //     state: { pk_sest_key: `${e.pk_sest_key}` },
            //   });
            // }
             else {
              // toast.error("Only Upcoming Session Editable", {
              //   autoClose: 3000, // 3 seconds in this example
              // });
              navigate(`/edit-existing-session-time`, {
                    state: { pk_sest_key: `${e.pk_sest_key}` },
                  });
            }
          } else {
            toast.error(
              "Failure! Unable to Editable session. Try again later",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );
          }
        } else {
          toast.error("Failure! Unable to Editable session. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });
        }
      })
      .catch((error) => {
        toast.error("Failure! Unable to Editable session. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
      });
  };

  const columns = [
    {
      headerName: "Session Name",
      field: "Session Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.sest_name == null || params.row.sest_name == ""
            ? "-"
            : params.row.sest_name
        }`;
      },
    },
    {
      headerName: "Day",
      field: "Day",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.sest_day == null || params.row.sest_day == ""
            ? "-"
            : params.row.sest_day
        }`;
      },
    },
    {
      headerName: "Tutor",
      field: "Tutor",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.tut_fname == null || params.row.tut_fname == ""
            ? "-"
            : params.row.tut_fname + " " + params.row.tut_surname
        }`;
      },
    },
    {
      headerName: "Start - End Date",
      field: "Start - End Date",
      headerClassName: "super-app-theme--header",
      //flex: 1,
      width: 200,
      valueGetter: (params) => {
        return `${
          (params.row.sest_from_date == null ||
            params.row.sest_from_date == "") &&
          (params.row.sest_to_date == null || params.row.sest_to_date == "")
            ? "-"
            : Moment(params.row.sest_from_date, "YYYY-MM-DD").format(
                "DD-MM-YYYY"
              ) +
              " - " +
              Moment(params.row.sest_to_date, "YYYY-MM-DD").format("DD-MM-YYYY")
        }`;
      },
    },
    // {
    //   id: 'End',
    //   name: 'End',
    //   selector: 'sest_to_date',
    //   cell: row => Moment(row.sest_to_date,'YYYY-MM-DD').format('DD-MM-YYYY'),
    //   sortable: false,
    // },
    {
      headerName: "Start - End Time",
      field: "Start - End Time",
      headerClassName: "super-app-theme--header",
      //flex: 1,
      width: 120,
      valueGetter: (params) => {
        return `${
          (params.row.sest_start_time == null ||
            params.row.sest_start_time == "") &&
          (params.row.sest_end_time == null || params.row.sest_end_time == "")
            ? "-"
            : Moment(params.row.sest_start_time, "HH:mm:ss").format("HH:mm") +
              " - " +
              Moment(params.row.sest_end_time, "HH:mm:ss").format("HH:mm")
        }`;
      },
    },
    // {
    //   name: 'End Time',
    //   selector: row => Moment(row.sest_end_time, "HH:mm:ss").format('HH:mm'),
    //   sortable: true
    // },
    {
      headerName: "Students",
      field: "Students",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.enrolment_count +
          " / " +
          (params.row.sest_max_student != null &&
          params.row.sest_max_student != ""
            ? params.row.sest_max_student
            : "0")
        }`;
      },
    },
    // {
    //   name: 'Maximum Students',
    //   selector: row => row.sest_max_student!=null && row.sest_max_student!='' ? row.sest_max_student : 0,
    //   sortable: true,
    // },
    // {

    //   headerName: 'Type',
    //   field: "Type",
    //   headerClassName: "super-app-theme--header",
    //   flex: 1,
    //   valueGetter: (params) => {
    //     return `${params.row.sest_type == null || params.row.sest_type == '' ? '-' : params.row.sest_type}`;
    //   },

    // },

    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 250,
      headerAlign: "left",
      renderCell: (params) => {
        const row = params.row;

        return (
          <div className="d-flex  pad-12">
            <Buttonmui
              variant="outlined-red"
              onClick={() => handleSessionClick(row)}
              className="primary-blue  btnmr-2 my-2"
            >
              View Sessions{" "}
            </Buttonmui>
            <Buttonmui
              variant="outlined-red"
              onClick={() => handleRowEditSessionClick(row)}
              className="primary-blue  btnmr-2 my-2"
            >
              Edit
            </Buttonmui>
            {parseInt(row.enrolment_count) === 0 ? (
              <Buttonmui
                variant="outlined-red"
                //onClick={() => handleDeleteClick(row)}
                className="filled-danger my-2"
              >
                Delete{" "}
              </Buttonmui>
            ) : (
              <Buttonmui
                variant="outlined-red"
                //onClick={() => handleMessageDeleteClick()}
                className="filled-danger my-2"
              >
                Delete{" "}
              </Buttonmui>
            )}
          </div>
        );
      },
    },
  ];

  function onSubmitUserDelete() {
    setIsUserDeleteSubmitting(true);

    const responseuserdelete = axios
      .post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        fk_cent_key: sessionStorage.getItem("key"),
        mode: "deleteSessionTime",
        pk_sest_key: data_delete.pk_sest_key,
      })
      .then((responseuserdelete) => {
        if (responseuserdelete.data.data == 1) {
          setDeleteShow(false);
          // Handle the successful response here
          toast.success("Success! Your form was submitted", {
            autoClose: 3000, // 3 seconds in this example
          });

          fetchData();

          setIsUserDeleteSubmitting(false);
        } else if (responseuserdelete.data.data == 2) {
          toast.error(
            "Failure! Unable to delete session. student enrolled this session",
            {
              autoClose: 3000, // 3 seconds in this example
            }
          );
          fetchData();
          setDeleteShow(false);
          setIsUserDeleteSubmitting(false);
        } else {
          toast.error("Failure! Unable to delete session. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });
          setIsUserDeleteSubmitting(false);
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to delete session. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsUserDeleteSubmitting(false);
      });
  }

  return (
    <>
      {/* React alert */}
      <ToastContainer position="top-center" />
      <div className="page-wrapper">
        <Modal show={showDelete} variant="danger">
          <Modal.Header>Delete Session</Modal.Header>

          <Modal.Body>
            <p>
              Are you sure? You want to delete this{" "}
              <b className="bg-warning">session</b>?{" "}
            </p>

            <Card className="rounded-1 mt-2 card-bdr">
              <Card.Body>
                <div>
                  <div className="d-flex justify-content-between align-self-center">
                    <div className="me-5">
                      <div className="">
                        <h6 className="">Session Name:</h6>
                        <p className="ml-3 mb-2">{data_delete.sest_name}</p>
                        <h6>Day:</h6>
                        <p className="ml-3 mb-2">{data_delete.sest_day}</p>
                        <h6>Tutor Name</h6>
                        <p className=" ml-3mb-2">
                          {data_delete.tut_fname +
                            " " +
                            data_delete.tut_surname}
                        </p>
                        <h6>From Date:</h6>
                        <p className=" ml-3 mb-2">
                          {Moment(
                            data_delete.sest_from_date,
                            "YYYY-MM-DD"
                          ).format("DD-MM-YYYY")}
                        </p>
                        <h6>End Date:</h6>
                        <p className=" ml-3 mb-2">
                          {Moment(
                            data_delete.sest_to_date,
                            "YYYY-MM-DD"
                          ).format("DD-MM-YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <span></span>
                </div>
              </Card.Body>
            </Card>
            <hr />
          </Modal.Body>
          <Modal.Footer className="pt-0">
            <div className="mx-auto d-flex gap-2">
              <Button
                variant="contained"
                className="primary-gray"
                onClick={() => setDeleteShow(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                className="primary-filled "
                disabled={isUserDeleteSubmitting}
                onClick={() => onSubmitUserDelete()}
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* --------------Page Start--------------- */}

        {/* --------------Section Start--------------- */}

        <div className="mb-3 d-flex justify-content-between align-items-center">
          <div>
            <h5 className="page-title"> Session Times </h5>

            <div role="presentation">
              <Breadcrumbs aria-label="breadcrumb" className="my-breadcrumbs">
                <NavLink underline="hover" color="inherit" to="/calendar">
                  {" "}
                  Home
                </NavLink>

                <Typography color="text.primary">Session</Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div className="d-flex align-items-center gap-2">
            {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}

            <NavLink
              className="primary-filled rounded-10 p-2"
              to="/add-session-time"
            >
              <AddIcon className="btn-icon" /> Create Session Time
            </NavLink>
            {/* <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <React.Fragment>
                <Button className="primary-filled" variant="contained" {...bindTrigger(popupState)}>
                  New Session Time
                  <ArrowDropDownIcon className='p-0' />
                </Button>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem onClick={() => navigate('/add-session-time')}>Create Session Time</MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState> */}
          </div>
        </div>
        <Card className="my-card p-1 bg-white">
          <Card.Body>
            {/* -------------Content Start--------------- */}

            <Row>
              <Col xs="12">
                <div className="">
                  <Row className="justify-content-center">
                    {/* --------------Table Content Start--------------- */}
                    <Col sm={12}>
                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#e3e6ee",
                          },
                        }}
                        style={{ height: "100%", width: "100%" }}
                      >
                        <DataGrid
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "SessionList" },
                              printOptions: {
                                pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`,
                                disableToolbarButton: true,
                                hideFooter: true,
                                hideToolbar: true,
                                fileName: "SessionList",
                              },
                              records,
                              showQuickFilter: true,
                            },
                          }}
                          initialState={{
                            ...records.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          showQuickFilter={true}
                          pageSizeOptions={[10, 25, 50, 100]}
                          loading={loading}
                          rows={records.map((row, index) => ({
                            ...row,
                            id: `row-${index}`,
                          }))}
                          columns={columns}
                          rowHeight={60}
                          density="compact"
                          // onRowClick={(params) => handleRowEditSessionClick(params.row)}
                          disableRowSelectionOnClick
                          showCellVerticalBorder={true}
                          showColumnVerticalBorder={true}
                        />
                      </Box>
                    </Col>
                    {/* --------------Table Content Start--------------- */}
                  </Row>
                </div>
              </Col>
            </Row>

            {/* --------------Content End--------------- */}
          </Card.Body>
        </Card>

        {/* --------------Section End--------------- */}

        {/* --------------Page End--------------- */}
      </div>
    </>
  );
}

export default ListSessionTime;
