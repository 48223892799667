/*
Purpose: list of Entrolment with use datatable
Developer: BY R.SD
Date:24/08/2023 
Project: TCMS
*/


import React from 'react';
import { useState, useEffect, useRef } from "react";
import { useLocation, NavLink, useNavigate } from 'react-router-dom';

import { Row, Col, Tab, Nav, Dropdown, DropdownButton, Card, Form, Modal, Button, ButtonGroup } from 'react-bootstrap';


import axios from 'axios';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import { Button as Buttonmui } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import Moment from 'moment';
import Select from 'react-select';
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

import 'react-toastify/dist/ReactToastify.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';


import {
  security_key, options_dig_type
  , BASE_URL, options_method_payment
} from "../global";




const SessionList = () => {

  const options_payment_type = [
    { value: '', label: '---Select---' },
    { value: 'Online', label: 'Online' },
    { value: 'Offline', label: 'Offline' },

  ];
  const options_pay_method_type = [
    { value: "1", label: "Online" },
    { value: "2", label: "Offline" },
  ];

  const options_payment_service = [
    { value: '', label: '---Select---' },
    { value: '1', label: 'Prepaid' },
    { value: '2', label: 'Postpaid' },

  ];

  const handleClick_student_edit = () => {
    // You can use the 'id' value here for further processing
    navigate(`/student-profile`, { state: { pk_student_key: `${Id}` } });

  };
  const handleClick_student_view = () => {
    // You can use the 'id' value here for further processing
    navigate(`/view-student`, { state: { pk_student_key: `${Id}` } });

  };


  const handleClick_student_xero = () => {
    // You can use the 'id' value here for further processing
    navigate(`/ListXeroEntry`, { state: { pk_student_key: `${Id}` } });

  };

  const handleClick_student_quickbook = () => {
    navigate(`/ListQBEntry`, { state: { pk_student_key: `${Id}` } });
  };


  const [showForfeit, setForfeitShow] = useState(false);


  const [dataPrice, setPrice] = useState([]);

  const [records, setRecords] = useState([]);

  const [records_l, setRecords_d] = useState([]);
  const [records_p, setRecords_p] = useState([]);
  const [records_ph, setRecords_ph] = useState([]);
  const [records_c, setRecords_c] = useState([]);

  const [records_deposit, setRecords_deposit] = useState([]);

  const [records_offline, setRecords_offline] = useState([]);

  const [records_online, setRecords_online] = useState([]);

  const [records_refund, setRecords_refund] = useState([]);

  const [data, setItems] = useState([]);

  const [data_d, setItems_d] = useState([]);

  const [data_c, setItems_c] = useState([]);
  const [data_p, setItems_p] = useState([]);
  const [data_ph, setItems_ph] = useState([]);


  //stripe Payment history

  const [StripePayment_record, setStripePayment_record] = useState([]);
  //stripe paymet history



  //offline Payment history

  const [OfflinePayment_record, setOfflinePayment_record] = useState([]);
  const [OfflineOtherPaymentData, setOfflineOtherPaymentData] = useState([]);
  //offline paymet history


  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);


  const [isActive, setIsActive] = useState("first");

  const handleClick = (eventkey) => {
    setIsActive(eventkey);
  };


  const [Id, setId] = useState(null);

  //Get key value start
  const location = useLocation();
  useEffect(() => {


    const { pk_student_key } = location.state || {};
    const extractedSId = pk_student_key;

    if (extractedSId !== '' && extractedSId !== undefined) {
      setId(extractedSId);
      //fetchData(extractedId);
      fetchData(extractedSId);
      fetchMasterData(extractedSId);
      fetchMasterSessionData(extractedSId);
    } else {
      navigate("/list-students");
    }
  }, [location]);
  //Get key value end


  //Api call start



  const [data_forfeit, setForfeitLabelData] = useState([]);


  const [options_subject, setOptions] = useState([]);
  const [options_pricing, setFeesOptions] = useState([]);
  const [options_session, setSessionOptions] = useState([]);
  const [options_unenrol_session, SetUnenrolSupOptions] = useState([]);
  const [SestTypeData, SetSestTypeData] = useState('In Centre');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingUnenrol, setIsSubmittingUnenrol] = useState(false);

  const [isSubmittingDeposit, setIsSubmittingDeposit] = useState(false);

  const [isSubmittingPrice, setIsSubmittingPrice] = useState(false);

  const [isSubmittingForfeit, setIsSubmittingForfeit] = useState(false);
  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);

  const [showPopupGeneratePayment, setShowPopupGeneratePayment] = useState(false);
  const [DataGP, setDataGP] = useState([]);

  const [StudentData, setStudentData] = useState({});


  const [OfflineModesOptions, setOfflineModesOptions] = useState([]);
  const [PaymentVerifyData, setPaymentVerifyData] = useState(null);

  const [options_pay_method, setOptionPayMethod] = useState([]);
  const [options_product_type, setProductTypeData] = useState([]);

  const [options_deposit_product_type, setDepostitProductTypeData] = useState([]);

  const handleSessionViewClick = async (e) => {
    navigate(`/ViewEntrolments`, { state: { fk_stu_key: `${e.fk_stu_key}`, fk_sest_key: `${e.fk_sest_key}`, pk_enrol_key: `${e.pk_enrol_key}` } });
  }

  const handleUnEnrollSessionViewClick = async (e) => {
    navigate(`/ViewUnEntrolments`, { state: { fk_stu_key: `${e.fk_stu_key}`, fk_sest_key: `${e.fk_sest_key}`, pk_enrol_key: `${e.pk_enrol_key}` } });
  }


  const formRef = useRef(null);
  const fetchMasterSessionData = (Id, clsid = '', clskey = '') => {
    axios.post(BASE_URL + 'API/api_index.php', {
      security_key: security_key,
      class_id: clsid,
      mode: "getAllSessionTimeByStudent",
      fk_student_key: Id,
      sest_type: SestTypeData,
      fk_pt_key: clskey,
      fk_cent_key: sessionStorage.getItem('key')
    })
      .then(response_session => {

        // Assuming the API response is an array of objects with 'id' and 'value' properties
        const SessionOptions = response_session.data.data.map(option => ({
          value: option.pk_sest_key,
          label: option.sest_name
        }));
        SessionOptions.unshift({
          value: '',
          label: '--Select--'
        });
        setSessionOptions(SessionOptions);
      })
      .catch(error => {
        console.error('Error fetching options from API: ', error);
      });
  }


  const fetchMasterProductData = (data, fk_pt_key) => {
    axios.post(BASE_URL + 'API/api_index.php', {
      security_key: security_key,
      mode: "getAllProductOnlineOfflineCentre",
      pp_pay_method: data,
      fk_pt_key: fk_pt_key,
      fk_student_key: Id,
      fk_cent_key: sessionStorage.getItem('key')
    })
      .then(response_Product => {

        // Assuming the API response is an array of objects with 'id' and 'value' properties
        const feesOptions = response_Product.data.data.map(option => ({
          value: option.pk_pp_key,
          label: option.pp_name + '(' + option.pp_amount + ')'
        }));
        feesOptions.unshift({
          value: '',
          label: '--Select--'
        });
        setFeesOptions(feesOptions);
      })
      .catch(error => {
        console.error('Error fetching options from API: ', error);
      });
  }

  const fetchMasterData = (Id, clsid = '') => {


    axios.post(BASE_URL + 'API/api_index.php', {
      security_key: security_key,
      pk_student_key: Id,
      mode: "getSingleStudent"

    })
      .then(response_stu => {
        // Assuming the API response is an array of objects with 'id' and 'value' properties
        if (response_stu.data.success === true) {
          if (response_stu.data.data.length > 0) {
            setStudentData(response_stu.data.data[0]);
          }
          setLoading(false);

        }
        else {
          setLoading(false);

        }

      })
      .catch(error => {
        //console.error('Error fetching options from API: ', error);
      });


    // Make an API request to fetch the options
    axios.post(BASE_URL + 'API/api_index.php', {
      security_key: security_key,
      fk_student_key: Id,
      mode: "getAllSubjectby_center", //"getAllSubjectByStudent" 
      fk_cent_key: sessionStorage.getItem('key')

    })
      .then(response => {
        // Assuming the API response is an array of objects with 'id' and 'value' properties
        const formattedOptions = response.data.data.map(option => ({
          value: option.subject_id,
          label: option.subject_name
        }));
        // formattedOptions.unshift({
        //   value: '',
        //   label: '--Select--'
        // });
        setOptions(formattedOptions);
      })
      .catch(error => {
        //console.error('Error fetching options from API: ', error);
      });


    // Make an API request to fetch the options
    axios.post(BASE_URL + 'API/api_index.php', {
      security_key: security_key,
      mode: "getOfflinePaymentModeCenter", //"getAllSubjectByStudent" 
      fk_cent_key: sessionStorage.getItem('key')
    })
      .then(response => {
        // Assuming the API response is an array of objects with 'id' and 'value' properties
        const formattedOptions = response.data.data.map(option => ({
          value: option.pk_cpm_key,
          label: option.pm_name
        }));
        // formattedOptions.unshift({
        //   value: '',
        //   label: '--Select--'
        // });
        setOfflineModesOptions(formattedOptions);
      })
      .catch(error => {
        //console.error('Error fetching options from API: ', error);
      });

    // axios.post(BASE_URL + 'API/api_index.php', {
    //   security_key: security_key,
    //   mode: "getAllPriceData",
    //   fk_cent_key: sessionStorage.getItem('key'),
    //   student_key: Id
    // })
    //   .then(response_fees => {
    //     // Assuming the API response is an array of objects with 'id' and 'value' properties
    //     const feesOptions = response_fees.data.data.map(option => ({
    //       value: option.ss_price,
    //       label: option.stp_product_name + (option.stp_product_name !== 'Offline' ? ' (' + option.ss_price + ')' : '')
    //     }));
    //     feesOptions.unshift({
    //       value: '',
    //       label: '--Select--'
    //     });
    //     setFeesOptions(feesOptions);
    //   })
    //   .catch(error => {
    //    // console.error('Error fetching options from API: ', error);
    //   });

    axios.post(BASE_URL + 'API/api_index.php', {
      security_key: security_key,
      mode: "getCenterStudentVerifiedDetails",
      fk_cent_key: sessionStorage.getItem('key'),
      fk_student_key: Id
    })
      .then(responseExistingStripe => {
        // Assuming the API response is an array of objects with 'id' and 'value' properties
        if (responseExistingStripe.data.success === true) {
          if (responseExistingStripe.data.data.length > 0) {

            setPaymentVerifyData(responseExistingStripe.data.data[0]);
            //  // Assuming the API response is an array of objects with 'id' and 'value' properties
            const options_pay_method = [

              { value: '', label: '--Select--' },
              { value: '1', label: 'Online' },
              { value: '2', label: 'Offline' }
            ];
            setOptionPayMethod(options_pay_method);
            //fetchMasterProductData('2');
          } else {

            const options_pay_method = [
              { value: '', label: '--Select--' },
              { value: '2', label: 'Offline' }
            ];
            setOptionPayMethod(options_pay_method);
            //fetchMasterProductData('2');
          }
        } else {
          const options_pay_method = [
            { value: '', label: '--Select--' }, { value: '2', label: 'Offline' }
          ];
          setOptionPayMethod(options_pay_method);
          // fetchMasterProductData('2');
          // Handle unsuccessful response, e.g., show an alert to the user.
        }
      })
      .catch(error => {
        // console.error('Error fetching options from API: ', error);
      });

    axios.post(BASE_URL + 'API/api_index.php', {
      security_key: security_key,
      mode: "getProductTypeStuEnrol",
    })
      .then(response_type => {
        // Assuming the API response is an array of objects with 'id' and 'value' properties
        if (response_type.data.success === true) {
          if (response_type.data.data.length > 0) {
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            const formattedOptionsType = response_type.data.data.map(option => ({
              value: option.pk_pt_key,
              label: option.pt_name
            }));
            formattedOptionsType.unshift({
              value: '',
              label: '--Select--'
            });
            setProductTypeData(formattedOptionsType);

          } else {
            setProductTypeData([{
              value: '',
              label: '--Select--'
            }]);

          }
        } else {
          setProductTypeData([{
            value: '',
            label: '--Select--'
          }]);
          // Handle unsuccessful response, e.g., show an alert to the user.
        }
      })
      .catch(error => {
        // console.error('Error fetching options from API: ', error);
      });


    axios.post(BASE_URL + 'API/api_index.php', {
      security_key: security_key,
      mode: "getProductTypeStuEnrolDeposit",
    })
      .then(response_type => {
        // Assuming the API response is an array of objects with 'id' and 'value' properties
        if (response_type.data.success === true) {
          if (response_type.data.data.length > 0) {
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            const formattedOptionsType = response_type.data.data.map(option => ({
              value: option.pk_pt_key,
              label: option.pt_name
            }));
            formattedOptionsType.unshift({
              value: '',
              label: 'Select'
            });
            setDepostitProductTypeData(formattedOptionsType);

          } else {
            setDepostitProductTypeData([{
              value: '',
              label: 'Select'
            }]);

          }
        } else {
          setDepostitProductTypeData([{
            value: '',
            label: 'Select'
          }]);
          // Handle unsuccessful response, e.g., show an alert to the user.
        }
      })
      .catch(error => {
        // console.error('Error fetching options from API: ', error);
      });



    axios.post(BASE_URL + 'API/api_index.php', {
      security_key: security_key,
      mode: "getProductStuDeposit",
      fk_student_key: Id
    })
      .then(response_deposit => {
        // Assuming the API response is an array of objects with 'id' and 'value' properties
        if (response_deposit.data.success === true) {
          if (response_deposit.data.data.length > 0) {

            setRecords_deposit(response_deposit.data.data);

          } else {
            setRecords_deposit([]);

          }
        } else {
          setRecords_deposit([]);
          // Handle unsuccessful response, e.g., show an alert to the user.
        }
      })
      .catch(error => {
        // console.error('Error fetching options from API: ', error);
      });


  };



  const fetchData = async (Id) => {
    if (Id != null && Id != '') {


      try {
        const response = await axios.post(BASE_URL + 'API/api_index.php', {
          security_key: security_key,
          mode: "getStudentEnrolmentData",
          fk_student_key: Id,
          fk_cent_key: sessionStorage.getItem('key')
        });
        if (response.data.success === true) {
          setRecords(response.data.data);
          setItems(response.data.data);
          setLoading(false);


          // const UnenrolSupOptions = response.data.data.map(option => ({
          //   value: option.pk_enrol_key,
          //   label: option.sest_name
          // }));
          // UnenrolSupOptions.unshift({
          //     value: '',
          //     label: '--Select--'
          //   });
          //   SetUnenrolSupOptions(UnenrolSupOptions);

        } else {
          setLoading(false);

          //alert message provide
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
      try {
        const response = await axios.post(BASE_URL + 'API/api_index.php', {
          security_key: security_key,
          mode: "readStudentEnrolmentPayment",
          fk_student_key: Id,
          fk_cent_key: sessionStorage.getItem('key')
        });

        if (response.data.success === true) {
          setRecords_p(response.data.data);
          setItems_p(response.data.data);
          setLoading(false);


        } else {
          setLoading(false);

          //alert message provide
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
      try {
        const response = await axios.post(BASE_URL + 'API/api_index.php', {
          security_key: security_key,
          mode: "readStudentEnrolmentPaymentHistory",
          fk_student_key: Id,
          fk_cent_key: sessionStorage.getItem('key')
        });

        if (response.data.success === true) {
          setRecords_ph(response.data.data);
          setItems_ph(response.data.data);
          setLoading(false);


        } else {
          setLoading(false);

          //alert message provide
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }


      try {
        const response = await axios.post(BASE_URL + 'API/api_index.php', {
          security_key: security_key,
          mode: "getStudentEnrolmentCompletedData",
          fk_student_key: Id,
          fk_cent_key: sessionStorage.getItem('key')
        });
        if (response.data.success === true) {
          setRecords_c(response.data.data);
          setItems_c(response.data.data);
          setLoading(false);


        } else {
          setLoading(false);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }

      try {
        const response = await axios.post(BASE_URL + 'API/api_index.php', {
          security_key: security_key,
          mode: "getStudentUnEnrolmentdData",
          fk_student_key: Id,
          fk_cent_key: sessionStorage.getItem('key')
        });
        if (response.data.success === true) {
          setRecords_d(response.data.data);
          setItems_d(response.data.data);
          setLoading(false);

        } else {
          //alert message provide
          setLoading(false);

        }


      } catch (error) {
        console.error('Error fetching data:', error);
      }

      try {
        const response_section = await axios.post(BASE_URL + 'API/api_index.php', {
          security_key: security_key,
          mode: "readStudentEnrolmentSectionOnly",
          fk_student_key: Id,
          fk_cent_key: sessionStorage.getItem('key')
        });
        if (response_section.data.success === true) {


          const UnenrolSupOptions = response_section.data.data.map(option => ({
            value: option.pk_enrol_key,
            label: option.sest_name
          }));
          UnenrolSupOptions.unshift({
            value: '',
            label: '--Select--'
          });
          SetUnenrolSupOptions(UnenrolSupOptions);

        } else {
          //alert message provide
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }


      try {
        const responseInvoice = await axios.post(BASE_URL + 'webpages/stripe/invoice.php', {
          security_key: security_key,
          mode: "getInvoiceData",
          pk_student_kay: Id,
          fk_cent_key: sessionStorage.getItem('key')
        });
        if (responseInvoice.data.success === true) {
          let invoices = responseInvoice.data.invoices;
          // Filter invoices: include the last one and those with amount_paid > 0
          let filterInvoice = invoices.filter((invoice, index) => {
            return index === invoices.length - 1 || parseInt(invoice.amount_paid) > 0;
          });

          // Get the current year
          let currentYear = new Date().getFullYear();

          // Add recipt_no to each filtered invoice
          filterInvoice.forEach((invoice, index) => {
            let reciptNo = `${currentYear}/${String(filterInvoice.length - index).padStart(4, '0')}`;
            invoice.recipt_no = reciptNo;
          });


          setStripePayment_record(filterInvoice);
          setLoading(false);


        } else {
          setLoading(false);

        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }

      try {
        const responseCentreOfflinePayment = await axios.post(BASE_URL + 'API/api_index.php', {
          security_key: security_key,
          mode: "getOfflinePaymentStudentCentre",
          fk_student_key: Id,
          fk_cent_key: sessionStorage.getItem('key')
        });
        if (responseCentreOfflinePayment.data.success === true) {
          let offlineCentrePaymentData = responseCentreOfflinePayment.data.data;
          // Check if the array is empty
          if (Array.isArray(offlineCentrePaymentData) && offlineCentrePaymentData.length > 0) {
            // Sorting the array based on spst_to_date
            // offlinePaymentData.sort((a, b) => {
            //   const dateA = new Date(a.spst_to_date);
            //   const dateB = new Date(b.spst_to_date);
            //   return dateB - dateA; // Reverse the subtraction for descending order
            // });


            // invoices.filter((invoice, index) => {
            //   return index === invoices.length - 1 || parseInt(invoice.amount_paid) > 0;
            // });

            // // Get the current year
            // let currentYear = new Date().getFullYear();

            // // Add recipt_no to each filtered invoice
            // offlinePaymentData.forEach((invoice, index) => {
            //   let reciptNo = `${currentYear}/${String(offlinePaymentData.length - index).padStart(4, '0')}`;
            //   invoice.recipt_no = reciptNo;
            // });

            setRecords_offline(offlineCentrePaymentData);
          } else {
            // Handle empty array case
            setRecords_offline([]);
          }
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }

      try {
        const responseCentreOnlinePayment = await axios.post(BASE_URL + 'API/api_index.php', {
          security_key: security_key,
          mode: "getOnlinePaymentStudentCentre",
          fk_student_key: Id,
          fk_cent_key: sessionStorage.getItem('key')
        });
        if (responseCentreOnlinePayment.data.success === true) {
          let onlineCentrePaymentData = responseCentreOnlinePayment.data.data;
          // Check if the array is empty
          if (Array.isArray(onlineCentrePaymentData) && onlineCentrePaymentData.length > 0) {
            // Sorting the array based on spst_to_date
            // offlinePaymentData.sort((a, b) => {
            //   const dateA = new Date(a.spst_to_date);
            //   const dateB = new Date(b.spst_to_date);
            //   return dateB - dateA; // Reverse the subtraction for descending order
            // });


            // invoices.filter((invoice, index) => {
            //   return index === invoices.length - 1 || parseInt(invoice.amount_paid) > 0;
            // });

            // // Get the current year
            // let currentYear = new Date().getFullYear();

            // // Add recipt_no to each filtered invoice
            // offlinePaymentData.forEach((invoice, index) => {
            //   let reciptNo = `${currentYear}/${String(offlinePaymentData.length - index).padStart(4, '0')}`;
            //   invoice.recipt_no = reciptNo;
            // });

            setRecords_online(onlineCentrePaymentData);
          } else {
            // Handle empty array case
            setRecords_online([]);
          }
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }

      try {
        const responseCentreRefundPayment = await axios.post(BASE_URL + 'API/api_index.php', {
          security_key: security_key,
          mode: "getRefundHistoryDetails",
          fk_student_key: Id,
          fk_cent_key: sessionStorage.getItem('key')
        });
        if (responseCentreRefundPayment.data.success === true) {
          let refundPaymentData = responseCentreRefundPayment.data.data;
          // Check if the array is empty
          if (Array.isArray(refundPaymentData) && refundPaymentData.length > 0) {


            setRecords_refund(refundPaymentData);
          } else {
            // Handle empty array case
            setRecords_refund([]);
          }
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }

      try {
        const responseOfflinePayment = await axios.post(BASE_URL + 'API/api_index.php', {
          security_key: security_key,
          mode: "getOfflinePaymentData",
          pk_student_kay: Id,
          fk_cent_key: sessionStorage.getItem('key')
        });
        if (responseOfflinePayment.data.success === true) {
          let offlinePaymentData = responseOfflinePayment.data.data;
          // Check if the array is empty
          if (Array.isArray(offlinePaymentData) && offlinePaymentData.length > 0) {
            // Sorting the array based on spst_to_date
            offlinePaymentData.sort((a, b) => {
              const dateA = new Date(a.spst_to_date);
              const dateB = new Date(b.spst_to_date);
              return dateB - dateA; // Reverse the subtraction for descending order
            });


            // invoices.filter((invoice, index) => {
            //   return index === invoices.length - 1 || parseInt(invoice.amount_paid) > 0;
            // });

            // Get the current year
            let currentYear = new Date().getFullYear();

            // Add recipt_no to each filtered invoice
            offlinePaymentData.forEach((invoice, index) => {
              let reciptNo = `${currentYear}/${String(offlinePaymentData.length - index).padStart(4, '0')}`;
              invoice.recipt_no = reciptNo;
            });

            setOfflinePayment_record(offlinePaymentData);
          } else {
            // Handle empty array case
            setOfflinePayment_record([]);
          }
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }

      try {
        const responseOfflineOtherPayment = await axios.post(BASE_URL + 'API/api_index.php', {
          security_key: security_key,
          mode: "getOtherPaymentModeByCenter",
          pk_student_kay: Id,
          fk_cent_key: sessionStorage.getItem('key')
        });
        if (responseOfflineOtherPayment.data.success === true && responseOfflineOtherPayment.data.data.length > 0) {

          const OtherPaymentOptions = responseOfflineOtherPayment.data.data.map(option => ({
            value: option.pk_copm_key,
            label: option.copm_name
          }));
          // OtherPaymentOptions.unshift({
          //   value: '',
          //   label: '--Select--'
          // });
          setOfflineOtherPaymentData(OtherPaymentOptions);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }



    }
  };

  const downloadInvoice = async (invoiceId) => {
    try {
      const response = await axios.post(BASE_URL + 'webpages/stripe/invoice.php', {
        invoice_id: invoiceId,
        pk_student_kay: Id,
        mode: 'getDownloadInvoiceData'
      }, {
        responseType: 'blob' // Ensure response type is blob for binary data (PDF)
      });

      // Create a blob object from the PDF Blob data received
      const blob = new Blob([response.data], { type: 'application/pdf' });

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `invoice_${Id}.pdf`;

      // Append the link to the body and click it to trigger download
      document.body.appendChild(a);
      a.click();

      // Clean up: remove the temporary link and URL object
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading invoice:', error);
    }
  };


  const downloadInvoiceCustom = async (invoiceId, fromDate, endDate, type) => {
    try {
      const response = await axios.post(BASE_URL + 'webpages/stripe/invoice.php', {
        invoice_id: invoiceId,
        pk_student_kay: Id,
        mode: 'getDownloadInvoiceCustom',
        from_date: fromDate,
        end_date: endDate,
        type: type
      }, {
        responseType: 'blob' // Ensure response type is blob for binary data (PDF)
      });

      // Create a blob object from the PDF Blob data received
      const blob = new Blob([response.data], { type: 'application/pdf' });

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `receipt_${invoiceId}.pdf`;

      // Append the link to the body and click it to trigger download
      document.body.appendChild(a);
      a.click();

      // Clean up: remove the temporary link and URL object
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading invoice:', error);
    }
  };




  //Deposit invoice and recipet start


  const downloadInvoiceDepositCustom = async (data) => {
    try {
      const response = await axios.post(BASE_URL + 'webpages/stripe/invoice.php', {
        invoice_data: data,
        pk_student_kay: Id,
        security_key: security_key,
        mode: 'getDownloadInvoiceDepositCustom'
      }, {
        responseType: 'blob' // Ensure response type is blob for binary data (PDF)
      });

      // Create a blob object from the PDF Blob data received
      const blob = new Blob([response.data], { type: 'application/pdf' });

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `DI_00${data.pk_std_id}.pdf`;

      // Append the link to the body and click it to trigger download
      document.body.appendChild(a);
      a.click();

      // Clean up: remove the temporary link and URL object
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading invoice:', error);
    }
  };

  const downloadRecieptDepositCustom = async (data) => {
    try {
      const response = await axios.post(BASE_URL + 'webpages/stripe/invoice.php', {
        invoice_data: data,
        pk_student_kay: Id,
        security_key: security_key,
        mode: 'getDownloadRecieptDepositCustom'
      }, {
        responseType: 'blob' // Ensure response type is blob for binary data (PDF)
      });

      // Create a blob object from the PDF Blob data received
      const blob = new Blob([response.data], { type: 'application/pdf' });

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `DR_00${data.pk_std_id}.pdf`;

      // Append the link to the body and click it to trigger download
      document.body.appendChild(a);
      a.click();

      // Clean up: remove the temporary link and URL object
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading invoice:', error);
    }
  };
  //Deposit invoice and recipet End


  const downloadInvoiceOfflineCustom = async (data) => {
    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
        invoice_data: data,
        pk_student_kay: Id,
        security_key: security_key,
        mode: 'getDownloadInvoiceOfflineCustom'
      }, {
        responseType: 'blob' // Ensure response type is blob for binary data (PDF)
      });

      // Create a blob object from the PDF Blob data received
      const blob = new Blob([response.data], { type: 'application/pdf' });

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `OI_00${data.pk_ps_id}.pdf`;

      // Append the link to the body and click it to trigger download
      document.body.appendChild(a);
      a.click();

      // Clean up: remove the temporary link and URL object
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading invoice:', error);
    }
  };



  const downloadReceiptOfflineCustom = async (data) => {
    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
        invoice_data: data,
        pk_student_kay: Id,
        security_key: security_key,
        mode: 'getDownloadReceiptOfflineCustom'
      }, {
        responseType: 'blob' // Ensure response type is blob for binary data (PDF)
      });

      // Create a blob object from the PDF Blob data received
      const blob = new Blob([response.data], { type: 'application/pdf' });

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `OR_00${data.pk_ps_id}.pdf`;

      // Append the link to the body and click it to trigger download
      document.body.appendChild(a);
      a.click();

      // Clean up: remove the temporary link and URL object
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading invoice:', error);
    }
  };

  // Sample columns configuration
  const columns = [


    {
      headerName: 'Session Name',
      field: "Session Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.sest_name == null || params.row.sest_name == '' ? '-' : params.row.sest_name}`;
      },

    },
    {
      headerName: 'Enrol Date',
      field: "Enrol Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.enrol_start_date == null || params.row.enrol_start_date == '' ? '-' : Moment(params.row.enrol_start_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }

        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');

        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {

      headerName: 'Start Date',
      field: "Start Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.ses_start_date == null || params.row.ses_start_date == '' ? '-' : Moment(params.row.ses_start_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }

        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');

        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },
    },
    {

      headerName: 'End Date',
      field: "End Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.ses_date == null || params.row.ses_date == '' ? '-' : Moment(params.row.ses_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }

        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');

        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {
      headerName: 'Day',
      field: "Day",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.sest_day == null || params.row.sest_day == '' ? '-' : params.row.sest_day}`;
      },


    },
    {
      headerName: 'Session Start Time',
      field: "Session Start Time",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.sest_day == null || params.row.sest_day == '' ? '-' : Moment(params.row.sest_start_time, "HH:mm:ss").format('HH:mm')}`;
      },

    },
    {

      headerName: 'Tutor',
      field: "Tutor",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.tut_fname == null || params.row.tut_fname == '' ? '-' : params.row.tut_fname + ' ' + params.row.tut_surname}`;
      },


    },
    {

      headerName: 'Class',
      field: "Class",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.sest_is_holidays == 1 ? 'Regular' : (params.row.sest_is_holidays == 2) ? 'One Off Session' : (params.row.sest_is_holidays == 3) ? 'Make up Session' : 'N/A'}`;
      },

    },
    {

      headerName: 'Subject',
      field: "Subject",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.subject_name == null || params.row.subject_name == '' ? '-' : params.row.subject_name}`;
      },

    },
    {

      headerName: 'Payment Method',
      field: "pp_pay_method",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        if (!params.row.pp_pay_method) {

          return 'Payment not yet mapped';
        }
        return `${params.row.pp_pay_method == null || params.row.pp_pay_method == '' ? '-' : parseInt(params.row.pp_pay_method) === 1 ? 'Online' : parseInt(params.row.pp_pay_method) === 2 ? 'Offline' : '-'}`;
      },

    },
    {

      headerName: 'Product',
      field: "pp_name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.pp_name == null || params.row.pp_name == '' ? '-' : params.row.pp_name}`;
      },

    },
    // {

    //   headerName: "Amount (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
    //   field: "pp_amount",
    //   headerClassName: "super-app-theme--header",
    //   flex: 1,
    //   valueGetter: (params) => {
    //     return `${params.row.pp_amount == null || params.row.pp_amount == '' ? '-' : params.row.pp_amount}`;
    //   },

    // },
    {

      headerName: 'Frequency',
      field: "fm_name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.fm_name == null || params.row.fm_name == '' ? '-' : params.row.fm_name}`;
      },

    },

    {
      headerName: 'Payment Service',
      field: "eps_pay_service",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.eps_pay_service == null || params.row.eps_pay_service == '' ? '-' : parseInt(params.row.eps_pay_service) === 1 ? 'Prepaid' : parseInt(params.row.eps_pay_service) === 2 ? 'PostPaid' : '-'}`;
      },
    },
    {
      headerName: "Action",
      type: "actions",
      field: "actions",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => {
        const row = params.row;
        const isPaymentMethodMissing = !row.pp_pay_method ||
          row.pp_pay_method === null ||
          row.pp_pay_method === '';


        if (isPaymentMethodMissing) {
          return (<div
            className="d-flex align-items-center justify-content-between pad-12 gap-2"
            style={{ gap: "8px" }}
          >
          <Buttonmui variant="outlined-red" onClick={() => handleEnrollmentdata(row)} className="primary-blue my-2">Edit Subject </Buttonmui>
            <Buttonmui variant="outlined-red" onClick={() => {
              setModalShowAddproduct(true);
              setaddproductsessionData(row);
            }} className="primary-blue my-2">Add Product </Buttonmui>
          </div>);
        } else {
          // Conditional rendering: Check if sest_is_holidays is not 3
          if (row.sest_is_holidays !== 3) {
            return (
              <div
                className="d-flex align-items-center justify-content-between pad-12 gap-2"
                style={{ gap: "8px" }}
              >
                <Buttonmui variant="outlined-red" onClick={() => handleEnrollmentdata(row)} className="primary-blue my-2">Edit Subject </Buttonmui>
                <Buttonmui
                  variant="outlined-red"
                  onClick={() => {
                    setModalShowEdit(true);
                    seteditsessionData(row);
                  }}
                  className="primary-blue my-2"
                >
                  Edit product
                </Buttonmui>
              </div>
            );
          }

          // Return null (no button) when sest_is_holidays is 3
          return (<div
            className="d-flex align-items-center justify-content-between pad-12 gap-2"
            style={{ gap: "8px" }}
          >
            <Buttonmui variant="outlined-red" onClick={() => handleEnrollmentdata(row)} className="primary-blue my-2">Edit Subject </Buttonmui>
          </div>);
        }
      },
    },


    // {
    //   field: "actions",
    //   type: "actions",

    //   //field: "#",
    //   //  type: "actions",
    //   headerName: "Action",
    //   headerClassName: "super-app-theme--header",
    //   width: 250,
    //   // selector: (row) => row.payslip,
    //   // sortable: true,
    //   //id: "id",

    //   //cell: (row) => (<><Button variant="outlined-red" onClick={() => handleForfeitClick(row)} className="primary-outlined" >Forfeit </Button><Button variant="outlined-red" onClick={() => handleWriteoffClick(row)} className="primary-outlined" >Write off </Button></>),
    //   headerAlign:"left",
    //   renderCell: (params) => {
    //     const row = params.row;

    //     // if (row.pay_gateway_response_code == 'Authorised') {

    //     //   return (
    //     //     <div className='d-flex flex-wrap pad-12'>

    //     //       Completed
    //     //     </div>
    //     //   );
    //     // }
    //     // else {
    //       return (
    //         <div className='d-flex  pad-12'>

    //           {/* <Buttonmui variant="outlined-red" onClick={() => openPopupGP(row)} className="primary-blue my-2" disabled={isSubmittingPayment}>Generate Payment </Buttonmui> */}

    //           <Buttonmui variant="outlined-red" onClick={() => { setPrice(row); toggleModalPrice(); }} className="primary-blue my-2">Edit Price </Buttonmui>
    //         </div>
    //       );
    //     // }

    //   }
    // },


    // {
    //   name: " Write off",
    //   // selector: (row) => row.payslip,
    //   // sortable: true,
    //   cell:  row  => (<Button variant="outlined-red" onClick={() => handleWriteoffClick(row)} className="primary-outlined" >Write off </Button> )
    // },



  ];



  // Sample columns configuration
  const columns_l = [

    {

      headerName: 'Session Name',
      field: "Session Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_name == null || params.row.sest_name == '' ? '-' : params.row.sest_name}`;
      },

    },
    {

      headerName: 'Enrol Date',
      field: "Enrol Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.enrol_start_date == null || params.row.enrol_start_date == '' ? '-' : Moment(params.row.enrol_start_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }

        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');

        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {
      headerName: 'Start Date',
      field: "Start Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ses_start_date == null || params.row.ses_start_date == '' ? '-' : Moment(params.row.ses_start_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }

        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');

        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {
      headerName: 'End Date',
      field: "End Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ses_date == null || params.row.ses_date == '' ? '-' : Moment(params.row.ses_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }

        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');

        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {
      headerName: 'Day',
      field: "Day",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_day == null || params.row.sest_day == '' ? '-' : params.row.sest_day}`;
      },

    },
    {
      headerName: 'Session Start Time',
      field: "Session Start Time",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_day == null || params.row.sest_day == '' ? '-' : Moment(params.row.sest_start_time, "HH:mm:ss").format('HH:mm')}`;
      },

    },
    {

      headerName: 'Tutor',
      field: "Tutor",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.tut_fname == null || params.row.tut_fname == '' ? '-' : params.row.tut_fname + ' ' + params.row.tut_surname}`;
      },

    },
    {

      headerName: 'Class',
      field: "Class",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${(params.row.sest_is_holidays == 1) ? 'Regular' : (params.row.sest_is_holidays == 2) ? 'One Off Session' : (params.row.sest_is_holidays == 3) ? 'Make up Session' : 'N/A'}`;
      },

    },
    {
      headerName: 'Subject',
      field: "Subject",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.subject_name == null || params.row.subject_name == '' ? '-' : params.row.subject_name}`;
      },


    },
    {

      headerName: 'Unenrol Date',
      field: "Unenrol Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.enrol_disc_date == null || params.row.enrol_disc_date == '' ? '-' : Moment(params.row.enrol_disc_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }

        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');

        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {

      headerName: 'Reason',
      field: "Reason",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.enrol_disc_reason == null || params.row.enrol_disc_reason == '' ? '-' : params.row.enrol_disc_reason}`;
      },

    },

  ];



  // Sample columns configuration
  const columns_p = [

    {

      headerName: 'Enrolment Date',
      field: "Enrolment Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.enrol_start_date == null || params.row.enrol_start_date == '' ? '-' : Moment(params.row.enrol_start_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }

        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');

        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {

      headerName: 'Session Name',
      field: "Session Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_name == null || params.row.sest_name == '' ? '-' : params.row.sest_name}`;
      },


    },
    {

      headerName: 'Day',
      field: "Day",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_day == null || params.row.sest_day == '' ? '-' : params.row.sest_day}`;
      },

    },
    // {
    //   name: 'Class',
    //   selector: row => (row.sest_is_holidays == 1) ? 'Regular' : (row.sest_is_holidays == 2) ? 'One Off Session' : (row.sest_is_holidays == 3) ? 'Make up Session': 'N/A',

    //   sortable: true
    // },

    {
      headerName: 'Tutor',
      field: "Tutor",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.tut_fname == null || params.row.tut_fname == '' ? '-' : params.row.tut_fname}`;
      },

    },

    {
      headerName: 'Subject',
      field: "Subject",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.subject_name == null || params.row.subject_name == '' ? '-' : params.row.subject_name}`;
      },


    },
    {
      headerName: "Deposit (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "Deposit (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        const paystatus = params.row.regdeposit_status == 1 ? 'Paid' : 'Unpaid';
        return `${params.row.enrol_deposit == null || params.row.enrol_deposit == '' ? '-' : params.row.enrol_deposit + ' - (' + paystatus + ')'}`;
      },

    },
    {

      headerName: "Registration Fees (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "Registration Fees (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        const paystatus = params.row.regdeposit_status == 1 ? 'Paid' : 'Unpaid';
        return `${params.row.enrol_reg_fees == null || params.row.enrol_reg_fees == '' ? '-' : params.row.enrol_reg_fees + ' - (' + paystatus + ')'}`;
      },


    },
    {
      headerName: "Fees (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "Fees (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        // const paystatus = params.row.regdeposit_status == 1 ? 'Paid' : 'Unpaid';

        return `${params.row.enrol_price == null || params.row.enrol_price == '' ? '-' : params.row.enrol_price}`;
      },

    },



  ];

  // Sample columns configuration
  const columns_ph = [

    {

      headerName: 'Enrolment Date',
      field: "Enrolment Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.enrol_start_date == null || params.row.enrol_start_date == '' ? '-' : Moment(params.row.enrol_start_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }

        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');

        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {

      headerName: 'Session Name',
      field: "Session Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_name == null || params.row.sest_name == '' ? '-' : params.row.sest_name}`;
      },


    },
    {

      headerName: 'Day',
      field: "Day",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_day == null || params.row.sest_day == '' ? '-' : params.row.sest_day}`;
      },

    },
    // {
    //   name: 'Class',
    //   selector: row => (row.sest_is_holidays == 1) ? 'Regular' : (row.sest_is_holidays == 2) ? 'One Off Session' : (row.sest_is_holidays == 3) ? 'Make up Session': 'N/A',

    //   sortable: true
    // },

    {
      headerName: 'Tutor',
      field: "Tutor",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.tut_fname == null || params.row.tut_fname == '' ? '-' : params.row.tut_fname}`;
      },

    },

    {
      headerName: 'Subject',
      field: "Subject",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.subject_name == null || params.row.subject_name == '' ? '-' : params.row.subject_name}`;
      },


    },
    {
      headerName: "Deposit (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "Deposit (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        const paystatus = params.row.regdeposit_status == 1 ? 'Paid' : 'Unpaid';
        return `${params.row.enrol_deposit == null || params.row.enrol_deposit == '' ? '-' : params.row.enrol_deposit + ' - (' + paystatus + ')'}`;
      },

    },
    {

      headerName: "Registration Fees (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "Registration Fees (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        const paystatus = params.row.regdeposit_status == 1 ? 'Paid' : 'Unpaid';
        return `${params.row.enrol_reg_fees == null || params.row.enrol_reg_fees == '' ? '-' : params.row.enrol_reg_fees + ' - (' + paystatus + ')'}`;
      },


    },
    {
      headerName: "Fees (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "Fees (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return (
          <div className='d-flex  pad-12'>
            {/* enrollment-receipts
fees-view */}

            <Buttonmui variant="outlined-red" onClick={() => handleViewFees(row)} className="primary-blue my-2" >View </Buttonmui>


          </div>
        );
      }
      // valueGetter: (params) => {
      //   return `${params.row.enrol_price == null || params.row.enrol_price == '' ? '-' : params.row.enrol_price}`;
      // },

    },

    {
      field: "actions",
      type: "actions",

      //field: "#",
      //  type: "actions",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 250,
      // selector: (row) => row.payslip,
      // sortable: true,
      //id: "id",
      headerAlign: "left",
      //cell: (row) => (<><Button variant="outlined-red" onClick={() => handleForfeitClick(row)} className="primary-outlined" >Forfeit </Button><Button variant="outlined-red" onClick={() => handleWriteoffClick(row)} className="primary-outlined" >Write off </Button></>),

      renderCell: (params) => {
        const row = params.row;

        // if (row.pay_gateway_response_code == 'Authorised') {

        //   return (
        //     <div className='d-flex flex-wrap pad-12'>

        //       Completed
        //     </div>
        //   );
        // }
        // else {
        return (
          <div className='d-flex  pad-12'>

            {/* <Buttonmui variant="outlined-red" onClick={() => openPopupGP(row)} className="primary-blue my-2" disabled={isSubmittingPayment}>Receipts </Buttonmui> */}
            <Buttonmui variant="outlined-red" onClick={() => handleEnrollmentReceipts(row)} className="primary-blue my-2">Receipts </Buttonmui>

          </div>
        );
        // }

      }
    },

  ];

  // Sample columns configuration
  const columns_c = [


    {

      headerName: "Session Name",
      field: "Session Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_name == null || params.row.sest_name == '' ? '-' : params.row.sest_name}`;
      },
    },
    {

      headerName: "Enrol Date",
      field: "Enrol Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.enrol_start_date == null || params.row.enrol_start_date == '' ? '-' : Moment(params.row.enrol_start_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }

        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');

        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {
      headerName: "Start Date",
      field: "Start Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ses_start_date == null || params.row.ses_start_date == '' ? '-' : Moment(params.row.ses_start_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }

        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');

        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {

      headerName: "End Date",
      field: "End Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ses_date == null || params.row.ses_date == '' ? '-' : Moment(params.row.ses_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }

        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');

        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {
      headerName: "Day",
      field: "Day",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_day == null || params.row.sest_day == '' ? '-' : params.row.sest_day}`;
      },
    },
    {
      headerName: "Session Start Time",
      field: "Session Start Time",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_start_time == null || params.row.sest_start_time == '' ? '-' : Moment(params.row.sest_start_time, "HH:mm:ss").format('HH:mm')}`;
      },

    },
    {
      headerName: "Tutor",
      field: "Tutor",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.tut_fname == null || params.row.tut_fname == '' ? '-' : params.row.tut_fname + ' ' + params.row.tut_surname}`;
      },


    },
    {
      headerName: "Class",
      field: "Class",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${(params.row.sest_is_holidays == 1) ? 'Regular' : (params.row.sest_is_holidays == 2) ? 'One Off Session' : (params.row.sest_is_holidays == 3) ? 'Make up Session' : 'N/A'}`;
      },


    },
    {
      headerName: "Subject",
      field: "Subject",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.subject_name == null || params.row.subject_name == '' ? '-' : params.row.subject_name}`;

      },

    },
    {

      headerName: 'Product',
      field: "pp_name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.pp_name == null || params.row.pp_name == '' ? '-' : params.row.pp_name}`;
      },

    },
    // {

    //   headerName: "Amount (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
    //   field: "pp_amount",
    //   headerClassName: "super-app-theme--header",
    //   flex: 1,
    //   valueGetter: (params) => {
    //     return `${params.row.pp_amount == null || params.row.pp_amount == '' ? '-' : params.row.pp_amount}`;
    //   },

    // },
    {

      headerName: 'Frequency',
      field: "fm_name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.fm_name == null || params.row.fm_name == '' ? '-' : params.row.fm_name}`;
      },

    },
    {

      headerName: 'Payment Method',
      field: "pp_pay_method",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.pp_pay_method == null || params.row.pp_pay_method == '' ? '-' : parseInt(params.row.pp_pay_method) === 1 ? 'Online' : parseInt(params.row.pp_pay_method) === 2 ? 'Offline' : '-'}`;
      },

    },
    {
      headerName: 'Payment Service',
      field: "eps_pay_service",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.eps_pay_service == null || params.row.eps_pay_service == '' ? '-' : parseInt(params.row.eps_pay_service) === 1 ? 'Prepaid' : parseInt(params.row.eps_pay_service) === 2 ? 'PostPaid' : '-'}`;
      },
    },


  ];



  const columns_stripePayment = [

    {

      headerName: 'Receipt No',
      field: "recipt_no",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.recipt_no && params.row.recipt_no != '' ? params.row.recipt_no : '-'}`;
      },
    },
    {

      headerName: 'From Date',
      field: "From Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.period_start && params.row.period_start != '' ? Moment.unix(params.row.period_start).format('DD-MM-YYYY') : '-'}`;
      },
    },
    {
      headerName: 'To Date',
      field: "To Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.period_end && params.row.period_end != '' ? Moment.unix(params.row.period_end).format('DD-MM-YYYY') : '-'}`;
      },
    },
    {
      headerName: "Amount(" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "Amount",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.number && params.row.number != '' ? params.row.amount_due / 100 : '-'}`;
      },
    },
    {
      headerName: 'Status',
      field: "Status",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.paid && params.row.paid != '' ? params.row.paid ? 'Paid' : 'Unpaid' : '-'}`;
      },
      renderCell: (params) => {
        const row = params.row;
        return (
          <>
            <div className={`text-${row.paid ? 'success' : 'danger'}`}>{row.paid ? 'Paid' : 'Unpaid'}</div>
            {/* <Buttonmui variant="outlined-red" onClick={() => handleViewFees(row)} className="primary-blue my-2" >View </Buttonmui> */}
          </>
        );
      }

    },
    {
      headerName: 'Invoice',
      field: "Action",
      type: "actions",
      headerClassName: "super-app-theme--header",
      flex: 1,
      headerAlign: "left",
      renderCell: (params) => {
        const row = params.row;
        return (
          <div className='d-flex  pad-12'>

            <Buttonmui variant="outlined-red" onClick={() => downloadInvoice(row.id)} className="primary-blue my-2" >Stripe</Buttonmui>
            {row.period_start && row.period_end ? <Buttonmui variant="outlined-red" onClick={() => downloadInvoiceCustom(row.recipt_no, Moment.unix(params.row.period_start).format('YYYY-MM-DD'), Moment.unix(params.row.period_end).format('YYYY-MM-DD'), row.id_row)} className="primary-blue my-2" >TCMS </Buttonmui> : null}
          </div>
        );
      }

    }


  ];


  const columns_offlinePayment = [

    {

      headerName: 'Payment Mode',
      field: "pm_name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.pm_name && params.row.pm_name != '' ? params.row.pm_name : '-'}`;
      },
    },
    {
      headerName: "Payment Frequency",
      field: "spst_freq_type",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.spst_freq_type && params.row.spst_freq_type != '' ? parseInt(params.row.spst_freq_type) === 1 ? 'Weekly' : 'Monthly' : 'Weekly'}`;
      },
    },
    {

      headerName: 'From Date',
      field: "spst_from_date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.spst_from_date == null || params.row.spst_from_date == '' ? '-' : Moment(params.row.spst_from_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }

        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');

        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },
    },
    {
      headerName: 'To Date',
      field: "To Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.spst_to_date == null || params.row.spst_to_date == '' ? '-' : Moment(params.row.spst_to_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }

        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');

        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },
    },
    {
      headerName: "Total Session",
      field: "spst_ses_count",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.spst_ses_count && params.row.spst_ses_count != '' ? params.row.spst_ses_count : '0'}`;
      },
    },
    {
      headerName: "Amount(" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "Amount",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.spst_amount && params.row.spst_amount != '' ? params.row.spst_amount : '0'}`;
      },
    },
    {
      headerName: "Total Amount(" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "spst_total_amount",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.spst_total_amount && params.row.spst_total_amount != '' ? params.row.spst_total_amount : '0'}`;
      },
    },
    {
      headerName: 'Status',
      field: "Status",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.paid && params.row.paid != '' ? parseInt(params.row.paid) === 1 ? 'Paid' : 'Unpaid' : '-'}`;
      },
      renderCell: (params) => {
        const row = params.row;
        return (
          <>
            {parseInt(row.paid) === 2 ? <Buttonmui variant="outlined-red" onClick={() => setPaidData(row)} className="primary-blue my-2" >Set as Paid </Buttonmui> : <div className={`text-${parseInt(row.paid) === 1 ? 'success' : 'danger'}`}>{parseInt(row.paid) === 1 ? 'Paid' : 'Unpaid'}</div>
            }{/* <Buttonmui variant="outlined-red" onClick={() => handleViewFees(row)} className="primary-blue my-2" >View </Buttonmui> */}
          </>
        );
      }

    },
    {
      headerName: 'Invoice',
      field: "Action",
      type: "actions",
      headerClassName: "super-app-theme--header",
      flex: 1,
      headerAlign: "left",
      renderCell: (params) => {
        const row = params.row;
        return (
          <div className='d-flex  pad-12'>
            {parseInt(row.paid) === 1 ? <Buttonmui variant="outlined-red" onClick={() => downloadInvoiceOfflineCustom(row)} className="primary-blue my-2" >Download </Buttonmui> : '-'}
          </div>
        );
      }

    }



  ];



  const columns_deposit = [

    {

      headerName: 'Deposit Product Name',
      field: "pp_name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.pp_name && params.row.pp_name != '' ? params.row.pp_name : '-'}`;
      },
    },
    {

      headerName: "Deposit Amount(" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "pp_total_amount",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.pp_total_amount && params.row.pp_total_amount != '' ? params.row.pp_total_amount : '-'}`;
      },
    },
    {

      headerName: 'Payment Method',
      field: "pp_pay_method",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.pp_pay_method == null || params.row.pp_pay_method == '' ? '-' : parseInt(params.row.pp_pay_method) === 1 ? 'Online' : parseInt(params.row.pp_pay_method) === 2 ? 'Offline' : '-'}`;
      },
    },
    {
      headerName: 'Status',
      field: "Status",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.paid_status && params.row.paid_status != '' ? parseInt(params.row.paid_status) === 1 ? 'Paid' : 'Unpaid' : '-'}`;
      },
      renderCell: (params) => {
        const row = params.row;
        return (
          <>
            {parseInt(row.paid_status) === 0 ? <Buttonmui variant="outlined-red" onClick={() => setPaidData(row)} className="primary-blue my-2" >Set as Paid </Buttonmui> : <div className={`text-${parseInt(row.paid_status) === 1 ? 'success' : 'danger'}`}>{parseInt(row.paid_status) === 1 ? 'Paid' : 'Unpaid'}</div>
            }{/* <Buttonmui variant="outlined-red" onClick={() => handleViewFees(row)} className="primary-blue my-2" >View </Buttonmui> */}
          </>
        );
      }

    },
    {
      headerName: 'Payment mode',
      field: "Payment mode",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.paid_status && params.row.paid_status != '' ? parseInt(params.row.paid_status) === 1 ? parseInt(params.row.pp_pay_method) === 1 ? 'Stripe' : params.row.pm_name : '-' : '-'}`;
      },
    },
    {
      headerName: 'Invoice',
      field: "Action",
      type: "actions",
      headerClassName: "super-app-theme--header",
      flex: 1,
      headerAlign: "left",
      renderCell: (params) => {
        const row = params.row;
        return (
          <div className='d-flex  pad-12'>
            <Buttonmui variant="outlined-red" onClick={() => downloadInvoiceDepositCustom(row)} className="primary-blue my-2" >Download </Buttonmui>
          </div>
        );
      }

    },
    {
      headerName: 'Receipt',
      field: "Receipt",
      type: "actions",
      headerClassName: "super-app-theme--header",
      flex: 1,
      headerAlign: "left",
      renderCell: (params) => {
        const row = params.row;
        return (
          <div className='d-flex  pad-12'>
            {parseInt(row.paid_status) === 1 ? <Buttonmui variant="outlined-red" onClick={() => downloadRecieptDepositCustom(row)} className="primary-blue my-2" >Download </Buttonmui> : '-'}
          </div>
        );
      }

    }

  ];


  const columns_offline = [

    {
      headerName: 'Session Name',
      field: "Session Name",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      //flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_name && params.row.sest_name != '' ? params.row.sest_name : '-'}`;
      },
    },
    {

      headerName: 'Product Name',
      field: "pp_name",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      //flex: 1,
      valueGetter: (params) => {
        return `${params.row.pp_name && params.row.pp_name != '' ? params.row.pp_name : '-'}`;
      },
    },
    {

      headerName: 'Date',
      field: "Date",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      // flex: 1,
      valueGetter: (params) => {
        return `${params.row.ps_date == null || params.row.ps_date == '' ? '-' : Moment(params.row.ps_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }

        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');

        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },
    },
    // {

    //   headerName: 'Due Date',
    //   field: "Due Date",
    //   headerClassName: "super-app-theme--header",
    //   flex: 1,
    //   valueGetter: (params) => {
    //     return `${params.row.ps_end_date == null || params.row.ps_end_date == '' ? '-' : Moment(params.row.ps_end_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
    //   },
    //   sortComparator: (valueA, valueB, rowA, rowB, direction) => {
    //     if (valueA === '-' || valueB === '-') {
    //       // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
    //       return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
    //     }

    //     const dateA = Moment(valueA, 'DD-MM-YYYY');
    //     const dateB = Moment(valueB, 'DD-MM-YYYY');

    //     return direction === 'asc' ? dateA - dateB : dateB - dateA;
    //   },
    // },
    {

      headerName: 'No Of Session',
      field: "No Of Session",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      //flex: 1,
      valueGetter: (params) => {
        return `${params.row.ps_ses_count && params.row.ps_ses_count != '' ? params.row.ps_ses_count : '0'}`;
      },
    },
    {

      headerName: 'No Of Free Session',
      field: "No Of Free Session",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.ps_free_ses_count && params.row.ps_free_ses_count != '' ? params.row.ps_free_ses_count : '0'}`;
      },
    },
    {

      headerName: "Amount(" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "ps_amount",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.ps_amount && params.row.ps_amount != '' ? params.row.ps_amount : '0'}`;
      },
    },
    {
      headerName: 'Method Of Payment',
      field: "pp_method",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.pp_method == null || params.row.pp_method == '' ? '-' : parseInt(params.row.pp_method) === 1 ? 'Block' : parseInt(params.row.pp_method) === 2 ? 'Session' : '-'}`;
      },
    },
    {
      headerName: 'Payment Service',
      field: "eps_pay_service",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.eps_pay_service == null || params.row.eps_pay_service == '' ? '-' : parseInt(params.row.eps_pay_service) === 1 ? 'Prepaid' : parseInt(params.row.eps_pay_service) === 2 ? 'PostPaid' : '-'}`;
      },
    },
    {
      headerName: 'Status',
      field: "Status",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.ps_status && params.row.ps_status != '' ? parseInt(params.row.ps_status) === 1 ? 'Paid' : parseInt(params.row.ps_status) === 2 ? 'Cancel' : 'Unpaid' : '-'}`;
      },
      renderCell: (params) => {
        const row = params.row;
        return (
          <>
            {parseFloat(row.ps_amount) >= 0 ?
              parseInt(row.ps_status) === 0 ? <Buttonmui variant="outlined-red" className="primary-blue my-2" onClick={() => { setOfflinePaidData(row) }} >Set as Paid </Buttonmui>
                : <div className={`text-${parseInt(row.ps_status) === 1 ? 'success' : 'danger'}`}>{parseInt(row.ps_status) === 1 ? 'Paid' : parseInt(row.ps_status) === 2 ? 'Cancel' : 'Unpaid'}</div>
              : '-'}</>
        );
      }

    }, {
      headerName: 'Payment Command',
      field: "ps_command",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.ps_command == null || params.row.ps_command == '' ? '-' : params.row.ps_command}`;
      },
    },
    {
      headerName: 'Payment Mode',
      field: "Payment mode",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.ps_status && params.row.ps_status != '' ? parseInt(params.row.ps_status) === 1 ? parseInt(params.row.pp_pay_method) === 1 ? 'Stripe' : params.row.pm_name : '-' : '-'}`;
      },
    },
    {
      headerName: 'No Of Transfer Session',
      field: "rp_ses_count",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.rp_ses_count == null || params.row.rp_ses_count == '' ? '-' : params.row.rp_ses_count}`;
      },
    },
    {
      headerName: "Transfer Amount(" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "rp_amount",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.rp_amount == null || params.row.rp_amount == '' ? '-' : params.row.rp_amount}`;
      },
    },
    {
      headerName: 'Transfer Status',
      field: "Refund Status",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.rp_status && params.row.rp_status != '' ? parseInt(params.row.rp_status) === 1 ? 'Refunded' : 'Non refunded' : '-'}`;
      },
      renderCell: (params) => {
        const row = params.row;
        return (
          <>
            {parseFloat(row.rp_amount) > 0 ?
              parseInt(row.rp_status) === 0 ? <Buttonmui variant="outlined-red" className="primary-blue my-2" onClick={() => { setOfflineRefundData(row) }}>Refund </Buttonmui>
                : <div className={`text-${parseInt(row.rp_status) === 1 ? 'success' : 'danger'}`}>{parseInt(row.ps_status) === 1 ? 'Refunded' : 'Non refunded'}</div>
              : '-'}</>
        );
      }

    },
    {
      headerName: 'Invoice',
      field: "Action",
      type: "actions",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      headerAlign: "left",
      renderCell: (params) => {
        const row = params.row;
        return (
          <div className='d-flex  pad-12'>
            <Buttonmui variant="outlined-red" onClick={() => downloadInvoiceOfflineCustom(row)} className="primary-blue my-2" >Download </Buttonmui>
          </div>
        );
      }

    },
    {
      headerName: 'Receipt',
      field: "Receipt",
      type: "actions",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      headerAlign: "left",
      renderCell: (params) => {
        const row = params.row;
        return (
          <div className='d-flex  pad-12'>
            {parseInt(row.ps_status) === 1 ? <Buttonmui variant="outlined-red" onClick={() => downloadReceiptOfflineCustom(row)} className="primary-blue my-2" >Download </Buttonmui> : '-'}
          </div>
        );
      }

    }

  ];
  const columns_online = [

    {
      headerName: 'Session Name',
      field: "Session Name",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      //flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_name && params.row.sest_name != '' ? params.row.sest_name : '-'}`;
      },
    },
    {

      headerName: 'Product Name',
      field: "pp_name",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      //flex: 1,
      valueGetter: (params) => {
        return `${params.row.pp_name && params.row.pp_name != '' ? params.row.pp_name : '-'}`;
      },
    },
    {

      headerName: 'Date',
      field: "Date",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.ps_from_date == null || params.row.ps_from_date == '' ? '-' : Moment(params.row.ps_from_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }

        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');

        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },
    },
    // {

    //   headerName: 'Due Date',
    //   field: "Due Date",
    //   headerClassName: "super-app-theme--header",
    //   flex: 1,
    //   valueGetter: (params) => {
    //     return `${params.row.ps_end_date == null || params.row.ps_end_date == '' ? '-' : Moment(params.row.ps_end_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
    //   },
    //   sortComparator: (valueA, valueB, rowA, rowB, direction) => {
    //     if (valueA === '-' || valueB === '-') {
    //       // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
    //       return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
    //     }

    //     const dateA = Moment(valueA, 'DD-MM-YYYY');
    //     const dateB = Moment(valueB, 'DD-MM-YYYY');

    //     return direction === 'asc' ? dateA - dateB : dateB - dateA;
    //   },
    // },
    {

      headerName: 'No Of Session',
      field: "No Of Session",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.ps_ses_count && params.row.ps_ses_count != '' ? params.row.ps_ses_count : '0'}`;
      },
    },
    {

      headerName: 'No Of Free Session',
      field: "No Of Free Session",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.ps_free_ses_count && params.row.ps_free_ses_count != '' ? params.row.ps_free_ses_count : '0'}`;
      },
    },
    {

      headerName: "Amount(" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "ps_amount",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.ps_amount && params.row.ps_amount != '' ? params.row.ps_amount : '0'}`;
      },
    },
    {
      headerName: 'Method Of Payment',
      field: "pp_method",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.pp_method == null || params.row.pp_method == '' ? '-' : parseInt(params.row.pp_method) === 1 ? 'Block' : parseInt(params.row.pp_method) === 2 ? 'Session' : '-'}`;
      },
    },
    {
      headerName: 'Payment Service',
      field: "eps_pay_service",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.eps_pay_service == null || params.row.eps_pay_service == '' ? '-' : parseInt(params.row.eps_pay_service) === 1 ? 'Prepaid' : parseInt(params.row.eps_pay_service) === 2 ? 'PostPaid' : '-'}`;
      },
    },
    {
      headerName: 'Status',
      field: "Status",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.ps_status && params.row.ps_status != '' ? parseInt(params.row.ps_status) === 1 ? 'Paid' : parseInt(params.row.ps_status) === 2 ? 'Cancel' : 'Unpaid' : '-'}`;
      },
      renderCell: (params) => {
        const row = params.row;
        return (
          <>
            {parseFloat(row.ps_amount) >= 0 ?
              <div className={`text-${parseInt(row.ps_status) === 1 ? 'success' : 'danger'}`}>{parseInt(row.ps_status) === 1 ? 'Paid' : parseInt(params.row.ps_status) === 2 ? 'Cancel' : 'Unpaid'}</div>
              : <div className={`text-danger`}>-</div>}</>
        );
      }

    }, {
      headerName: 'Payment Command',
      field: "ps_command",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.ps_command == null || params.row.ps_command == '' ? '-' : params.row.ps_command}`;
      },
    },
    {
      headerName: 'Payment Mode',
      field: "Payment mode",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.ps_status && params.row.ps_status != '' ? parseInt(params.row.ps_status) === 1 ? parseInt(params.row.pp_pay_method) === 1 ? 'Stripe' : params.row.pm_name : '-' : '-'}`;
      },
    },
    {
      headerName: 'No Of Transfer Session',
      field: "rp_ses_count",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.rp_ses_count == null || params.row.rp_ses_count == '' ? '-' : params.row.rp_ses_count}`;
      },
    },
    {
      headerName: "Transfer Amount(" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "rp_amount",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.rp_amount == null || params.row.rp_amount == '' ? '-' : params.row.rp_amount}`;
      },
    },
    {
      headerName: 'Transfer Status',
      field: "Refund Status",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.rp_status && params.row.rp_status != '' ? parseInt(params.row.rp_status) === 1 ? 'Refunded' : 'Non refunded' : '-'}`;
      },
      renderCell: (params) => {
        const row = params.row;
        return (
          <>
            {parseFloat(row.rp_amount) > 0 ?
              parseInt(row.rp_status) === 0 ? <Buttonmui variant="outlined-red" className="primary-blue my-2" onClick={() => { setOfflineRefundData(row) }}>Refund </Buttonmui>
                : <div className={`text-${parseInt(row.rp_status) === 1 ? 'success' : 'danger'}`}>{parseInt(row.ps_status) === 1 ? 'Refunded' : 'Non refunded'}</div>
              : '-'}</>
        );
      }

    },
    {
      headerName: 'Stripe Status',
      field: "ps_stripe_status",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.ps_stripe_status == null || params.row.ps_stripe_status == '' ? '-' : params.row.ps_stripe_status}`;
      },
    },
    {
      headerName: 'Invoice',
      field: "Action",
      type: "actions",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      headerAlign: "left",
      renderCell: (params) => {
        const row = params.row;
        return (
          <div className='d-flex  pad-12'>
            <Buttonmui variant="outlined-red" onClick={() => downloadInvoiceOfflineCustom(row)} className="primary-blue my-2" >Download </Buttonmui>
          </div>
        );
      }

    },
    {
      headerName: 'Receipt',
      field: "Receipt",
      type: "actions",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      headerAlign: "left",
      renderCell: (params) => {
        const row = params.row;
        return (
          <div className='d-flex  pad-12'>
            {parseInt(row.ps_status) === 1 ? <Buttonmui variant="outlined-red" onClick={() => downloadReceiptOfflineCustom(row)} className="primary-blue my-2" >Download </Buttonmui> : '-'}
          </div>
        );
      }

    }

  ];


  const columns_refund = [

    {
      headerName: 'Session Name',
      field: "Session Name",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      //flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_name && params.row.sest_name != '' ? params.row.sest_name : '-'}`;
      },
    },
    {

      headerName: 'Product Name',
      field: "pp_name",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      //flex: 1,
      valueGetter: (params) => {
        return `${params.row.pp_name && params.row.pp_name != '' ? params.row.pp_name : '-'}`;
      },
    },
    {

      headerName: 'Date',
      field: "Date",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.ps_from_date == null || params.row.ps_from_date == '' ? '-' : Moment(params.row.ps_from_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }

        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');

        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },
    },
    {

      headerName: 'Remaining No Of Session',
      field: "No Of Session",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.ps_ses_count && params.row.ps_ses_count != '' ? params.row.ps_ses_count : '0'}`;
      },
    },
    {

      headerName: "Remaining Amount(" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "ps_amount",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.ps_amount && params.row.ps_amount != '' ? params.row.ps_amount : '0'}`;
      },
    },
    {
      headerName: 'Method Of Payment',
      field: "pp_method",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.pp_method == null || params.row.pp_method == '' ? '-' : parseInt(params.row.pp_method) === 1 ? 'Block' : parseInt(params.row.pp_method) === 2 ? 'Session' : '-'}`;
      },
    },
    {
      headerName: 'Payment Service',
      field: "eps_pay_service",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.eps_pay_service == null || params.row.eps_pay_service == '' ? '-' : parseInt(params.row.eps_pay_service) === 1 ? 'Prepaid' : parseInt(params.row.eps_pay_service) === 2 ? 'PostPaid' : '-'}`;
      },
    },
    {
      headerName: 'Payment Mode',
      field: "Payment mode",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.rp_status && params.row.rp_status != '' ? parseInt(params.row.rp_status) === 1 ? parseInt(params.row.pp_pay_method) === 1 ? 'Stripe' : params.row.pm_name : '-' : '-'}`;
      },
    },
    {
      headerName: 'No Of Transfer Session',
      field: "rp_ses_count",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.rp_ses_count == null || params.row.rp_ses_count == '' ? '-' : params.row.rp_ses_count}`;
      },
    },
    {
      headerName: "Transfer Amount(" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "rp_amount",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.rp_amount == null || params.row.rp_amount == '' ? '-' : params.row.rp_amount}`;
      },
    },
    {
      headerName: 'Transfer Status',
      field: "Refund Status",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      valueGetter: (params) => {
        return `${params.row.rp_status && params.row.rp_status != '' ? parseInt(params.row.rp_status) === 1 ? 'Refunded' : 'Non refunded' : '-'}`;
      },
      renderCell: (params) => {
        const row = params.row;
        return (
          <>
            {parseFloat(row.rp_amount) > 0 ?
              row.manage_sest_name && row.manage_sest_name != '' ?
                <div className={`text-success`}>Transfer to "{row.manage_sest_name}"</div>
                :
                parseInt(row.rp_status) === 0 ? <Buttonmui variant="outlined-red" className="primary-blue my-2" onClick={() => { setOfflineRefundData(row) }}>Refund </Buttonmui>
                  :
                  <div className={`text-${parseInt(row.rp_status) === 1 ? 'success' : 'danger'}`}>{parseInt(row.ps_status) === 1 ? 'Refunded' : 'Non refunded'}</div>

              : '-'}</>
        );
      }

    },
    {
      headerName: 'Stripe Status',
      field: "rp_stripe_status",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.rp_stripe_status == null || params.row.rp_stripe_status == '' ? '-' : params.row.rp_stripe_status}`;
      },
    },

  ];


  const [modalShowAdd, setModalShowAdd] = useState(false);
  const [modalShowNotify, setModalShowNotyfy] = useState(false);

  const [showModal1, setShowModal1] = useState(false);
  const toggleModal1 = () => setShowModal1(!showModal1);

  const [showModalPrice, setShowModalPrice] = useState(false);
  const toggleModalPrice = () => setShowModalPrice(!showModalPrice);

  const [showModalDeposit, setShowModalDeposit] = useState(false);
  const toggleModalDeposit = () => setShowModalDeposit(!showModalDeposit);


  const [modalShowEdit, setModalShowEdit] = useState(false);
  const [editSessionData, seteditsessionData] = useState([]);

  // yogesh 20/01/2025
  const [modalShowAddproduct, setModalShowAddproduct] = useState(false);
  const [addproductsessionData, setaddproductsessionData] = useState([]);


  const basicSchema = yup.object().shape({

    enrol_start_date: yup
      .string()
      .test('is-valid-date', 'Please provide a valid date.', function (value) {
        const parsedDate = dayjs(value, 'YYYY-MM-DD');
        return parsedDate.isValid();
      }).required("Date is required."),
    fk_sub_id: yup.array().min(1, 'Subject is required.'),
    fk_sest_key: yup.mixed().required("Session is required."),
    enrol_category: yup.mixed().required("Category is required."),
    sest_class: yup.bool(), // Assuming sest_class is a boolean checkbox


    enrol_product: yup.mixed().when(['pp_pay_method', 'sest_class'], {
      is: (ppVal, sestClass) => !!ppVal && sestClass === false,
      then: () => yup.mixed().required("Product is required."),
      otherwise: () => yup.mixed().notRequired()

    }),
    // pp_pay_method : yup.mixed().when('sest_class', {
    //   is: false,
    //   then: () => yup.mixed().required("Payment method is required."),
    // }),
    enrol_pay_serice: yup.mixed().when(['pp_pay_method', 'sest_class'], {
      is: (ppVal, sestClass) => !!ppVal && sestClass === false,
      then: () => yup.mixed().required("Payment service is required."),
      otherwise: () => yup.mixed().notRequired()
    }),
    // enrol_reg_fees: yup.mixed().when('sest_class', {
    //   is: false,
    //   then: () => yup.mixed().required("Fees is required."),
    // }),
    // enrol_deposit: yup.mixed().when('sest_class', {
    //   is: false,
    //   then: () => yup.mixed().required("Deposit is required."),
    // }),



    // sest_class: yup.mixed(),
    // enrol_price: yup.mixed().when('sest_class', {
    //   is: true,
    //   then: yup.mixed().notRequired() // Skip validation when isChecked is true (checked)
    // }),
    // enrol_price:  yup.string().required('Fees is required.'),


    //  enrol_price: yup.mixed().required('Fees is required.'),

    //   enrol_reg_fees: yup.mixed().required('Fees is required.'),

    //   enrol_deposit:yup.mixed().required('Deposit is required.'),

    //enrol_price_type: yup.mixed().required("Type is required."),
    enrol_reg_fees: yup.number().integer().min(0, "Minimum value 0 required"),
    enrol_deposit: yup.number().integer().min(0, "Minimum value 0 required"),
    //fk_pt_key: yup.mixed().required("Product type is required."),
    fk_pt_key: yup.mixed().when(['pp_pay_method', 'sest_class'], {
      is: (ppVal, sestClass) => !!ppVal && sestClass === false,
      then: () => yup.mixed().required("Product type is required."),
      otherwise: () => yup.mixed().notRequired()
    }),
  });

  const initialValuesmain = {
    /*tbl_student_enrol*/


    fk_sub_id: [],
    fk_sest_key: '',
    sest_class: false,
    enrol_category: "In Centre",
    enrol_product: '',
    enrol_price: 0,
    enrol_start_date: "",
    enrol_reg_fees: 0,
    enrol_price_type: "",
    enrol_deposit: 0,
    pp_pay_method: StudentData && StudentData.spt_type ? StudentData.spt_type : '',
    fk_pt_key: '',
    enrol_pay_serice: '',
    fk_stu_key: Id,
    fk_cent_key: sessionStorage.getItem('key'),
    created_by: sessionStorage.getItem('key'),
  };

  const formik = useFormik({
    initialValues: initialValuesmain,
    validationSchema: basicSchema,
    enableReinitialize: true,
    // onSubmit,
    onSubmit: async (formValues) => {
      setIsSubmitting(true);


      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "createStudentEnrolmentWithPaymentMethod",
          ...formValues,
          ...PaymentVerifyData,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Your form was submitted", {
              autoClose: 3000, // 3 seconds in this example
            });
            fetchData(Id);
            fetchMasterData(Id);
            fetchMasterSessionData(Id);
            // setShow(false);
            setModalShowAdd(false);
            //setModalShowNotyfy(true);

            resetForm();
            // After 3 seconds, redirect to another page
            // setTimeout(function () {
            //handleClose();
            // }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

            setIsSubmitting(false); // Re-enable the submit button
          } else if (response.data.data == 2) {
            toast.error("Sorry! The session was deleted. Please try another session.", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button
          } else if (response.data.data == 3) {
            toast.error("Sorry! Generate session is not available after enrolment date. Try another session or date.", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to create student enrolled. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button
          }

        } else {

          toast.error("Failure! Unable to create student enrolled. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmitting(false); // Re-enable the submit button

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to create student enrolled. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmitting(false); // Re-enable the submit button

      } finally {
        setIsSubmitting(false); // Re-enable the submit button

      }

    },
  });

  // Now, you can set variables as needed:
  const values = formik.values;
  const handleBlur = formik.handleBlur;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const errors = formik.errors;
  const handleSubmit = formik.handleSubmit;
  const setTouched = formik.setTouched;
  const resetForm = formik.resetForm;
  const setValues = formik.setValues;
  const handleReset = formik.handleReset;

  const resetFormFields = (resetForm, setValues, initialValues) => {
    resetForm(); // Reset Formik-controlled fields

  };


  //yogesh start
  const basicSchemaedit = yup.object().shape({
    enrol_category: yup.mixed().required("Category is required."),

    enrol_product: yup.mixed().when("sest_class", {
      is: false,
      then: () => yup.mixed().required("Product is required."),
    }),
    // pp_pay_method: yup.mixed().when("sest_class", {
    //   is: false,
    //   then: () => yup.mixed().required("Payment method is required."),
    // }),
    pp_pay_method: yup
      .string()
      .required("Payment method is required") // Adjust this as per your requirement
      .test(
        "is-valid-payment-method",
        "Online payment not verified.",
        (value) => {
          // Validate the payment method here (assuming valid payment methods are passed as options)
          return options_pay_method.some(
            (option) => option.value.toString() === value.toString()
          );
        }
      ),
    enrol_pay_serice: yup.mixed().when("sest_class", {
      is: false,
      then: () => yup.mixed().required("Payment service is required."),
    }),
  });

  const initialValuesmainedit = {
    /* tbl_student_enrol */
    fk_sub_id: editSessionData?.fk_sub_id || [],
    subject_name: editSessionData?.subject_name || [],
    fk_sest_key: editSessionData?.fk_sest_key || "",
    sest_name: editSessionData?.sest_name || "",
    sest_class: editSessionData?.sest_class || false,
    enrol_category: editSessionData?.enrol_category || "In Centre",
    enrol_product: editSessionData?.pk_pp_key || "",

    enrol_start_date: editSessionData?.enrol_start_date || "",
    pk_esp_key: editSessionData?.pk_esp_key || "",
    enrol_price_type: editSessionData?.enrol_price_type || "",

    pp_pay_method: editSessionData?.pp_pay_method || "",
    fk_pt_key: editSessionData?.fk_pt_key || "",
    enrol_pay_serice: editSessionData?.eps_pay_service || "",
    fk_stu_key: editSessionData?.fk_stu_key || Id,
    fk_cent_key: editSessionData?.fk_cent_key || sessionStorage.getItem("key"),
    created_by: editSessionData?.created_by || sessionStorage.getItem("key"),
  };

  const formikedit = useFormik({
    initialValues: initialValuesmainedit,
    validationSchema: basicSchemaedit,
    enableReinitialize: true,
    // onSubmit,
    onSubmit: async (formValues) => {
      setIsSubmitting(true);

      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "editenrollSessionProduct",
          ...formValues,
          ...PaymentVerifyData,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Payment Product changed Successfully", {
              autoClose: 3000, // 3 seconds in this example
            });
            fetchData(Id);
            fetchMasterData(Id);
            fetchMasterSessionData(Id);

            setModalShowEdit(false);

            resetForm();

            setIsSubmitting(false); // Re-enable the submit button
          } else if (response.data.data == 2) {
            toast.error("Sorry! No changes made", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button
          } else {
            toast.error(
              "Failure! Upable to update the product. Try again later",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );

            setIsSubmitting(false); // Re-enable the submit button
          }
        } else {
          toast.error(
            "Failure! Unable to update the product. Try again later",
            {
              autoClose: 3000, // 3 seconds in this example
            }
          );

          setIsSubmitting(false); // Re-enable the submit button
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to update the product. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmitting(false); // Re-enable the submit button
      } finally {
        setIsSubmitting(false); // Re-enable the submit button
      }
    },
  });

  // Now, you can set variables as needed:
  const valuesedit = formikedit.values;
  const handleBluredit = formikedit.handleBlur;
  const touchededit = formikedit.touched;
  const handleChangeedit = formikedit.handleChange;
  const errorsedit = formikedit.errors;
  const handleSubmitedit = formikedit.handleSubmit;
  const setTouchededit = formikedit.setTouched;
  const resetFormedit = formikedit.resetForm;
  const setValuesedit = formikedit.setValues;
  const setFieldError = formikedit.setFieldError;
  const handleResetedit = formikedit.handleReset;

  useEffect(() => {
    fetchMasterProductData(
      formikedit.values.pp_pay_method,
      formikedit.values.fk_pt_key
    );
  }, [formikedit.values.pp_pay_method]);

  //yogesh end

  //yogesh add product start
  const basicSchemaaddproduct = yup.object().shape({

    enrol_product: yup.mixed().when("sest_class", {
      is: false,
      then: () => yup.mixed().required("Product is required."),
    }),
    pp_pay_method: yup.mixed().when("sest_class", {
      is: false,
      then: () => yup.mixed().required("Payment method is required."),
    }),
    pp_pay_method: yup
      .string()
      .required("Payment method is required") // Adjust this as per your requirement
      .test(
        "is-valid-payment-method",
        "Online payment not verified.",
        (value) => {
          // Validate the payment method here (assuming valid payment methods are passed as options)
          return options_pay_method.some(
            (option) => option.value.toString() === value.toString()
          );
        }
      ),
    fk_pt_key: yup.mixed().when('sest_class', {
      is: false,
      then: () => yup.mixed().required("Product type is required."),
      otherwise: () => yup.mixed().notRequired()
    }),
    enrol_pay_serice: yup.mixed().when("sest_class", {
      is: false,
      then: () => yup.mixed().required("Payment service is required."),
    }),
  });

  const initialValuesmainaddproduct = {
    /* tbl_student_enrol */
    fk_sub_id: addproductsessionData?.fk_sub_id || [],
    subject_name: addproductsessionData?.subject_name || [],
    fk_sest_key: addproductsessionData?.fk_sest_key || "",
    sest_name: addproductsessionData?.sest_name || "",
    sest_class: addproductsessionData?.sest_class || false,
    enrol_category: addproductsessionData?.enrol_category || "In Centre",
    enrol_product: addproductsessionData?.pk_pp_key || "",

    enrol_start_date: addproductsessionData?.enrol_start_date || "",
    pk_esp_key: addproductsessionData?.pk_esp_key || "",
    pk_enrol_key: addproductsessionData?.pk_enrol_key || "",
    enrol_price_type: addproductsessionData?.enrol_price_type || "",

    pp_pay_method: addproductsessionData?.pp_pay_method || "",
    fk_pt_key: addproductsessionData?.fk_pt_key || "",
    enrol_pay_serice: addproductsessionData?.eps_pay_service || "",
    fk_stu_key: addproductsessionData?.fk_stu_key || Id,
    fk_cent_key: addproductsessionData?.fk_cent_key || sessionStorage.getItem("key"),
    created_by: addproductsessionData?.created_by || sessionStorage.getItem("key"),
    billing_start_date: dayjs().format("YYYY-MM-DD"),
  };

  const formikaddproduct = useFormik({
    initialValues: initialValuesmainaddproduct,
    validationSchema: basicSchemaaddproduct,
    enableReinitialize: true,
    // onSubmit,
    onSubmit: async (formValues) => {
      setIsSubmitting(true);

      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "AddproductenrollmentWithPaymentMethod",
          ...formValues,
          ...PaymentVerifyData,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Payment Product Added Successfully", {
              autoClose: 3000, // 3 seconds in this example
            });
            fetchData(Id);
            fetchMasterData(Id);
            fetchMasterSessionData(Id);

            setModalShowAddproduct(false);

            resetFormaddproduct();

            setIsSubmitting(false); 
          }  else {
            toast.error(
              "Failure! Upable to add the product. Try again later",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );
            resetFormaddproduct();
            setIsSubmitting(false); // Re-enable the submit button
          }
        } else {
          toast.error(
            "Failure! Unable to add the product. Try again later",
            {
              autoClose: 3000, // 3 seconds in this example
            }
          );
          resetFormaddproduct();
          setIsSubmitting(false); // Re-enable the submit button
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to add the product. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
        resetFormaddproduct();
        setIsSubmitting(false); // Re-enable the submit button
      } finally {
        resetFormaddproduct();
        setIsSubmitting(false); // Re-enable the submit button
      }
    },
  });

  // Now, you can set variables as needed:
  const valuesaddproduct = formikaddproduct.values;
  const handleBluraddproduct = formikaddproduct.handleBlur;
  const touchedaddproduct = formikaddproduct.touched;
  const handleChangeaddproduct = formikaddproduct.handleChange;
  const errorsaddproduct = formikaddproduct.errors;
  const handleSubmitaddproduct = formikaddproduct.handleSubmit;
  const setTouchedaddproduct = formikaddproduct.setTouched;
  const resetFormaddproduct = formikaddproduct.resetForm;
  const setValuesaddproduct = formikaddproduct.setValues;
  const setFieldErroraddproduct = formikaddproduct.setFieldError;
  const handleResetaddproduct = formikaddproduct.handleReset;
  

  // useEffect(() => {
  //   fetchMasterProductData(
  //     formikaddproduct.values.pp_pay_method,
  //     formikaddproduct.values.fk_pt_key
  //   );
  // }, [formikaddproduct.values.pp_pay_method]);

  //yogesh add product end


  const validationSchema1 = yup.object().shape({
    pk_enrol_key_e: yup.string().required('Session is required.'),
    enrol_disc_date: yup
      .string()
      .test('is-valid-date', 'Please provide a valid date.', function (value) {
        const parsedDate = dayjs(value, 'YYYY-MM-DD');
        return parsedDate.isValid();
      }).required('Date is required.'),
    enrol_disc_reason: yup.string().required('Reason is required.').matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
  });


  const initialValues1 = {
    pk_enrol_key_e: '',
    enrol_disc_date: new Date().toISOString().split("T")[0],
    enrol_disc_reason: '',
    updated_by: sessionStorage.getItem('key'),
  };


  const formik1 = useFormik({
    initialValues: initialValues1,
    validationSchema: validationSchema1,

    onSubmit: async (values) => {
      setIsSubmittingUnenrol(true);


      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "StudentUnenrolmentwithRefund",
          ...values,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Your form was submitted", {
              autoClose: 3000, // 3 seconds in this example
            });

            // setShow(false);
            fetchData(Id);
            fetchMasterData(Id);
            fetchMasterSessionData(Id);
            resetForm();
            toggleModal1();



            setIsSubmittingUnenrol(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to create student unenrolled. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmittingUnenrol(false); // Re-enable the submit button
          }

        } else {

          toast.error("Failure! Unable to create student unenrolled. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmittingUnenrol(false); // Re-enable the submit button

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to create student unenrolled. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmittingUnenrol(false); // Re-enable the submit button

      } finally {
        setIsSubmittingUnenrol(false); // Re-enable the submit button

      }

    },
  });




  function onSubmitForfeit(type) {
    setIsSubmittingForfeit(true);

    const responseforfeit = axios.post(BASE_URL + "API/api_index.php", {

      security_key: security_key,
      fk_cent_key: sessionStorage.getItem('key'),

      mode: "changeStudentEnrolmentForfeitWriteoff",
      pk_enrol_key: data_forfeit.pk_enrol_key,
      fw_session_date: data_forfeit.ses_date,
      fw_session_time: data_forfeit.ses_start_time,
      fw_sest_key: data_forfeit.fk_sest_key,
      fw_type: type,
    }).then((responseforfeit) => {
      setForfeitShow(false)
      // Handle the successful response here
      toast.success("Success! Your form was submitted", {
        autoClose: 3000, // 3 seconds in this example
      });
      setTimeout(function () {
        window.location.reload(false);

      }, 3000);

    })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to forfeit or writeoff. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
      });






  }
  const handleChangeCategory = async (e) => {
    SetSestTypeData(e);
  };

  useEffect(() => {
    // This will run after the component renders or whenever SestTypeData changes
    formik.setFieldValue("fk_sest_key", '');
    formik.setFieldTouched("fk_sest_key", false);

    if (Id != null && Id != '') {

      fetchMasterSessionData(Id, values.sest_class, values.fk_pt_key);
    }
  }, [SestTypeData, values.sest_class, values.fk_pt_key]);



  const handleClassChange = async (e) => {
    formik.setFieldValue("fk_sest_key", '');
    formik.setFieldTouched("fk_sest_key", false);


  };

  // const openPopupGP = (dataGP) => {

  //   setDataGP(dataGP);
  //   setShowPopupGeneratePayment(!showPopupGeneratePayment);
  // };
  const closePopupGeneratePayment = () => {
    setShowPopupGeneratePayment(false);
  };



  const basicSchema2 = yup.object().shape({
    payment_emailid: yup.string().email("Please enter a valid email address").required("Email is required.").matches(/^[^$&+,:;=?@#|'<>.^*()%!-]+/, 'Input cannot start with a special character').test('no-dot', 'Please enter a valid email address', function (value) {
      return value && value.includes('.');
    }),

  });
  const initialValues2 = {
    /*tbl_center_master*/
    payment_emailid: StudentData ? StudentData.par_emailid != '' && StudentData.par_emailid != null && (StudentData.st_is_adultlearner != null && StudentData.st_is_adultlearner == 0) ? StudentData.par_emailid : '' : '', //Centre name-1

    created_by: sessionStorage.getItem('key'),
    visibility: "1",
  };


  // const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched, resetForm, setValues } = useFormik({
  const formik2 = useFormik({
    initialValues: initialValues2,
    validationSchema: basicSchema2,
    enableReinitialize: true,
    // onSubmit,

    onSubmit: async (formValues) => {
      setIsSubmittingPayment(true);

      try {
        const responseSE = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          fk_cent_key: sessionStorage.getItem('key'),
          mode: "generatePaymentMail",
          pk_enrol_key: DataGP.pk_enrol_key,
          fk_stu_key: DataGP.fk_stu_key,
          st_email: DataGP.st_email,
          st_first_name: DataGP.st_first_name,
          st_surname: DataGP.st_surname,
          ...formValues,
        });

        if (responseSE.data.success === true) {

          // Reset the form
          //formRef.current.reset();
          if (responseSE.data.data === 1) {
            toast.success("Success! Your enrolment request mail has been successfully sent.", {
              autoClose: 3000, // 3 seconds in this example
            });


            setIsSubmittingPayment(false);
            setShowPopupGeneratePayment(false);
            window.open(responseSE.data.dataRes, '_blank', 'noopener,noreferrer');

          } else {
            toast.error("Failure! Your enrolment request mail has been not sent. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setTimeout(function () {
              setIsSubmittingPayment(false);
              setShowPopupGeneratePayment(false)
            }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
          }

        } else {

          if (responseSE.data.data === 2) {
            toast.error("Failure! Your enrolment request mail has been not sent. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });
          } else {



            toast.error("Failure! Your enrolment request mail has been not sent. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });
          }

          setTimeout(function () {
            setIsSubmittingPayment(false);
            setShowPopupGeneratePayment(false)
          }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Your enrolment request mail has been not sent. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setTimeout(function () {
          setIsSubmittingPayment(false);
          setShowPopupGeneratePayment(false)
        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
      } finally {
        setTimeout(function () {
          setIsSubmittingPayment(false);
          setShowPopupGeneratePayment(false)
        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
      }

    },
  });




  // function onSubmitGeneratePayment(data) {

  //   // window.location.href = BASE_URL+'webpages/otp-checkout.php'; //relative to domain

  //   setIsSubmittingPayment(true);



  //   const responseforfeit = axios.post(BASE_URL + "API/api_index.php", {

  //     security_key: security_key,
  //     fk_cent_key: sessionStorage.getItem('key'),
  //     mode: "generatePaymentMail",
  //     pk_enrol_key: data.pk_enrol_key,
  //     fk_stu_key: data.fk_stu_key,
  //     st_email: data.st_email,
  //     st_first_name: data.st_first_name,
  //     st_surname: data.st_surname,

  //   }).then((responseforfeit) => {
  //     setIsSubmittingPayment(false);
  //     // Handle the successful response here
  //     toast.success("Success! Your payment request mail has been successfully sent. ", {
  //       autoClose: 5000, // 3 seconds in this example
  //     });
  //     setTimeout(function () {
  //       window.location.reload(false);

  //     }, 5000);
  //     setIsSubmittingPayment(false);

  //   })
  //     .catch((error) => {
  //       // Handle any errors that occurred during the request
  //       toast.error("Oops! Something went wrong.", {
  //         autoClose: 5000, // 3 seconds in this example
  //       });
  //       setIsSubmittingPayment(false);

  //     });






  // }

  const validationSchema3 = yup.object().shape({
    enrol_price: yup.mixed().required("Fees is required."),
    // enrol_reg_fees: yup.mixed().required("Fees is required."),
    // enrol_deposit: yup.mixed().required("Deposit is required.")
  });


  const initialValues3 = {
    pk_enrol_key: dataPrice ? dataPrice.pk_enrol_key : '',
    enrol_sest_name: dataPrice ? dataPrice.sest_name : '',
    enrol_price: dataPrice ? dataPrice.enrol_price : '',
    enrol_reg_fees: dataPrice ? dataPrice.enrol_reg_fees : '',
    enrol_deposit: dataPrice ? dataPrice.enrol_deposit : '',
    updated_by: sessionStorage.getItem('key')
  };


  const formik3 = useFormik({
    initialValues: initialValues3,
    validationSchema: validationSchema3,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSubmittingPrice(true);


      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "updateStudentPriceEnrolment",
          ...values,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Your form was submitted", {
              autoClose: 3000, // 3 seconds in this example
            });

            // setShow(false);
            fetchData(Id);
            //fetchMasterData(Id);
            resetForm();
            toggleModalPrice();



            setIsSubmittingPrice(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to update price. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmittingPrice(false); // Re-enable the submit button
          }

        } else {

          toast.error("Failure! Unable to update price. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmittingPrice(false); // Re-enable the submit button

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to update price. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmittingPrice(false); // Re-enable the submit button

      } finally {
        setIsSubmittingPrice(false); // Re-enable the submit button

      }

    },
  });



  const handleViewFees = async (e) => {

    navigate(`/fees-view`, { state: { fk_stu_key: `${e.fk_stu_key}`, fk_sest_key: `${e.fk_sest_key}`, pk_enrol_key: `${e.pk_enrol_key}`, sest_name: `${e.sest_name}`, enrol_start_date: `${e.enrol_start_date}`, sest_day: `${e.sest_day}` } });
  }
  const handleEnrollmentReceipts = async (e) => {
    navigate(`/enrollment-receipts`, { state: { fk_stu_key: `${e.fk_stu_key}`, fk_sest_key: `${e.fk_sest_key}`, pk_enrol_key: `${e.pk_enrol_key}`, sest_name: `${e.sest_name}`, enrol_start_date: `${e.enrol_start_date}`, sest_day: `${e.sest_day}` } });
  }



  //Deposit Paid functionality

  const [isSubmittingPaid, setIsSubmittingPaid] = useState(false);
  const [PaidData, setPaidData] = useState(null);
  const [showModalPaid, setShowModalPaid] = useState(false);
  const [showOtherField, setShowOtherField] = useState(false);
  const toggleModalPaid = () => setShowModalPaid(!showModalPaid);


  const validationSchemaPaid = yup.object().shape({
    //spst_transaction_id: yup.string().required('Transaction ID is required.'),
    pp_pay_method_e: yup.number().notRequired(),
    spst_transaction_id: yup.string().when('pp_pay_method_e', {
      is: 2,
      then: () => yup.string().required('Transaction ID is required.'),
    }),
    pk_cpm_key_e: yup.string().when('pp_pay_method_e', {
      is: 2,
      then: () => yup.string().required('Payment Mode is required.'),
    }),
  });



  const initialValuesPaid = {
    spst_transaction_id: '',
    pp_pay_method_e: '',
    pk_cpm_key_e: '',
    fk_cent_key: sessionStorage.getItem('key'),
  };


  const formikPaid = useFormik({
    initialValues: initialValuesPaid,
    validationSchema: validationSchemaPaid,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSubmittingPaid(true);


      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "addDepositPaymentByStudent",
          ...values,
          ...PaidData
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! The amount has been paid successfully.", {
              autoClose: 3000, // 3 seconds in this example
            });

            // setShow(false);
            fetchData(Id);
            fetchMasterData(Id);
            setPaidData(null);
            toggleModalPaid();



            setIsSubmittingPaid(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to paid the amount. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmittingPaid(false); // Re-enable the submit button
          }

        } else {

          toast.error("Failure! Unable to paid the amount. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmittingPaid(false); // Re-enable the submit button

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to paid the amount. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmittingPaid(false); // Re-enable the submit button

      } finally {
        setIsSubmittingPaid(false); // Re-enable the submit button

      }

    },
  });




  useEffect(() => {

    if (PaidData != null && PaidData != '') {

      if (PaidData.pp_pay_method) {
        setShowOtherField(false);
        formikPaid.setFieldValue(
          'pp_pay_method_e',
          PaidData.pp_pay_method
        );
      } else {
        setShowOtherField(false);
      }
      setShowModalPaid(true);
    }
  }, [PaidData]);









  const validationSchemaDeposit = yup.object().shape({
    fk_pt_key: yup.mixed().required('Product type is required.'),
    fk_pp_key: yup.mixed().required('Product is required.'),
  });


  const initialValuesDeposit = {
    fk_pt_key: '',
    fk_pp_key: '',
    pp_pay_method: StudentData && StudentData.spt_type ? StudentData.spt_type : '',
    fk_stu_key: Id,
    fk_cent_key: sessionStorage.getItem('key'),
  };


  const formikDeposit = useFormik({
    initialValues: initialValuesDeposit,
    validationSchema: validationSchemaDeposit,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSubmittingDeposit(true);


      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "addStudentDepositData",
          ...values,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Deposit has been added.", {
              autoClose: 3000, // 3 seconds in this example
            });

            // setShow(false);
            fetchData(Id);
            fetchMasterData(Id);
            fetchMasterSessionData(Id);

            toggleModalDeposit();



            setIsSubmittingDeposit(false); // Re-enable the submit button
          } else if (response.data.data === 2) {
            toast.error("Failure! Your card payment was declined.", {
              autoClose: 3000, // 3 seconds in this example
            });

            // setShow(false);
            fetchData(Id);
            fetchMasterData(Id);
            fetchMasterSessionData(Id);

            toggleModalDeposit();



            setIsSubmittingDeposit(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to create student deposit. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmittingDeposit(false); // Re-enable the submit button
          }

        } else {

          toast.error("Failure! Unable to create student deposit. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmittingDeposit(false); // Re-enable the submit button

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // toast.error("Failure! Unable to create student deposit. Try again later", {
        //   autoClose: 3000, // 3 seconds in this example
        // });

        setIsSubmittingDeposit(false); // Re-enable the submit button

      } finally {
        setIsSubmittingDeposit(false); // Re-enable the submit button

      }

    },
  });




  //Deposit Paid functionality

  const [isSubmittingOfflinePaid, setIsSubmittingOfflinePaid] = useState(false);
  const [OfflinePaidData, setOfflinePaidData] = useState(null);
  const [showModalOfflinePaid, setShowModalOfflinePaid] = useState(false);

  const toggleModalOfflinePaid = () => setShowModalOfflinePaid(!showModalOfflinePaid);


  const validationSchemaOfflinePaid = yup.object().shape({
    //spst_transaction_id: yup.string().required('Transaction ID is required.'),
    // pp_pay_method_e: yup.number().notRequired(),
    pk_cpm_key_e: yup.string().required('Payment Mode is required.'),
    eps_transaction_id: yup.string().required('Transaction ID is required.'),
  });



  const initialValuesOfflinePaid = {
    eps_transaction_id: '',
    pk_cpm_key_e: '',
    fk_cent_key: sessionStorage.getItem('key'),
  };


  const formikOfflinePaid = useFormik({
    initialValues: initialValuesOfflinePaid,
    validationSchema: validationSchemaOfflinePaid,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSubmittingOfflinePaid(true);


      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "addOfflinePaymentByStudentCenter",
          ...values,
          ...OfflinePaidData
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! The amount has been paid successfully.", {
              autoClose: 3000, // 3 seconds in this example
            });

            // setShow(false);
            fetchData(Id);
            fetchMasterData(Id);
            setOfflinePaidData(null);
            toggleModalOfflinePaid();



            setIsSubmittingOfflinePaid(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to paid the amount. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmittingOfflinePaid(false); // Re-enable the submit button
          }

        } else {

          toast.error("Failure! Unable to paid the amount. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmittingOfflinePaid(false); // Re-enable the submit button

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to paid the amount. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmittingOfflinePaid(false); // Re-enable the submit button

      } finally {
        setIsSubmittingOfflinePaid(false); // Re-enable the submit button

      }

    },
  });




  useEffect(() => {

    if (OfflinePaidData != null && OfflinePaidData != '') {

      setShowModalOfflinePaid(true);
    }
  }, [OfflinePaidData]);


  const [isSubmittingOfflineRefund, setIsSubmittingOfflineRefund] = useState(false);
  const [OfflineRefundData, setOfflineRefundData] = useState(null);
  const [showModalOfflineRefund, setShowModalOfflineRefund] = useState(false);

  const toggleModalOfflineRefund = () => setShowModalOfflineRefund(!showModalOfflineRefund);


  const validationSchemaOfflineRefund = yup.object().shape({
    //spst_transaction_id: yup.string().required('Transaction ID is required.'),
    // pp_pay_method_e: yup.number().notRequired(),
    //pk_cpm_key_e: yup.string().required('Payment Mode is required.'),
    //eps_transaction_id: yup.string().required('Transaction ID is required.'),
    pp_pay_method_e: yup.string().notRequired(),
    pk_cpm_key_e: yup.string().when('pp_pay_method_e', (pp_pay_method) => {
      if (parseInt(pp_pay_method) === 1) {
        return yup.string().notRequired();
      } else {
        return yup.string().required('Payment Mode is required.');
      }
    }
    ),
    eps_transaction_id: yup.string().when('pp_pay_method_e', (pp_pay_method) => {
      if (parseInt(pp_pay_method) === 1) {
        return yup.string().notRequired();
      } else {
        return yup.string().required('Transaction ID is required.');
      }
    }
    ),
    // pk_cpm_key_e: yup.string().when('pp_pay_method', {
    //   is: (val) => val !== '1',  // Check if 'pp_pay_method' is not '1'
    //   then: yup.string().required('Payment Mode is required.'),  // If true, make it required
    //   otherwise: yup.string().notRequired(),  // If false, make it not required
    // }),

    // eps_transaction_id: yup.string().when('pp_pay_method', {
    //   is: (val) => val !== '1',  // Check if 'pp_pay_method' is not '1'
    //   then: yup.string().required('Transaction ID is required.'),  // If true, make it required
    //   otherwise: yup.string().notRequired(),  // If false, make it not required
    // }),
  });



  const initialValuesOfflineRefund = {
    pp_pay_method_e: OfflineRefundData && OfflineRefundData.pp_pay_method ? OfflineRefundData.pp_pay_method : '',
    eps_transaction_id: '',
    pk_cpm_key_e: '',
    fk_cent_key: sessionStorage.getItem('key'),
  };


  const formikOfflineRefund = useFormik({
    initialValues: initialValuesOfflineRefund,
    validationSchema: validationSchemaOfflineRefund,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSubmittingOfflineRefund(true);


      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "makeRefundAmountProcess",
          ...values,
          ...OfflineRefundData
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! The amount has been refund successfully.", {
              autoClose: 3000, // 3 seconds in this example
            });

            // setShow(false);
            fetchData(Id);
            fetchMasterData(Id);
            setOfflineRefundData(null);
            toggleModalOfflineRefund();



            setIsSubmittingOfflineRefund(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to refund the amount. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmittingOfflineRefund(false); // Re-enable the submit button
          }

        } else {

          toast.error("Failure! Unable to refund the amount. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmittingOfflineRefund(false); // Re-enable the submit button

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to refund the amount. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmittingOfflineRefund(false); // Re-enable the submit button

      } finally {
        setIsSubmittingOfflineRefund(false); // Re-enable the submit button

      }

    },
  });


  // console.log(formikOfflineRefund.values);
  // console.log(formikOfflineRefund.errors);

  useEffect(() => {
    //console.log(OfflineRefundData)
    if (OfflineRefundData != null && OfflineRefundData != '') {

      setShowModalOfflineRefund(true);
    }
  }, [OfflineRefundData]);



  const [enrolledData, setEnrolledData] = useState(null);
  const [enrolledOptionSubjectData, setEnrolledOptionSubjectData] = useState([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [IsSubmittingEdit, setIsSubmittingEdit] = useState(false);
  const toggleModalEdit = () => setShowModalEdit(!showModalEdit);
 

  const validationSchema4 = yup.object().shape({
    enrol_subject: yup.array().min(1, 'Subject is required.'),
  });

  const initialValues4 = {
    pk_enrol_key: enrolledData && enrolledData.pk_enrol_key ? enrolledData.pk_enrol_key : '',
    enrol_sest_name: enrolledData && enrolledData.sest_name ? enrolledData.sest_name : '',
    enrol_subject: '',
    updated_by: sessionStorage.getItem('key')
  };


  const formik4 = useFormik({
    initialValues: initialValues4,
    validationSchema: validationSchema4,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSubmittingEdit(true);


      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "AddSubjectEnrollment",
          ...values,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Added Subject Sucessfully", {
              autoClose: 3000, // 3 seconds in this example
            });

            // setShow(false);
            fetchData(Id);

            resetForm();
            toggleModalEdit();

            setIsSubmittingEdit(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to added subject. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmittingEdit(false); // Re-enable the submit button
          }

        } else {

          toast.error("Failure! Unable to added subject. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmittingPrice(false); // Re-enable the submit button

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to added subject. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmittingEdit(false); // Re-enable the submit button

      } finally {
        setIsSubmittingEdit(false); // Re-enable the submit button

      }

    },
  });

  const handleEnrollmentdata = async (e) => {


    const subjectValues = e.subject_id.split(",").map(Number); // Convert "1,27" to [1, 27]

    const filteredData = options_subject.filter(item => subjectValues.includes(item.value));
    //const filteredOptionData = options_subject.filter(item => !subjectValues.includes(item.value));
    setEnrolledOptionSubjectData(options_subject);
    e.stujectArray = filteredData || [];
    e.stujectOptionArray = options_subject;
   
    setEnrolledData(e);
    toggleModalEdit();
  }

  useEffect(() => {
    if(enrolledData && enrolledData.stujectArray && enrolledData.stujectArray.length > 0){
      formik4.setFieldValue("enrol_subject", enrolledData.stujectArray);
    }
    
    
  }, [enrolledData]);


  return (
    <>
      <ToastContainer position="top-center" />

      <div className="page-wrapper">


        <Modal
          show={modalShowAdd} onHide={() => setModalShowAdd(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation={false}
        >
          <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5 className='page-title'> Add Enrolment </h5>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>

              {/* --------------Date--------------- */}
              <Row>


                {/* --------------Subject--------------- */}



                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Subject</h6>
                  <Select menuPlacement="auto" options={options_subject}
                    name="fk_sub_id"
                    value={values.fk_sub_id}
                    id="fk_sub_id"
                    isMulti
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "fk_sub_id",
                          value: selectedOption,
                        },
                      };
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, fk_sub_id: true });
                    }}
                    className={errors.fk_sub_id && touched.fk_sub_id ? "input-error" : "mb-3"} />
                  {errors.fk_sub_id && touched.fk_sub_id && <span className="error">{errors.fk_sub_id}</span>}
                </Col>



                {/* --------------Category--------------- */}

                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Enrolment Category</h6>
                  <Select menuPlacement="auto" options={options_dig_type}
                    name="enrol_category"
                    value={options_dig_type.find(option => option.value && values.enrol_category && option.value.toString() === values.enrol_category.toString())}
                    id="enrol_category"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "enrol_category",
                          value: selectedOption["value"],
                        },
                      };
                      handleChangeCategory(selectedOption["value"]);
                      // Assuming handleChange is asynchronous
                      // handleChange({ target: { name: 'fk_sest_key', value: '' } }, () => {
                      //   handleChange(event);
                      // });
                      //handleChange({ target: { name: 'fk_sest_key', value: '' } });

                      // formik.setFieldValue("fk_sest_key", '');
                      // formik.setFieldTouched("fk_sest_key", false);


                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, enrol_category: true });
                    }}
                    className={errors.enrol_category && touched.enrol_category ? "input-error" : "mb-3"} />
                  {errors.enrol_category && touched.enrol_category && <p className="error">{errors.enrol_category}</p>}
                </Col>

                {/* --------------Class Session--------------- */}


                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable ">Class</h6>
                  <Form.Check
                    type="checkbox"
                    label="Makeup Session"
                    name="sest_class"
                    id="sest_class"
                    checked={values.sest_class}
                    onClick={handleClassChange}
                    onChange={handleChange}
                    className='mt-2'
                  />
                  {/*  <Select menuPlacement="auto"  options={options_isholiday}
                    name="sest_is_holidays"
                   value={values.sest_is_holidays['value']}
                    id="sest_is_holidays"
                    onChange={(event) => {
                      handleClassChange(event);                     
                       setTouched({ ...touched, sest_is_holidays: true });


                    }}
                    
                    // onChange={(events) => {
                    //   const event = {
                    //     target: {
                    //       name: "sest_is_holidays",
                    //       value: events["value"],
                    //     },
                    //   };
                    //  // handleChange(event);
                    //   handleClassChange(event);                     

                    // }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, sest_is_holidays: true });
                    }}
                    className={errors.sest_is_holidays && touched.sest_is_holidays ? "input-error" : ""} />
                    {errors.sest_is_holidays && touched.sest_is_holidays && <p className="error">{errors.sest_is_holidays}</p>} */}

                </Col>

                {values.sest_class === false && (
                  <>
                    <Col lg="6" md="6" sm="12" className='position-relative'>
                      <h6 className="form-lable">Payment Method {['top'].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                              Note: Change the product dropdown options based on the product type and payment method
                            </Tooltip>
                          }
                        >
                          <Button variant="secondary" className="no-bdr">
                            <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                          </Button>
                        </OverlayTrigger>

                      ))}</h6>
                      <Select menuPlacement="auto" options={options_pay_method}
                        name="pp_pay_method"
                        value={options_pay_method.find(option => option.value && values.pp_pay_method && option.value.toString() === values.pp_pay_method.toString())}
                        id="pp_pay_method"
                        onChange={(selectedOption) => {
                          const event = {
                            target: {
                              name: "pp_pay_method",
                              value: selectedOption["value"],
                            },
                          };
                          const event2 = {
                            target: {
                              name: "enrol_product",
                              value: '',
                            },
                          };
                          formik.handleChange(event2);
                          formik.setFieldValue("enrol_product", '');
                          formik.setFieldTouched("enrol_product", false);
                          fetchMasterProductData(selectedOption["value"], values.fk_pt_key);


                          handleChange(event);
                        }}
                        onBlur={(event) => {
                          handleBlur(event);
                          setTouched({ ...touched, pp_pay_method: true });
                        }}
                        className={errors.pp_pay_method && touched.pp_pay_method ? "input-error" : "mb-3"}
                      />
                      {errors.pp_pay_method && touched.pp_pay_method && <span className="error">{errors.pp_pay_method}</span>}

                    </Col>
                    {values.pp_pay_method && (
                      <Col lg="6" md="6" sm="12" className='position-relative'>
                        <h6 className="form-lable required-field">Product Type  {['top'].map((placement) => (
                          <OverlayTrigger
                            key={placement}
                            placement={placement}
                            overlay={
                              <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                                Note: Change the product dropdown options based on the product type and payment method
                              </Tooltip>
                            }
                          >
                            <Button variant="secondary" className="no-bdr">
                              <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                            </Button>
                          </OverlayTrigger>

                        ))}</h6>
                        <Select menuPlacement="auto" options={options_product_type}
                          name="fk_pt_key"
                          value={options_product_type.find(option => option.value && values.fk_pt_key && option.value.toString() === values.fk_pt_key.toString())}
                          id="fk_pt_key"
                          onChange={(selectedOption) => {
                            const event = {
                              target: {
                                name: "fk_pt_key",
                                value: selectedOption["value"],
                              },
                            };
                            const event2 = {
                              target: {
                                name: "enrol_product",
                                value: '',
                              },
                            };
                            formik.handleChange(event2);
                            formik.setFieldValue("enrol_product", '');
                            formik.setFieldTouched("enrol_product", false);
                            fetchMasterProductData(values.pp_pay_method, selectedOption["value"]);
                            handleChange(event);
                          }}
                          onBlur={(event) => {
                            handleBlur(event);
                            setTouched({ ...touched, fk_pt_key: true });
                          }}
                          className={errors.fk_pt_key && touched.fk_pt_key ? "input-error" : "mb-3"}
                        />
                        {errors.fk_pt_key && touched.fk_pt_key && <span className="error">{errors.fk_pt_key}</span>}

                      </Col>)}</>)}
                {/* --------------Session--------------- */}

                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Session {['top'].map((placement) => (
                    <OverlayTrigger
                      key={placement}
                      placement={placement}
                      overlay={
                        <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                          Session options are controlled so that already completed sessions do not shown. Additionally, the date and time of sessions for which students are already enrolled are not shown. Furthermore, sessions that have reached their maximum student capacity are also not shown.
                        </Tooltip>
                      }
                    >
                      <Button variant="secondary" className="no-bdr">
                        <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                      </Button>
                    </OverlayTrigger>

                  ))}</h6>

                  <Select menuPlacement="auto" options={options_session}
                    name="fk_sest_key"

                    //isClearable={true} 
                    // value={values.fk_sest_key['value']}
                    value={options_session.find(option => option.value.toString() === values.fk_sest_key.toString())}
                    id="fk_sest_key"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "fk_sest_key",
                          value: selectedOption["value"],
                        },
                      };
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, fk_sest_key: true });
                    }}
                    className={errors.fk_sest_key && touched.fk_sest_key ? "input-error" : ""} />
                  {errors.fk_sest_key && touched.fk_sest_key && <span className="error">{errors.fk_sest_key}</span>}


                </Col>


                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Enrolment Date</h6>
                  {/* <Form.Control
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    value={values.enrol_start_date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="enrol_start_date"
                    className={errors.enrol_start_date && touched.enrol_start_date ? "input-error" : "mb-3"}
                  />
                  {errors.enrol_start_date && touched.enrol_start_date && <p className="error">{errors.enrol_start_date}</p>} */}




                  <LocalizationProvider className={'form-control'} dateAdapter={AdapterDayjs}>
                    <DatePicker views={['year', 'month', 'day']}
                      value={dayjs(values.enrol_start_date, 'YYYY-MM-DD')}
                      onChange={(date) => {
                        const event = {
                          target: {
                            name: "enrol_start_date",
                            value: date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date,
                          },
                        };
                        handleChange(event);
                      }}
                      onBlur={(event) => {
                        handleBlur(event);
                        setTouched({ ...touched, enrol_start_date: true });
                      }}
                      name="enrol_start_date"
                      slotProps={{
                        textField: {
                          readOnly: true, size: 'small',
                          id: 'enrol_start_date',
                        },
                      }}
                      className={errors.enrol_start_date && touched.enrol_start_date ? "input-error w-100" : "w-100"}
                      minDate={dayjs().subtract(1, 'year')}
                      format="DD-MM-YYYY"
                    />
                  </LocalizationProvider>
                  {errors.enrol_start_date && touched.enrol_start_date && <span className="error">{errors.enrol_start_date}</span>}
                </Col>



                {/* --------------Fees--------------- */}
                {values.sest_class === false && values.pp_pay_method && (
                  <>
                    {/* <Col lg="6" md="6" sm="12" className='position-relative'>
                    <h6 className="form-lable required-field">Product Type</h6>
                    <Select menuPlacement="auto" options={options_product_type}
                        name="fk_pt_key"
                        value={options_product_type.find(option => option.value && values.fk_pt_key && option.value.toString() === values.fk_pt_key.toString())}
                        id="fk_pt_key"
                        onChange={(selectedOption) => {
                            const event = {
                                target: {
                                    name: "fk_pt_key",
                                    value: selectedOption["value"],
                                },
                            };
                            fetchMasterProductData(values.pp_pay_method, selectedOption["value"]);
                            handleChange(event);
                        }}
                        onBlur={(event) => {
                            handleBlur(event);
                            setTouched({ ...touched, fk_pt_key: true });
                        }}
                        className={errors.fk_pt_key && touched.fk_pt_key ? "input-error" : ""}
                    />
                    {errors.fk_pt_key && touched.fk_pt_key && <span className="error">{errors.fk_pt_key}</span>}

                </Col> */}
                    <Col lg="6" md="6" sm="6" className='position-relative'>
                      <h6 className="form-lable required-field">Product {['top'].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                              Please select a product. Once the product is selected and you are enrolled in the subscription, it will no longer be editable.
                            </Tooltip>
                          }
                        >
                          <Button variant="secondary" className="no-bdr">
                            <i class="fa-solid fa-info-circle ml-2 mr-2"></i>
                          </Button>
                        </OverlayTrigger>

                      ))}</h6>
                      <Select menuPlacement="auto" options={options_pricing}
                        name="enrol_product"
                        value={options_pricing.find(option => option.value.toString() === values.enrol_product.toString())}
                        id="enrol_product"
                        onChange={(selectedOption) => {
                          const event = {
                            target: {
                              name: "enrol_product",
                              value: selectedOption["value"],
                            },
                          };
                          handleChange(event);
                        }}
                        onBlur={(event) => {
                          handleBlur(event);
                          setTouched({ ...touched, enrol_product: true });
                        }}
                        className={errors.enrol_product && touched.enrol_product ? "input-error" : "mb-3"} />
                      {errors.enrol_product && touched.enrol_product && <span className="error">{errors.enrol_product}</span>}
                    </Col>

                    <Col lg="6" md="6" sm="6" className='position-relative'>
                      <h6 className="form-lable required-field mt-1">Payment Service</h6>
                      <Select menuPlacement="auto" options={options_payment_service}
                        name="enrol_pay_serice"
                        value={values.enrol_pay_serice['value']}
                        id="enrol_pay_serice"
                        onChange={(selectedOption) => {
                          const event = {
                            target: {
                              name: "enrol_pay_serice",
                              value: selectedOption["value"],
                            },
                          };
                          handleChange(event);
                        }}
                        onBlur={(event) => {
                          handleBlur(event);
                          setTouched({ ...touched, enrol_pay_serice: true });
                        }}
                        className={errors.enrol_pay_serice && touched.enrol_pay_serice ? "input-error" : "mb-0"} />
                      {errors.enrol_pay_serice && touched.enrol_pay_serice && <span className="error">{errors.enrol_pay_serice}</span>}
                    </Col>

                    <Col lg="6" md="6" sm="6" className='position-relative' style={{ display: 'none' }}>
                      <h6 className="form-lable ">Registration fees ({sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD'})</h6>
                      <Form.Control
                        type="number"

                        value={values.enrol_reg_fees}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="enrol_reg_fees"
                        min={0}
                        className={errors.enrol_reg_fees && touched.enrol_reg_fees ? "input-error" : "mb-0"}
                      />
                      {errors.enrol_reg_fees && touched.enrol_reg_fees && <p className="error">{errors.enrol_reg_fees}</p>}
                    </Col>

                    <Col lg="6" md="6" sm="6" className='position-relative' style={{ display: 'none' }}>
                      <h6 className="form-lable ">Enrolment Deposit ({sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD'})</h6>
                      <Form.Control
                        type="number"

                        value={values.enrol_deposit}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="enrol_deposit"
                        min={0}
                        className={errors.enrol_deposit && touched.enrol_deposit ? "input-error" : "mb-0"}
                      />
                      {errors.enrol_deposit && touched.enrol_deposit && <p className="error">{errors.enrol_deposit}</p>}
                    </Col>

                    <Col lg="6" md="6" sm="6" className='position-relative' style={{ display: 'none' }}>
                      <h6 className="form-lable required-field">Fees Type</h6>
                      <Select menuPlacement="auto" options={options_payment_type}
                        name="enrol_price_type"
                        value={values.enrol_price_type['value']}
                        id="enrol_price_type"
                        onChange={(selectedOption) => {
                          const event = {
                            target: {
                              name: "enrol_price_type",
                              value: selectedOption["value"],
                            },
                          };
                          handleChange(event);
                        }}
                        onBlur={(event) => {
                          handleBlur(event);
                          setTouched({ ...touched, enrol_price_type: true });
                        }}
                        className={errors.enrol_price_type && touched.enrol_price_type ? "input-error" : "mb-0"} />
                      {errors.enrol_price_type && touched.enrol_price_type && <p className="error">{errors.enrol_price_type}</p>}
                    </Col>
                  </>
                )}
              </Row>
            </Modal.Body>

            <Modal.Footer className='pt-0'>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={() => setModalShowAdd(false)}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmitting}>Save</Button>
              </div>

            </Modal.Footer>
          </Form>

        </Modal>

        <Modal
          show={modalShowNotify} onHide={() => setModalShowNotyfy(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5 className='page-title'> Add Enrolment </h5>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>

              {/* --------------Date--------------- */}
              <Row>


                <div className="ten columns col-12">
                  <ul className="">
                    <li> Mon 04-sep-23 &rArr;	 Lesson Added For Student</li>
                    <li> Mon 11-sep-23 &rArr;	 Lesson Added For Student </li>
                    <li> Mon 18-sep-23 &rArr;	 Lesson Added For Student</li>
                    <li> Mon 25-sep-23 &rArr;	 Lesson Added For Student</li>
                  </ul>


                </div>
              </Row>
            </Modal.Body>

            <Modal.Footer>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={() => setModalShowNotyfy(false)}>Cancel</Button>
                <Button variant="contained" className="primary-filled " onClick={handleClick_student_view}>View Student</Button>
              </div>

            </Modal.Footer>
          </Form>
        </Modal>


        <Modal show={showModal1} onHide={toggleModal1} size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered >
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className='page-title'> Unenrolment </h5>
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={formik1.handleSubmit}>
            <Modal.Body>

              {/* --------------Date--------------- */}
              <Row>


                {/* --------------Subject--------------- */}

                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Session {['top'].map((placement) => (
                    <OverlayTrigger
                      key={placement}
                      placement={placement}
                      overlay={
                        <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                          The session option only displays the start date of sessions in which students are enrolled.
                        </Tooltip>
                      }
                    >
                      <Button variant="secondary" className="no-bdr">
                        <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                      </Button>
                    </OverlayTrigger>

                  ))}</h6>
                  <Select menuPlacement="auto" options={options_unenrol_session}
                    name="pk_enrol_key_e"
                    value={formik1.values.pk_enrol_key_e['value']}
                    id="pk_enrol_key_e"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "pk_enrol_key_e",
                          value: selectedOption["value"],
                        },
                      };
                      formik1.handleChange(event);
                    }}
                    onBlur={(event) => {
                      formik1.handleBlur(event);
                      formik1.setTouched({ ...formik1.touched, pk_enrol_key_e: true });
                    }}
                    className={formik1.errors.pk_enrol_key_e && formik1.touched.pk_enrol_key_e ? "input-error" : ""} />
                  {formik1.errors.pk_enrol_key_e && formik1.touched.pk_enrol_key_e && <span className="error">{formik1.errors.pk_enrol_key_e}</span>}
                </Col>

                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Unenrolment Date</h6>
                  {/* <Form.Control
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    max={new Date().toISOString().split("T")[0]}
                    value={formik1.values.enrol_disc_date}
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    id="enrol_disc_date"
                    className={formik1.errors.enrol_disc_date && formik1.touched.enrol_disc_date ? "input-error" : ""}
                  /> */}
                  <LocalizationProvider className={'form-control'} dateAdapter={AdapterDayjs}>
                    <DatePicker views={['year', 'month', 'day']}
                      value={dayjs(formik1.values.enrol_disc_date, 'YYYY-MM-DD')}
                      onChange={(date) => {
                        const event = {
                          target: {
                            name: "enrol_disc_date",
                            value: date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date,
                          },
                        };
                        formik1.handleChange(event);
                      }}
                      onBlur={(event) => {
                        formik1.handleBlur(event);
                        formik1.setTouched({ ...formik1.touched, enrol_disc_date: true });
                      }}
                      name="enrol_disc_date"
                      slotProps={{
                        textField: {
                          readOnly: true, size: 'small',
                          id: 'enrol_disc_date',
                        },
                      }}
                      className={formik1.errors.enrol_disc_date && formik1.touched.enrol_disc_date ? "input-error w-100" : "w-100"}
                      minDate={dayjs()}
                      //maxDate={dayjs()}
                      format="DD-MM-YYYY"
                    />
                  </LocalizationProvider>
                  {formik1.errors.enrol_disc_date && formik1.touched.enrol_disc_date && <span className="error">{formik1.errors.enrol_disc_date}</span>}
                </Col>

                <Col lg="12" md="12" sm="12" className='position-relative'>
                  <h6 className="form-lable required-field">Reason</h6>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    id="enrol_disc_reason"

                    value={formik1.values.enrol_disc_reason}
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    className={formik1.errors.enrol_disc_reason && formik1.touched.enrol_disc_reason ? "input-error" : "mb-0"}
                  />
                  {formik1.errors.enrol_disc_reason && formik1.touched.enrol_disc_reason && <p className="error">{formik1.errors.enrol_disc_reason}</p>}
                </Col>
              </Row>


            </Modal.Body>
            <Modal.Footer className='pt-0'>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={toggleModal1}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingUnenrol}>Submit</Button>

              </div>

            </Modal.Footer>
          </Form>
        </Modal>

        <Modal show={showModalDeposit} onHide={toggleModalDeposit} size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered >
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className='page-title'> Deposit </h5>
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={formikDeposit.handleSubmit}>
            <Modal.Body>

              {/* --------------Date--------------- */}
              <Row>


                {/* --------------Subject--------------- */}
                <Col lg="6" md="6" sm="12" className='position-relative'>
                  <h6 className="form-lable required-field">Payment Method {['top'].map((placement) => (
                    <OverlayTrigger
                      key={placement}
                      placement={placement}
                      overlay={
                        <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                          Note: Change the product dropdown options based on the product type and payment method
                        </Tooltip>
                      }
                    >
                      <Button variant="secondary" className="no-bdr">
                        <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                      </Button>
                    </OverlayTrigger>

                  ))}</h6>
                  <Select menuPlacement="auto" options={options_pay_method}
                    name="pp_pay_method"
                    value={options_pay_method.find(option => option.value && formikDeposit.values.pp_pay_method && option.value.toString() === formikDeposit.values.pp_pay_method.toString())}
                    id="pp_pay_method"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "pp_pay_method",
                          value: selectedOption["value"],
                        },
                      };
                      const event2 = {
                        target: {
                          name: "fk_pp_key",
                          value: '',
                        },
                      };
                      formikDeposit.handleChange(event2);
                      fetchMasterProductData(selectedOption["value"], formikDeposit.values.fk_pt_key);
                      formikDeposit.handleChange(event);
                    }}
                    onBlur={(event) => {
                      formikDeposit.handleBlur(event);
                      formikDeposit.setTouched({ ...formikDeposit.touched, pp_pay_method: true });
                    }}
                    className={formikDeposit.errors.pp_pay_method && formikDeposit.touched.pp_pay_method ? "input-error" : "mb-3"}
                  />
                  {formikDeposit.errors.pp_pay_method && formikDeposit.touched.pp_pay_method && <span className="error">{formikDeposit.errors.pp_pay_method}</span>}

                </Col>

                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Product Type {['top'].map((placement) => (
                    <OverlayTrigger
                      key={placement}
                      placement={placement}
                      overlay={
                        <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                          Product type select show the product option show in Product field.
                        </Tooltip>
                      }
                    >
                      <Button variant="secondary" className="no-bdr">
                        <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                      </Button>
                    </OverlayTrigger>

                  ))}</h6>
                  <Select menuPlacement="auto" options={options_deposit_product_type}
                    name="fk_pt_key"
                    value={formikDeposit.values.fk_pt_key['value']}
                    id="fk_pt_key"
                    onChange={(selectedOption) => {

                      const event = {
                        target: {
                          name: "fk_pt_key",
                          value: selectedOption["value"],
                        },
                      };
                      const event2 = {
                        target: {
                          name: "fk_pp_key",
                          value: '',
                        },
                      };
                      formikDeposit.handleChange(event2);
                      fetchMasterProductData(formikDeposit.values.pp_pay_method, selectedOption["value"]);
                      formikDeposit.handleChange(event);
                    }}
                    onBlur={(event) => {
                      formikDeposit.handleBlur(event);
                      formikDeposit.setTouched({ ...formikDeposit.touched, fk_pt_key: true });
                    }}
                    className={formikDeposit.errors.fk_pt_key && formikDeposit.touched.fk_pt_key ? "input-error" : ""} />
                  {formikDeposit.errors.fk_pt_key && formikDeposit.touched.fk_pt_key && <span className="error">{formikDeposit.errors.fk_pt_key}</span>}
                </Col>

                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Product {['top'].map((placement) => (
                    <OverlayTrigger
                      key={placement}
                      placement={placement}
                      overlay={
                        <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                          Please select a product. Once the product is selected and you are enrolled in the subscription, it will no longer be editable.
                        </Tooltip>
                      }
                    >
                      <Button variant="secondary" className="no-bdr">
                        <i class="fa-solid fa-info-circle ml-2 mr-2"></i>
                      </Button>
                    </OverlayTrigger>

                  ))}</h6>
                  <Select menuPlacement="auto" options={options_pricing}
                    name="fk_pp_key"
                    value={options_pricing.find(option => option.value.toString() === formikDeposit.values.fk_pp_key.toString())}
                    id="fk_pp_key"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "fk_pp_key",
                          value: selectedOption["value"],
                        },
                      };
                      formikDeposit.handleChange(event);
                    }}
                    onBlur={(event) => {
                      formikDeposit.handleBlur(event);
                      formikDeposit.setTouched({ ...formikDeposit.touched, fk_pp_key: true });
                    }}
                    className={formikDeposit.errors.fk_pp_key && formikDeposit.touched.fk_pp_key ? "input-error" : "mb-3"} />
                  {formikDeposit.errors.fk_pp_key && formikDeposit.touched.fk_pp_key && <span className="error">{formikDeposit.errors.fk_pp_key}</span>}
                </Col>

                {/* <Col lg="12" md="12" sm="12" className='position-relative'>
                  <h6 className="form-lable required-field">description</h6>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    id="enrol_disc_reason"

                    value={formik1.values.enrol_disc_reason}
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    className={formik1.errors.enrol_disc_reason && formik1.touched.enrol_disc_reason ? "input-error" : "mb-0"}
                  />
                  {formik1.errors.enrol_disc_reason && formik1.touched.enrol_disc_reason && <p className="error">{formik1.errors.enrol_disc_reason}</p>}
                </Col> */}
              </Row>


            </Modal.Body>
            <Modal.Footer className='pt-0'>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={toggleModalDeposit}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingDeposit}>Submit</Button>

              </div>

            </Modal.Footer>
          </Form>
        </Modal>


        <Modal show={showModalPaid} onHide={toggleModalPaid} size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered >
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className='page-title'> Payment </h5>
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={formikPaid.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <Card className='rounded-1 mt-2 card-bdr h-100' >
                    <Card.Body>

                      <div className="me-0">
                        <div className="">

                          <div className="cus-pyt-dtls">
                            <h6 className='mb-0'>Student Name:</h6>
                            <p className="mb-0">
                              {StudentData ? StudentData.st_first_name != null && StudentData.st_first_name != '' ? StudentData.st_first_name + ' ' + StudentData.st_surname + '  ' : '' : ''}
                            </p>
                          </div>

                          <div className="cus-pyt-dtls">
                            <h6 className='mb-0'>Payment Frequency:</h6>
                            <p className="mb-0">
                              {PaidData && PaidData.fm_name ? PaidData.fm_name : ''}
                            </p>
                          </div>

                          {/* <div className="cus-pyt-dtls">
                            <h6>From Date:</h6>
                              <p className="mb-0">
                                {PaidData && PaidData.spst_from_date ? Moment(PaidData.spst_from_date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-'}
                              </p>
                          </div>

                          <div className="cus-pyt-dtls">
                              <h6>End Date:</h6>
                              <p className="mb-0">
                                {PaidData && PaidData.spst_to_date ? Moment(PaidData.spst_to_date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-'}
                              </p>
                          </div> */}
                          <div className="cus-pyt-dtls">
                            <h6>Product Type :</h6>
                            <p className="mb-0">
                              {PaidData && PaidData.pt_name ? PaidData.pt_name : '-'}
                            </p>
                          </div>
                          <div className="cus-pyt-dtls">
                            <h6>Product Name :</h6>
                            <p className="mb-0">
                              {PaidData && PaidData.pp_name ? PaidData.pp_name : '-'}
                            </p>
                          </div>
                          {/* <div className="cus-pyt-dtls">
                              <h6>Amount:</h6>
                              <p className="mb-0">
                                {PaidData && PaidData.pp_total_amount ? PaidData.pp_total_amount : 0} - {sessionStorage.getItem('currency_code')}
                              </p>
                          </div> */}

                          <div className="cus-pyt-dtls">
                            <h6>Total Amount:</h6>
                            <p className="mb-0">
                              {PaidData && PaidData.pp_total_amount ? PaidData.pp_total_amount : 0} - {sessionStorage.getItem('currency_code')}
                            </p>
                          </div>

                          {/* <div className="cus-pyt-dtls mb-0">
                              <h6 className="mb-0">Status:</h6>
                              <p className="mb-0 text-success"> <strong>Paid</strong>
                              
                              </p>
                          </div> */}



                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <Card className='rounded-1 mt-2 card-bdr h-100' >
                    <Card.Body>

                      <div className="me-0">
                        <div className="">

                          <div className="cus-pyt-dtls mb-2">
                            <h6 className='mb-0'>Payment Type:</h6>
                            <p className="mb-0">
                              {PaidData && PaidData.pp_pay_method ? parseInt(PaidData.pp_pay_method) === 1 ? 'Online - Stripe' : 'Offline' : '-'}</p>
                          </div>

                          <div className="mt-3">
                            <Row>
                              {/* {showOtherField ? <Col lg="12" md="12" sm="12" className='position-relative mb-2'>
                                <h6 className="form-lable required-field">Other Payment Option {['top'].map((placement) => (
                                      <OverlayTrigger
                                      key={placement}
                                      placement={placement}
                                      overlay={
                                          <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                                          The super admin can add, edit, and delete other payment modes in the 'Other Payment Option' form.
                                          </Tooltip>
                                      }
                                      >
                                          <Button variant="secondary" className="no-bdr">
                                              <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                                          </Button>
                                      </OverlayTrigger>
                                      
                                  ))}</h6>
                                <Select menuPlacement="auto" options={OfflineOtherPaymentData}
                                  name="fk_cpmo_key_e"
                                  value={formikPaid.values.fk_cpmo_key_e['value']}
                                  id="fk_cpmo_key_e"
                                  onChange={(selectedOption) => {
                                    const event = {
                                      target: {
                                        name: "fk_cpmo_key_e",
                                        value: selectedOption["value"],
                                      },
                                    };
                                    formikPaid.handleChange(event);
                                  }}
                                  onBlur={(event) => {
                                    formikPaid.handleBlur(event);
                                    formikPaid.setTouched({ ...formikPaid.touched, fk_cpmo_key_e: true });
                                  }}
                                  className={formikPaid.errors.fk_cpmo_key_e && formikPaid.touched.fk_cpmo_key_e ? "input-error" : ""} />
                                {formikPaid.errors.fk_cpmo_key_e && formikPaid.touched.fk_cpmo_key_e && <span className="error">{formikPaid.errors.fk_cpmo_key_e}</span>}
                              </Col> : null} */}

                              {PaidData && PaidData.pp_pay_method && parseInt(PaidData.pp_pay_method) === 2 ?
                                <><Col lg="12" md="12" sm="12" className='position-relative mb-2'>
                                  <h6 className="form-lable required-field">Payment Mode {['top'].map((placement) => (
                                    <OverlayTrigger
                                      key={placement}
                                      placement={placement}
                                      overlay={
                                        <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                                          The super admin can add, edit, and delete payment mode form.
                                        </Tooltip>
                                      }
                                    >
                                      <Button variant="secondary" className="no-bdr">
                                        <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                                      </Button>
                                    </OverlayTrigger>

                                  ))}</h6>
                                  <Select menuPlacement="auto" options={OfflineModesOptions}
                                    name="pk_cpm_key_e"
                                    value={formikPaid.values.pk_cpm_key_e['value']}
                                    id="pk_cpm_key_e"
                                    onChange={(selectedOption) => {
                                      const event = {
                                        target: {
                                          name: "pk_cpm_key_e",
                                          value: selectedOption["value"],
                                        },
                                      };
                                      formikPaid.handleChange(event);
                                    }}
                                    onBlur={(event) => {
                                      formikPaid.handleBlur(event);
                                      formikPaid.setTouched({ ...formikPaid.touched, pk_cpm_key_e: true });
                                    }}
                                    className={formikPaid.errors.pk_cpm_key_e && formikPaid.touched.pk_cpm_key_e ? "input-error" : ""} />
                                  {formikPaid.errors.pk_cpm_key_e && formikPaid.touched.pk_cpm_key_e && <span className="error">{formikPaid.errors.pk_cpm_key_e}</span>}
                                </Col>
                                  <Col lg="12" md="12" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Transaction ID</h6>
                                    <Form.Control
                                      type="text"
                                      id="spst_transaction_id"
                                      value={formikPaid.values.spst_transaction_id}
                                      onChange={formikPaid.handleChange}
                                      onBlur={formikPaid.handleBlur}
                                      className={formikPaid.errors.spst_transaction_id && formikPaid.touched.spst_transaction_id ? "input-error" : "mb-0"}
                                    />
                                    {formikPaid.errors.spst_transaction_id && formikPaid.touched.spst_transaction_id && <p className="error">{formikPaid.errors.spst_transaction_id}</p>}
                                  </Col></>
                                : null}
                            </Row>
                          </div>



                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

            </Modal.Body>
            <Modal.Footer className='pt-0'>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={toggleModalPaid}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingPaid}>Submit</Button>

              </div>

            </Modal.Footer>
          </Form>
        </Modal>

        <Modal show={showModalOfflinePaid} onHide={toggleModalOfflinePaid} size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered >
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className='page-title'> Payment </h5>
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={formikOfflinePaid.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <Card className='rounded-1 mt-2 card-bdr h-100' >
                    <Card.Body>

                      <div className="me-0">
                        <div className="">

                          <div className="cus-pyt-dtls">
                            <h6 className='mb-0'>Student Name:</h6>
                            <p className="mb-0">
                              {StudentData ? StudentData.st_first_name != null && StudentData.st_first_name != '' ? StudentData.st_first_name + ' ' + StudentData.st_surname + '  ' : '' : ''}
                            </p>
                          </div>

                          <div className="cus-pyt-dtls">
                            <h6 className='mb-0'>Payment Frequency:</h6>
                            <p className="mb-0">
                              {OfflinePaidData && OfflinePaidData.ps_frq_name ? OfflinePaidData.ps_frq_name : ''}
                            </p>
                          </div>

                          <div className="cus-pyt-dtls">
                            <h6>From Date:</h6>
                            <p className="mb-0">
                              {OfflinePaidData && OfflinePaidData.ps_from_date ? Moment(OfflinePaidData.ps_from_date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-'}
                            </p>
                          </div>

                          <div className="cus-pyt-dtls">
                            <h6>To Date:</h6>
                            <p className="mb-0">
                              {OfflinePaidData && OfflinePaidData.ps_end_date ? Moment(OfflinePaidData.ps_end_date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-'}
                            </p>
                          </div>
                          <div className="cus-pyt-dtls">
                            <h6>Session Name :</h6>
                            <p className="mb-0">
                              {OfflinePaidData && OfflinePaidData.sest_name ? OfflinePaidData.sest_name : '-'}
                            </p>
                          </div>
                          <div className="cus-pyt-dtls">
                            <h6>Product Name :</h6>
                            <p className="mb-0">
                              {OfflinePaidData && OfflinePaidData.pp_name ? OfflinePaidData.pp_name : '-'}
                            </p>
                          </div>
                          {/* <div className="cus-pyt-dtls">
                              <h6>Amount:</h6>
                              <p className="mb-0">
                                {PaidData && PaidData.pp_total_amount ? PaidData.pp_total_amount : 0} - {sessionStorage.getItem('currency_code')}
                              </p>
                          </div> */}

                          <div className="cus-pyt-dtls">
                            <h6>Total Amount:</h6>
                            <p className="mb-0">
                              {OfflinePaidData && OfflinePaidData.ps_amount ? OfflinePaidData.ps_amount : 0} - {sessionStorage.getItem('currency_code')}
                            </p>
                          </div>

                          {/* <div className="cus-pyt-dtls mb-0">
                              <h6 className="mb-0">Status:</h6>
                              <p className="mb-0 text-success"> <strong>Paid</strong>
                              
                              </p>
                          </div> */}



                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <Card className='rounded-1 mt-2 card-bdr h-100' >
                    <Card.Body>

                      <div className="me-0">
                        <div className="">

                          <div className="cus-pyt-dtls mb-2">
                            <h6 className='mb-0'>Payment Type:</h6>
                            <p className="mb-0">
                              {OfflinePaidData && OfflinePaidData.pp_pay_method ? parseInt(OfflinePaidData.pp_pay_method) === 1 ? 'Online - Stripe' : 'Offline' : '-'}</p>
                          </div>

                          <div className="mt-3">
                            <Row>
                              {/* {showOtherField ? <Col lg="12" md="12" sm="12" className='position-relative mb-2'>
                                <h6 className="form-lable required-field">Other Payment Option {['top'].map((placement) => (
                                      <OverlayTrigger
                                      key={placement}
                                      placement={placement}
                                      overlay={
                                          <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                                          The super admin can add, edit, and delete other payment modes in the 'Other Payment Option' form.
                                          </Tooltip>
                                      }
                                      >
                                          <Button variant="secondary" className="no-bdr">
                                              <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                                          </Button>
                                      </OverlayTrigger>
                                      
                                  ))}</h6>
                                <Select menuPlacement="auto" options={OfflineOtherPaymentData}
                                  name="fk_cpmo_key_e"
                                  value={formikPaid.values.fk_cpmo_key_e['value']}
                                  id="fk_cpmo_key_e"
                                  onChange={(selectedOption) => {
                                    const event = {
                                      target: {
                                        name: "fk_cpmo_key_e",
                                        value: selectedOption["value"],
                                      },
                                    };
                                    formikPaid.handleChange(event);
                                  }}
                                  onBlur={(event) => {
                                    formikPaid.handleBlur(event);
                                    formikPaid.setTouched({ ...formikPaid.touched, fk_cpmo_key_e: true });
                                  }}
                                  className={formikPaid.errors.fk_cpmo_key_e && formikPaid.touched.fk_cpmo_key_e ? "input-error" : ""} />
                                {formikPaid.errors.fk_cpmo_key_e && formikPaid.touched.fk_cpmo_key_e && <span className="error">{formikPaid.errors.fk_cpmo_key_e}</span>}
                              </Col> : null} */}

                              <><Col lg="12" md="12" sm="12" className='position-relative mb-2'>
                                <h6 className="form-lable required-field">Payment Mode {['top'].map((placement) => (
                                  <OverlayTrigger
                                    key={placement}
                                    placement={placement}
                                    overlay={
                                      <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                                        The super admin can add, edit, and delete payment mode form.
                                      </Tooltip>
                                    }
                                  >
                                    <Button variant="secondary" className="no-bdr">
                                      <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                                    </Button>
                                  </OverlayTrigger>

                                ))}</h6>
                                <Select menuPlacement="auto" options={OfflineModesOptions}
                                  name="pk_cpm_key_e"
                                  value={formikOfflinePaid.values.pk_cpm_key_e['value']}
                                  id="pk_cpm_key_e"
                                  onChange={(selectedOption) => {
                                    const event = {
                                      target: {
                                        name: "pk_cpm_key_e",
                                        value: selectedOption["value"],
                                      },
                                    };
                                    formikOfflinePaid.handleChange(event);
                                  }}
                                  onBlur={(event) => {
                                    formikOfflinePaid.handleBlur(event);
                                    formikOfflinePaid.setTouched({ ...formikOfflinePaid.touched, pk_cpm_key_e: true });
                                  }}
                                  className={formikOfflinePaid.errors.pk_cpm_key_e && formikOfflinePaid.touched.pk_cpm_key_e ? "input-error" : ""} />
                                {formikOfflinePaid.errors.pk_cpm_key_e && formikOfflinePaid.touched.pk_cpm_key_e && <span className="error">{formikOfflinePaid.errors.pk_cpm_key_e}</span>}
                              </Col>
                                <Col lg="12" md="12" sm="12" className='position-relative'>
                                  <h6 className="form-lable required-field">Transaction ID</h6>
                                  <Form.Control
                                    type="text"
                                    id="eps_transaction_id"
                                    value={formikOfflinePaid.values.eps_transaction_id}
                                    onChange={formikOfflinePaid.handleChange}
                                    onBlur={formikOfflinePaid.handleBlur}
                                    className={formikOfflinePaid.errors.eps_transaction_id && formikOfflinePaid.touched.eps_transaction_id ? "input-error" : "mb-0"}
                                  />
                                  {formikOfflinePaid.errors.eps_transaction_id && formikOfflinePaid.touched.eps_transaction_id && <p className="error">{formikOfflinePaid.errors.eps_transaction_id}</p>}
                                </Col></>

                            </Row>
                          </div>



                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

            </Modal.Body>
            <Modal.Footer className='pt-0'>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={toggleModalOfflinePaid}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingOfflinePaid}>Submit</Button>

              </div>

            </Modal.Footer>
          </Form>
        </Modal>

        <Modal show={showModalOfflineRefund} onHide={toggleModalOfflineRefund} size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered >
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className='page-title'> Refund </h5>
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={formikOfflineRefund.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <Card className='rounded-1 mt-2 card-bdr h-100' >
                    <Card.Body>

                      <div className="me-0">
                        <div className="">

                          <div className="cus-pyt-dtls">
                            <h6 className='mb-0'>Student Name:</h6>
                            <p className="mb-0">
                              {StudentData ? StudentData.st_first_name != null && StudentData.st_first_name != '' ? StudentData.st_first_name + ' ' + StudentData.st_surname + '  ' : '' : ''}
                            </p>
                          </div>

                          <div className="cus-pyt-dtls">
                            <h6 className='mb-0'>Payment Frequency:</h6>
                            <p className="mb-0">
                              {OfflineRefundData && OfflineRefundData.ps_frq_name ? OfflineRefundData.ps_frq_name : ''}
                            </p>
                          </div>

                          <div className="cus-pyt-dtls">
                            <h6>Date:</h6>
                            <p className="mb-0">
                              {OfflineRefundData && OfflineRefundData.ps_date ? Moment(OfflineRefundData.ps_date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-'}
                            </p>
                          </div>

                          {/* <div className="cus-pyt-dtls">
                              <h6>To Date:</h6>
                              <p className="mb-0">
                                {OfflineRefundData && OfflineRefundData.ps_end_date ? Moment(OfflineRefundData.ps_end_date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-'}
                              </p>
                          </div> */}
                          <div className="cus-pyt-dtls">
                            <h6>Session Name :</h6>
                            <p className="mb-0">
                              {OfflineRefundData && OfflineRefundData.sest_name ? OfflineRefundData.sest_name : '-'}
                            </p>
                          </div>
                          <div className="cus-pyt-dtls">
                            <h6>Product Name :</h6>
                            <p className="mb-0">
                              {OfflineRefundData && OfflineRefundData.pp_name ? OfflineRefundData.pp_name : '-'}
                            </p>
                          </div>
                          {/* <div className="cus-pyt-dtls">
                              <h6>Amount:</h6>
                              <p className="mb-0">
                                {PaidData && PaidData.pp_total_amount ? PaidData.pp_total_amount : 0} - {sessionStorage.getItem('currency_code')}
                              </p>
                          </div> */}

                          <div className="cus-pyt-dtls">
                            <h6>Total Amount:</h6>
                            <p className="mb-0">
                              {OfflineRefundData && OfflineRefundData.rp_amount ? OfflineRefundData.rp_amount : 0} - {sessionStorage.getItem('currency_code')}
                            </p>
                          </div>

                          {/* <div className="cus-pyt-dtls mb-0">
                              <h6 className="mb-0">Status:</h6>
                              <p className="mb-0 text-success"> <strong>Paid</strong>
                              
                              </p>
                          </div> */}



                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <Card className='rounded-1 mt-2 card-bdr h-100' >
                    <Card.Body>

                      <div className="me-0">
                        <div className="">

                          <div className="cus-pyt-dtls mb-2">
                            <h6 className='mb-0'>Payment Type:</h6>
                            <p className="mb-0">
                              {OfflineRefundData && OfflineRefundData.pp_pay_method ? parseInt(OfflineRefundData.pp_pay_method) === 1 ? 'Online - Stripe' : 'Offline' : '-'}</p>
                          </div>

                          <div className="mt-3">
                            <Row>
                              {/* {showOtherField ? <Col lg="12" md="12" sm="12" className='position-relative mb-2'>
                                <h6 className="form-lable required-field">Other Payment Option {['top'].map((placement) => (
                                      <OverlayTrigger
                                      key={placement}
                                      placement={placement}
                                      overlay={
                                          <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                                          The super admin can add, edit, and delete other payment modes in the 'Other Payment Option' form.
                                          </Tooltip>
                                      }
                                      >
                                          <Button variant="secondary" className="no-bdr">
                                              <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                                          </Button>
                                      </OverlayTrigger>
                                      
                                  ))}</h6>
                                <Select menuPlacement="auto" options={OfflineOtherPaymentData}
                                  name="fk_cpmo_key_e"
                                  value={formikPaid.values.fk_cpmo_key_e['value']}
                                  id="fk_cpmo_key_e"
                                  onChange={(selectedOption) => {
                                    const event = {
                                      target: {
                                        name: "fk_cpmo_key_e",
                                        value: selectedOption["value"],
                                      },
                                    };
                                    formikPaid.handleChange(event);
                                  }}
                                  onBlur={(event) => {
                                    formikPaid.handleBlur(event);
                                    formikPaid.setTouched({ ...formikPaid.touched, fk_cpmo_key_e: true });
                                  }}
                                  className={formikPaid.errors.fk_cpmo_key_e && formikPaid.touched.fk_cpmo_key_e ? "input-error" : ""} />
                                {formikPaid.errors.fk_cpmo_key_e && formikPaid.touched.fk_cpmo_key_e && <span className="error">{formikPaid.errors.fk_cpmo_key_e}</span>}
                              </Col> : null} */}
                              {OfflineRefundData && OfflineRefundData.pp_pay_method == 2
                                ?
                                <><Col lg="12" md="12" sm="12" className='position-relative mb-2'>
                                  <h6 className="form-lable required-field">Payment Mode {['top'].map((placement) => (
                                    <OverlayTrigger
                                      key={placement}
                                      placement={placement}
                                      overlay={
                                        <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                                          The super admin can add, edit, and delete payment mode form.
                                        </Tooltip>
                                      }
                                    >
                                      <Button variant="secondary" className="no-bdr">
                                        <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                                      </Button>
                                    </OverlayTrigger>

                                  ))}</h6>
                                  <Select menuPlacement="auto" options={OfflineModesOptions}
                                    name="pk_cpm_key_e"
                                    value={formikOfflineRefund.values.pk_cpm_key_e['value']}
                                    id="pk_cpm_key_e"
                                    onChange={(selectedOption) => {
                                      const event = {
                                        target: {
                                          name: "pk_cpm_key_e",
                                          value: selectedOption["value"],
                                        },
                                      };
                                      formikOfflineRefund.handleChange(event);
                                    }}
                                    onBlur={(event) => {
                                      formikOfflineRefund.handleBlur(event);
                                      formikOfflineRefund.setTouched({ ...formikOfflineRefund.touched, pk_cpm_key_e: true });
                                    }}
                                    className={formikOfflineRefund.errors.pk_cpm_key_e && formikOfflineRefund.touched.pk_cpm_key_e ? "input-error" : ""} />
                                  {formikOfflineRefund.errors.pk_cpm_key_e && formikOfflineRefund.touched.pk_cpm_key_e && <span className="error">{formikOfflineRefund.errors.pk_cpm_key_e}</span>}
                                </Col>
                                  <Col lg="12" md="12" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Transaction ID</h6>
                                    <Form.Control
                                      type="text"
                                      id="eps_transaction_id"
                                      value={formikOfflineRefund.values.eps_transaction_id}
                                      onChange={formikOfflineRefund.handleChange}
                                      onBlur={formikOfflineRefund.handleBlur}
                                      className={formikOfflineRefund.errors.eps_transaction_id && formikOfflineRefund.touched.eps_transaction_id ? "input-error" : "mb-0"}
                                    />
                                    {formikOfflineRefund.errors.eps_transaction_id && formikOfflineRefund.touched.eps_transaction_id && <p className="error">{formikOfflineRefund.errors.eps_transaction_id}</p>}
                                  </Col></>
                                : null}

                            </Row>
                          </div>



                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

            </Modal.Body>
            <Modal.Footer className='pt-0'>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={toggleModalOfflineRefund}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingOfflineRefund}>Submit</Button>

              </div>

            </Modal.Footer>
          </Form>
        </Modal>

        <Modal show={showForfeit} variant="danger">

          <Modal.Header>Are you sure?</Modal.Header>

          <Modal.Body>
            <p>You want to <b className="bg-warning">{(data_forfeit.type == 1 ? 'forfeit ' : data_forfeit.type == 2 ? 'writeoff' : '')}</b>  this enrolment? </p>

            <Card className='rounded-1 mt-2 card-bdr' >
              <Card.Body>

                <div>
                  <div className="d-flex justify-content-between align-self-center">
                    <div className="me-5">
                      <div className="">

                        <p className="">{data_forfeit.sest_name}<span className=''> ({data_forfeit.tut_fname + ' ' + data_forfeit.tut_surname})</span></p>
                        <h6>Session Date:</h6>
                        <p className="mb-0">
                          <i class="fa-solid fa-calendar me-2"></i>
                          {Moment(data_forfeit.ses_date).format('DD-MM-YYYY')} ({data_forfeit.sest_day})
                        </p>
                        <h6>Session Time:</h6>
                        <p className="mb-0">
                          <i class="fa-solid fa-clock me-2"></i>

                          {data_forfeit.ses_start_time}
                        </p>
                      </div>
                    </div>
                  </div>
                  <span>

                  </span>
                </div>

              </Card.Body>
            </Card>
            <hr />
          </Modal.Body>
          <Modal.Footer>

            <div className="mx-auto d-flex gap-2" >

              <Button variant="contained" className="primary-gray" onClick={() => setForfeitShow(false)}>Cancel</Button>
              <Button variant="contained" type='submit' className="primary-filled " onClick={() => onSubmitForfeit(data_forfeit.type)} disabled={isSubmittingForfeit} >Yes</Button>
            </div>

          </Modal.Footer>

        </Modal>

        <Modal show={showModalPrice} onHide={toggleModalPrice} size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered >
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className='page-title'>Edit Price </h5>
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={formik3.handleSubmit}>
            <Modal.Body>

              {/* --------------Date--------------- */}
              <Row>

                {/* --------------Subject--------------- */}


                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Session Name</h6>
                  <Form.Control
                    type="text"
                    readOnly
                    value={formik3.values.enrol_sest_name}
                    onChange={formik3.handleChange}
                    onBlur={formik3.handleBlur}
                    id="enrol_sest_name"
                    className={formik3.errors.enrol_sest_name && formik3.touched.enrol_sest_name ? "input-error" : ""}
                  />
                  {formik3.errors.enrol_sest_name && formik3.touched.enrol_sest_name && <p className="error">{formik3.errors.enrol_sest_name}</p>}
                </Col>

                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Fees</h6>
                  <Select menuPlacement="auto" options={options_pricing}
                    name="enrol_price"

                    value={options_pricing.find(option => option.value && formik3.values.enrol_price && option.value.toString() === formik3.values.enrol_price.toString())}
                    // value={formik3.values.enrol_price['value']}
                    id="enrol_price"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "enrol_price",
                          value: selectedOption["value"],
                        },
                      };
                      formik3.handleChange(event);
                    }}
                    onBlur={(event) => {
                      formik3.handleBlur(event);
                      formik3.setTouched({ ...formik3.touched, enrol_price: true });
                    }}
                    className={formik3.errors.enrol_price && formik3.touched.enrol_price ? "input-error" : "mb-3"} />
                  {formik3.errors.enrol_price && formik3.touched.enrol_price && <p className="error">{formik3.errors.enrol_price}</p>}
                </Col>

                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable ">Registration fees ({sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD'})</h6>
                  <Form.Control
                    type="number"

                    value={formik3.values.enrol_reg_fees}
                    onChange={formik3.handleChange}
                    onBlur={formik3.handleBlur}
                    id="enrol_reg_fees"
                    min={0}
                    className={formik3.errors.enrol_reg_fees && formik3.touched.enrol_reg_fees ? "input-error" : "mb-0"}
                  />
                  {formik3.errors.enrol_reg_fees && formik3.touched.enrol_reg_fees && <p className="error">{formik3.errors.enrol_reg_fees}</p>}
                </Col>

                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable ">Enrolment Deposit ({sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD'})</h6>
                  <Form.Control
                    type="number"

                    value={formik3.values.enrol_deposit}
                    onChange={formik3.handleChange}
                    onBlur={formik3.handleBlur}
                    id="enrol_deposit"
                    min={0}
                    className={formik3.errors.enrol_deposit && formik3.touched.enrol_deposit ? "input-error" : "mb-0"}
                  />
                  {formik3.errors.enrol_deposit && formik3.touched.enrol_deposit && <p className="error">{formik3.errors.enrol_deposit}</p>}
                </Col>

              </Row>


            </Modal.Body>
            <Modal.Footer className='pt-0'>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={toggleModalPrice}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingPrice}>Submit</Button>

              </div>

            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          show={showModalEdit} onHide={() => setShowModalEdit(false)}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation={false}
        >
          <Form autoComplete="off" onSubmit={formik4.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5 className='page-title'> Subject </h5>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>

              {/* --------------Date--------------- */}
              <Row>


                {/* --------------Subject--------------- */}
                <Col lg="12" md="12" sm="12" className='position-relative'>
                  <h6 className="form-lable"><strong>Student</strong> : {StudentData ? StudentData.st_first_name != null && StudentData.st_first_name != '' ? StudentData.st_first_name + ' ' + StudentData.st_surname : '' : ''}</h6>
                </Col>
                <Col lg="12" md="12" sm="12" className='position-relative'>
                  <h6 className="form-lable"><strong>Session</strong> : {enrolledData && enrolledData.sest_name ? enrolledData.sest_name : ''}</h6>
                </Col>
                <Col lg="12" md="12" sm="12" className='position-relative'>
                  <h6 className="form-lable"><strong>Subject</strong> : {enrolledData && enrolledData.subject_name ? enrolledData.subject_name : ''}</h6>
                </Col>
                <Col lg="12" md="12" sm="12" className='position-relative mt-2'>
                  <h6 className="form-lable required-field">Subject</h6>
                  <Select menuPlacement="auto" options={enrolledOptionSubjectData}
                    name="enrol_subject"
                    value={formik4.values.enrol_subject}
                    id="enrol_subject"
                    isMulti
                    onChange={(selectedOption) => {
                      console.log(selectedOption)
                      const event = {
                        target: {
                          name: "enrol_subject",
                          value: selectedOption,
                        },
                      };
                      formik4.handleChange(event);
                    }}
                    onBlur={(event) => {
                      formik4.handleBlur(event);
                      formik4.setTouched({ ...formik4.touched, enrol_subject: true });
                    }}
                    className={formik4.errors.enrol_subject && formik4.touched.enrol_subject ? "input-error" : "mb-3"} />
                  {formik4.errors.enrol_subject && formik4.touched.enrol_subject && <span className="error">{formik4.errors.enrol_subject}</span>}
                </Col>

              </Row>
            </Modal.Body>

            <Modal.Footer className='pt-0'>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={() => setShowModalEdit(false)}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={IsSubmittingEdit}>Save</Button>
              </div>

            </Modal.Footer>
          </Form>

        </Modal>



        {showPopupGeneratePayment && (
          <Dialog open={showPopupGeneratePayment} onClose={closePopupGeneratePayment}>
            <DialogTitle>Send Mail ({DataGP.st_first_name}   {DataGP.st_surname})</DialogTitle>
            <DialogContent>

              <Form autoComplete="off" onSubmit={formik2.handleSubmit} ref={formRef}>
                <Form.Group >
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    id="payment_emailid"

                    value={formik2.values.payment_emailid}
                    onChange={formik2.handleChange}
                    onBlur={formik2.handleBlur}
                    className={formik2.errors.payment_emailid && formik2.touched.payment_emailid ? "input-error mb-0 " : ""}
                  />
                  {formik2.errors.payment_emailid && formik2.touched.payment_emailid && <span className="error clearfix">{formik2.errors.payment_emailid}</span>}




                  <Button variant="contained" className="primary-gray" onClick={() => setShowPopupGeneratePayment(false)}>Cancel</Button>
                  <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingPayment} >Send</Button>
                </Form.Group>
              </Form>
            </DialogContent>
          </Dialog>
        )}



        {/* yogeshstart */}

        <Modal
          show={modalShowEdit}
          onHide={() => {
            setModalShowEdit(false);
            seteditsessionData([]);
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation={false}
        >
          <Form onSubmit={formikedit.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5 className="page-title"> Edit enrolment product </h5>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {/* --------------Date--------------- */}
              <Row>
                {/* --------------Subject--------------- */}

                <Col lg="6" md="6" sm="6" className="position-relative">
                  <h6 className="form-lable">Subject</h6>
                  <Form.Control
                    type="text"
                    name="fk_sub_id"
                    id="fk_sub_id"
                    value={
                      formikedit.values.fk_sub_id &&
                        formikedit.values.fk_sub_id.length > 0
                        ? formikedit.values.fk_sub_id
                          .map((subject) => subject.label)
                          .join(", ")
                        : formikedit.values.subject_name // : "No subjects selected"
                    }
                    readOnly
                    disabled
                  />
                </Col>

                {/* --------------Category--------------- */}

                <Col lg="6" md="6" sm="6" className="position-relative">
                  <h6 className="form-lable required-field">
                    Enrolment Category
                  </h6>
                  <Form.Control
                    type="text"
                    name="enrol_category"
                    id="enrol_category"
                    value={formikedit.values?.enrol_category || ""}
                    readOnly
                    disabled
                  />
                </Col>

                <Col lg="6" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">
                    Payment Method{" "}
                    {["top"].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip
                            id={`tooltip-${placement}`}
                            className="custom-tooltip"
                          >
                            Note: Change the product dropdown options based on
                            the product type and payment method
                          </Tooltip>
                        }
                      >
                        <Button variant="secondary" className="no-bdr">
                          <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                        </Button>
                      </OverlayTrigger>
                    ))}
                  </h6>
                  <Select
                    menuPlacement="auto"
                    options={options_pay_method}
                    name="pp_pay_method"
                    value={options_pay_method.find(
                      (option) =>
                        option.value &&
                        formikedit.values.pp_pay_method &&
                        option.value.toString() ===
                        formikedit.values.pp_pay_method.toString()
                    )}
                    id="pp_pay_method"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "pp_pay_method",
                          value: selectedOption["value"],
                        },
                      };

                      fetchMasterProductData(
                        selectedOption["value"],
                        formikedit.values.fk_pt_key
                      );
                      setValuesedit({
                        ...formikedit.values,
                        enrol_product: "",
                      });

                      handleChangeedit(event);
                    }}
                    onBlur={(event) => {
                      handleBluredit(event);
                      setTouchededit({ ...touchededit, pp_pay_method: true });
                    }}
                    className={"mb-2"}
                  />
                  {errorsedit.pp_pay_method && touchededit.pp_pay_method && (
                    <span className="error">{errorsedit.pp_pay_method}</span>
                  )}
                </Col>

                {/* --------------Session--------------- */}

                <Col lg="6" md="6" sm="6" className="position-relative">
                  <h6 className="form-lable">Session </h6>

                  <Form.Control
                    type="text"
                    name="fk_sest_key"
                    id="fk_sest_key"
                    value={
                      formikedit.values?.sest_name || "No category selected"
                    }
                    readOnly
                    disabled
                  />
                </Col>

                <Col lg="6" md="6" sm="6" className="position-relative">
                  <h6 className="form-lable">Enrolment Date</h6>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["year", "month", "day"]}
                      value={dayjs(
                        formikedit.values.enrol_start_date,
                        "YYYY-MM-DD"
                      )}
                      onChange={() => { }} // No action when the date changes (disables change)
                      onBlur={(event) => {
                        handleBluredit(event);
                        setTouchededit({ ...touched, enrol_start_date: true });
                      }}
                      name="enrol_start_date"
                      disabled // Disable the DatePicker entirely
                      slotProps={{
                        textField: {
                          disabled: true, // Disable the text field to make it uneditable
                          size: "small",
                          id: "enrol_start_date",
                        },
                      }}
                      className={"w-100"}
                      minDate={dayjs().subtract(1, "year")}
                      format="DD-MM-YYYY"
                    />
                  </LocalizationProvider>
                </Col>
                {/* --------------Fees--------------- */}

                <Col lg="6" md="6" sm="6" className="position-relative">
                  <h6 className="form-lable required-field">
                    Product{" "}
                    {["top"].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip
                            id={`tooltip-${placement}`}
                            className="custom-tooltip"
                          >
                            Please select a product. Once the product is
                            selected and you are enrolled in the subscription,
                            it will no longer be editable.
                          </Tooltip>
                        }
                      >
                        <Button variant="secondary" className="no-bdr">
                          <i class="fa-solid fa-info-circle ml-2 mr-2"></i>
                        </Button>
                      </OverlayTrigger>
                    ))}
                  </h6>
                  <Select
                    menuPlacement="auto"
                    options={options_pricing}
                    name="enrol_product"
                    value={options_pricing.find(
                      (option) =>
                        option.value.toString() ===
                        formikedit.values?.enrol_product.toString()
                    )}
                    id="enrol_product"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "enrol_product",
                          value: selectedOption["value"],
                        },
                      };
                      handleChangeedit(event);
                      setValuesedit;
                    }}
                    onBlur={(event) => {
                      handleBluredit(event);
                      setTouchededit({
                        ...touchededit,
                        enrol_product: true,
                      });
                    }}
                    className={
                      errorsedit.enrol_product && touchededit.enrol_product
                        ? "input-error"
                        : "mb-3"
                    }
                  />
                  {errorsedit.enrol_product && touchededit.enrol_product && (
                    <span className="error">{errorsedit.enrol_product}</span>
                  )}
                </Col>

                <Col lg="6" md="6" sm="6" className="position-relative">
                  <h6 className="form-lable required-field mt-1">
                    Payment Service
                  </h6>
                  <Select
                    menuPlacement="auto"
                    options={options_payment_service}
                    name="enrol_pay_serice"
                    value={options_payment_service.find(
                      (option) =>
                        option.value.toString() ===
                        formikedit.values.enrol_pay_serice.toString()
                    )}
                    id="enrol_pay_serice"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "enrol_pay_serice",
                          value: selectedOption["value"],
                        },
                      };
                      handleChangeedit(event);
                    }}
                    onBlur={(event) => {
                      handleBluredit(event);
                      setTouchededit({
                        ...touched,
                        enrol_pay_serice: true,
                      });
                    }}
                    className={
                      errorsedit.enrol_pay_serice &&
                        touchededit.enrol_pay_serice
                        ? "input-error"
                        : "mb-0"
                    }
                  />
                  {errorsedit.enrol_pay_serice &&
                    touchededit.enrol_pay_serice && (
                      <p className="error mt-3">
                        {errorsedit.enrol_pay_serice}
                      </p>
                    )}
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer className="pt-0">
              <div className="mx-auto d-flex gap-2">
                <Button
                  variant="contained"
                  className="primary-gray"
                  onClick={() => {
                    setModalShowEdit(false);
                    seteditsessionData([]);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  className="primary-filled "
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* yogesh end  */}

        {/* yogeshstart addproduct */}

        <Modal
          show={modalShowAddproduct}
          onHide={() => {
            setModalShowAddproduct(false);
            setaddproductsessionData([]);
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation={false}
        >
          <Form onSubmit={handleSubmitaddproduct}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5 className="page-title"> Add enrolment product </h5>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {/* --------------Date--------------- */}
              <Row>
                {/* --------------Subject--------------- */}

                <Col lg="6" md="6" sm="6" className="position-relative">
                  <h6 className="form-lable">Subject</h6>
                  <Form.Control
                    type="text"
                    name="fk_sub_id"
                    id="fk_sub_id"
                    value={
                      valuesaddproduct.fk_sub_id &&
                        valuesaddproduct.fk_sub_id.length > 0
                        ? valuesaddproduct.fk_sub_id
                          .map((subject) => subject.label)
                          .join(", ")
                        : valuesaddproduct.subject_name // : "No subjects selected"
                    }
                    readOnly
                    disabled
                  />
                </Col>

                {/* --------------Category--------------- */}

                <Col lg="6" md="6" sm="6" className="position-relative">
                  <h6 className="form-lable">
                    Enrolment Category
                  </h6>
                  <Form.Control
                    type="text"
                    name="enrol_category"
                    id="enrol_category"
                    value={valuesaddproduct?.enrol_category || ""}
                    readOnly
                    disabled
                  />
                </Col>


                {/* --------------Session--------------- */}

                <Col lg="6" md="6" sm="6" className="position-relative">
                  <h6 className="form-lable">Session </h6>

                  <Form.Control
                    type="text"
                    name="fk_sest_key"
                    id="fk_sest_key"
                    value={
                      valuesaddproduct?.sest_name || "No category selected"
                    }
                    readOnly
                    disabled
                  />
                </Col>

                <Col lg="6" md="6" sm="6" className="position-relative">
                  <h6 className="form-lable">Enrolment Date</h6>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["year", "month", "day"]}
                      value={dayjs(
                        valuesaddproduct.enrol_start_date,
                        "YYYY-MM-DD"
                      )}
                      onChange={() => { }} // No action when the date changes (disables change)
                      onBlur={(event) => {
                        handleBluraddproduct(event);
                        setTouchedaddproduct({ ...touchedaddproduct, enrol_start_date: true });
                      }}
                      name="enrol_start_date"
                      disabled // Disable the DatePicker entirely
                      slotProps={{
                        textField: {
                          disabled: true, // Disable the text field to make it uneditable
                          size: "small",
                          id: "enrol_start_date",
                        },
                      }}
                      className={"w-100"}
                      minDate={dayjs().subtract(1, "year")}
                      format="DD-MM-YYYY"
                    />
                  </LocalizationProvider>
                </Col>
                {/* <Col lg="6" md="6" sm="6" className="position-relative">
                  <h6 className="form-lable required-field">Billing From  {["top"].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip
                            id={`tooltip-${placement}`}
                            className="custom-tooltip"
                          >
                           Please select a billing start date. Once the billing start date is set, it will no longer be editable.
                          </Tooltip>
                        }
                      >
                        <Button variant="secondary" className="no-bdr">
                          <i class="fa-solid fa-info-circle ml-2 mr-2"></i>
                        </Button>
                      </OverlayTrigger>
                    ))}</h6>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["year", "month", "day"]}
                      value={dayjs(
                        valuesaddproduct.Billing_start_date,
                        "YYYY-MM-DD"
                      )}
                      onChange={() => { }} // No action when the date changes (disables change)
                      onBlur={(event) => {
                        handleBluraddproduct(event);
                        setTouchedaddproduct({ ...touchedaddproduct, Billing_start_date: true });
                      }}
                      name="Billing_start_date"

                      slotProps={{
                        textField: {
                          disabled: true, // Disable the text field to make it uneditable
                          size: "small",
                          id: "Billing_start_date",
                        },
                      }}
                      className={"w-100"}
                      minDate={dayjs(valuesaddproduct.enrol_start_date, "YYYY-MM-DD")}
                      maxDate={dayjs()}
                      format="DD-MM-YYYY"
                    />
                  </LocalizationProvider>
                </Col> */}
                {/* --------------Fees--------------- */}
                <Col lg="6" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">
                    Payment Method{" "}
                    {["top"].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip
                            id={`tooltip-${placement}`}
                            className="custom-tooltip"
                          >
                            Note: Change the product dropdown options based on
                            the product type and payment method
                          </Tooltip>
                        }
                      >
                        <Button variant="secondary" className="no-bdr">
                          <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                        </Button>
                      </OverlayTrigger>
                    ))}
                  </h6>
                  <Select
                    menuPlacement="auto"
                    options={options_pay_method}
                    name="pp_pay_method"
                    value={options_pay_method.find(
                      (option) =>
                        option.value &&
                        valuesaddproduct.pp_pay_method &&
                        option.value.toString() ===
                        valuesaddproduct.pp_pay_method.toString()
                    )}
                    id="pp_pay_method"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "pp_pay_method",
                          value: selectedOption["value"],
                        },
                      };
                      const event2 = {
                        target: {
                          name: "fk_pt_key",
                          value: '',
                        },
                      };

                      setValuesaddproduct({
                        ...valuesaddproduct,
                        enrol_product: "",
                        fk_pt_key: '',
                      });


                      handleChangeaddproduct(event);
                      handleChangeaddproduct(event2);
                      formikaddproduct.setFieldTouched("fk_pt_key", false);
                    }}
                    onBlur={(event) => {
                      handleBluraddproduct(event);
                      setTouchedaddproduct({ ...touchedaddproduct, pp_pay_method: true, fk_pt_key: false });
                    }}
                    className={"mb-2"}
                  />
                  {errorsaddproduct.pp_pay_method && touchedaddproduct.pp_pay_method && (
                    <span className="error">{errorsaddproduct.pp_pay_method}</span>
                  )}
                </Col>
                <Col lg="6" md="6" sm="12" className='position-relative'>
                  <h6 className="form-lable required-field">Product Type  {['top'].map((placement) => (
                    <OverlayTrigger
                      key={placement}
                      placement={placement}
                      overlay={
                        <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                          Note: Change the product dropdown options based on the product type and payment method
                        </Tooltip>
                      }
                    >
                      <Button variant="secondary" className="no-bdr">
                        <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                      </Button>
                    </OverlayTrigger>

                  ))}</h6>


                  <Select menuPlacement="auto"
                    options={options_product_type.filter(option => option.value !== 'A65182496976263E')}
                    name="fk_pt_key"
                    value={options_product_type.find(
                      (option) =>
                        option.value.toString() ===
                        valuesaddproduct?.fk_pt_key.toString()
                    )}
                    id="fk_pt_key"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "fk_pt_key",
                          value: selectedOption["value"],
                        },
                      };


                      formikaddproduct.setFieldValue("enrol_product", '');
                      formikaddproduct.setFieldTouched("enrol_product", false);
                      fetchMasterProductData(valuesaddproduct.pp_pay_method, selectedOption["value"]);
                      handleChangeaddproduct(event);
                    }}
                    onBlur={(event) => {
                      handleBluraddproduct(event);
                      setTouchedaddproduct({ ...touchedaddproduct, fk_pt_key: true });
                    }}
                    className={
                      errorsaddproduct.fk_pt_key && touchedaddproduct.fk_pt_key
                        ? "input-error"
                        : "mb-3"
                    }
                  />
                  {errorsaddproduct.fk_pt_key && touchedaddproduct.fk_pt_key && <span className="error">{errorsaddproduct.fk_pt_key}</span>}

                </Col>
                <Col lg="6" md="6" sm="6" className="position-relative">
                  <h6 className="form-lable required-field">
                    Product{" "}
                    {["top"].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip
                            id={`tooltip-${placement}`}
                            className="custom-tooltip"
                          >
                            Please select a product. Once the product is
                            selected and you are enrolled in the subscription,
                            it will no longer be editable.
                          </Tooltip>
                        }
                      >
                        <Button variant="secondary" className="no-bdr">
                          <i class="fa-solid fa-info-circle ml-2 mr-2"></i>
                        </Button>
                      </OverlayTrigger>
                    ))}
                  </h6>
                  <Select
                    menuPlacement="auto"
                    options={options_pricing}
                    name="enrol_product"
                    value={options_pricing.find(
                      (option) =>
                        option.value.toString() ===
                        valuesaddproduct?.enrol_product.toString()
                    )}
                    id="enrol_product"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "enrol_product",
                          value: selectedOption["value"],
                        },
                      };
                      handleChangeaddproduct(event);

                    }}
                    onBlur={(event) => {
                      handleBluraddproduct(event);
                      setTouchedaddproduct({
                        ...touchedaddproduct,
                        enrol_product: true,
                      });
                    }}
                    className={
                      errorsaddproduct.enrol_product && touchedaddproduct.enrol_product
                        ? "input-error"
                        : "mb-3"
                    }
                  />
                  {errorsaddproduct.enrol_product && touchedaddproduct.enrol_product && (
                    <span className="error">{errorsaddproduct.enrol_product}</span>
                  )}
                </Col>

                <Col lg="6" md="6" sm="6" className="position-relative">
                  <h6 className="form-lable required-field mt-1">
                    Payment Service
                  </h6>
                  <Select
                    menuPlacement="auto"
                    options={options_payment_service}
                    name="enrol_pay_serice"
                    value={options_payment_service.find(
                      (option) =>
                        option.value.toString() ===
                        valuesaddproduct?.enrol_pay_serice.toString()
                    )}
                    id="enrol_pay_serice"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "enrol_pay_serice",
                          value: selectedOption["value"],
                        },
                      };
                      handleChangeaddproduct(event);
                    }}
                    onBlur={(event) => {
                      handleBluraddproduct(event);
                      setTouchedaddproduct({
                        ...touchedaddproduct,
                        enrol_pay_serice: true,
                      });
                    }}
                    className={
                      errorsaddproduct.enrol_pay_serice &&
                        touchedaddproduct.enrol_pay_serice
                        ? "input-error"
                        : "mb-0"
                    }
                  />
                  {errorsaddproduct.enrol_pay_serice &&
                    touchedaddproduct.enrol_pay_serice && (
                      <p className="error mt-3">
                        {errorsaddproduct.enrol_pay_serice}
                      </p>
                    )}
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer className="pt-0">
              <div className="mx-auto d-flex gap-2">
                <Button
                  variant="contained"
                  className="primary-gray"
                  onClick={() => {
                    setModalShowAddproduct(false);
                    setaddproductsessionData([]);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  className="primary-filled "
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* yogesh end addproduct  */}

        {/* --------------Page Start--------------- */}

        <div className="mb-3 d-flex justify-content-between align-items-center">
          <div>
            <h5 className='page-title'> Enrolment List </h5>

            <div role="presentation" >
              <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                <Typography color="text.primary">Enrolment</Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div className='d-flex gap-2'>
            {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}


            <div className='d-flex justify-content-end gap-2'>
              <div >
                <DropdownButton as={ButtonGroup} title="Action" id="dropdown-menu-align-responsive-1" className="ps-0 rounded-10 primary-filled btn-action text-light">
                  <Dropdown.Item eventKey="1" onClick={handleClick_student_edit}>Student Profile</Dropdown.Item>
                  <Dropdown.Item eventKey="2" onClick={handleClick_student_view}>Student Particulars</Dropdown.Item>
                  <Dropdown.Item eventKey="3"><NavLink to='/list-students' >Student list</NavLink></Dropdown.Item>

                </DropdownButton>
              </div>
            </div>
          </div>
        </div>


        <Card className='my-card p-1 bg-white'>

          <Card.Body>

            {/* -------------Content Start--------------- */}

            <Tab.Container defaultActiveKey="first">
              <Row>
                <Col sm={12} className='border-bottom mb-2'>

                <div className="d-flex justify-content-between">                     
                   <div className="d-flex justify-content-left align-items-center gap-3">
                        <span className="me-2 mt-2">
                          {StudentData
                            ? StudentData.st_first_name != null &&
                              StudentData.st_first_name != ""
                              ? StudentData.st_first_name +
                              " " +
                              StudentData.st_surname +
                              " - "
                              : ""
                            : ""}{" "}
                          <b>
                            {StudentData
                              ? StudentData.pk_student_id != null &&
                                StudentData.pk_student_id != ""
                                ? "#" + StudentData.pk_student_id
                                : ""
                              : ""}
                          </b>
                        </span>
                        {isActive === "nine" && (
                          <>
                            <span className="me-2 mt-2">
                              Card status :{" "}
                              {StudentData ? (
                                StudentData.Online_payment_card_status !== null &&
                                  StudentData.Online_payment_card_status !== "" ? (
                                  StudentData.Online_payment_card_status === 1 ? (
                                    <i
                                      className="fa fa-check-circle"
                                      style={{ color: "green" }}
                                    ></i>
                                  ) : (
                                    <i
                                      className="fa fa-times-circle"
                                      style={{ color: "red" }}
                                    ></i>
                                  )
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}{" "}
                              <b>
                                {StudentData
                                  ? StudentData.Online_payment_card_details !==
                                    null &&
                                    StudentData.Online_payment_card_details !== ""
                                    ? StudentData.Online_payment_card_details
                                    : ""
                                  : ""}
                              </b>
                            </span>
                          </>
                        )}
                      </div>
                      

                  <div className="d-flex flex-wrap  justify-content-right  float-end">
                    <div className=''>

                      <Button variant='primary' className='me-2 btn-primary-outline' onClick={() => setModalShowAdd(true)} >
                        Add Enrolment
                      </Button>
                      {/* <Button variant='primary' className='me-2' onClick={() => setModalShow2(true)}>
                            Edit Enrolment
                          </Button> */}
                      <Button variant='primary' className='me-2 btn-primary-outline' onClick={toggleModal1}>
                        Unenrol
                      </Button>

                      <Button variant='primary' className='me-2 btn-primary-outline' onClick={toggleModalDeposit}>
                        Deposit
                      </Button>
                    </div>
                    {/* <div className='d-flex gap-2 '>
                       <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div class="form-group has-search my-auto mb-2">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input type="text" class="form-control mb-0" onChange={handleFilter} />
                          </div>
                        

                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <div class="form-group has-search my-auto">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input type="text" class="form-control mb-0" onChange={handleFilter_c} />
                          </div>
                          
                        </Tab.Pane>

                        <Tab.Pane eventKey="third">
                          <div class="form-group has-search my-auto">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input type="text" class="form-control mb-0" onChange={handleFilter_l} />
                          </div>
                        
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth">
                          <div class="form-group has-search my-auto">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input type="text" class="form-control mb-0" onChange={handleFilter_p} />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="fifth">
                          <div class="form-group has-search my-auto">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input type="text" class="form-control mb-0" onChange={handleFilter_ph} />
                          </div>
                        </Tab.Pane>
                      </Tab.Content>

                    </div> */}

                  </div>
                  </div>

                  <Nav variant="pills" className="mb-3 text-dark ">
                    <div className='d-flex justify-content-start flex-wrap'>
                      <Nav.Item>
                        <Nav.Link eventKey="first" className="px-4 py-2 text-dark me-2" onClick={() => handleClick("first")}>Current</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second" className="px-4 py-2 text-dark" onClick={() => handleClick("second")}>Completed</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third" className="px-4 py-2 text-dark" onClick={() => handleClick("third")}>Unenrol</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fourth" className="px-4 py-2 text-dark" onClick={() => handleClick("fourth")} style={{ display: 'none' }}>Payment</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fifth" className="px-4 py-2 text-dark" onClick={() => handleClick("fifth")} style={{ display: 'none' }}>Payment History</Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                        <Nav.Link eventKey="sixth" className="px-4 py-2 text-dark" onClick={handleClick}>Stripe Payment</Nav.Link>
                      </Nav.Item> */}
                      <Nav.Item>
                        <Nav.Link eventKey="seventh" className="px-4 py-2 text-dark" onClick={() => handleClick("seventh")}>Deposit</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="eight" className="px-4 py-2 text-dark" onClick={() => handleClick("eight")}>Offline Payment</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="nine" className="px-4 py-2 text-dark" onClick={() => handleClick("nine")}>Online Payment</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="ten" className="px-4 py-2 text-dark" onClick={() => handleClick("ten")}>Transfer History</Nav.Link>
                      </Nav.Item>
                    </div>
                  </Nav>
                  
                </Col>

                <Col sm={12}>

                  <Tab.Content>
                    <Tab.Pane eventKey="first">

                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#e3e6ee",
                          },
                        }}
                        style={{ height: '100%', width: "100%" }}
                      >
                        <DataGrid
                          sx={{
                            // disable cell selection style
                            '.MuiDataGrid-cell:focus': {
                              outline: 'none'
                            },
                            // pointer cursor on ALL rows
                            '& .MuiDataGrid-row:hover': {
                              cursor: 'pointer'
                            }
                          }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "CurrentEnrolList" },
                              printOptions: {
                                pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "CurrentEnrolList"
                              },
                              records,
                              showQuickFilter: true
                            },


                          }}
                          initialState={{
                            ...records.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          showQuickFilter={true}

                          pageSizeOptions={[10, 25, 50, 100]}

                          loading={loading}
                          rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                          columns={columns}
                          rowHeight={60} density="compact"
                          onRowClick={(params) => handleSessionViewClick(params.row)}
                          disableRowSelectionOnClick
                          showCellVerticalBorder={true}
                          showColumnVerticalBorder={true}

                        />


                      </Box>


                    </Tab.Pane>

                    <Tab.Pane eventKey="second">

                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#e3e6ee",
                          },
                        }}
                        style={{ height: '100%', width: "100%" }}
                      >
                        <DataGrid
                          sx={{
                            // disable cell selection style
                            '.MuiDataGrid-cell:focus': {
                              outline: 'none'
                            },
                            // pointer cursor on ALL rows
                            '& .MuiDataGrid-row:hover': {
                              cursor: 'pointer'
                            }
                          }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "CompletedEnrolList" },
                              printOptions: {
                                pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "CompletedEnrolList"
                              },
                              records_c,
                              showQuickFilter: true
                            },


                          }}
                          initialState={{
                            ...records_c.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          showQuickFilter={true}

                          pageSizeOptions={[10, 25, 50, 100]}

                          loading={loading}
                          rows={records_c.map((row, index) => ({ ...row, id: `row-${index}` }))}
                          columns={columns_c}
                          rowHeight={60} density="compact"
                          onRowClick={(params) => handleSessionViewClick(params.row)}
                          disableRowSelectionOnClick
                          showCellVerticalBorder={true}
                          showColumnVerticalBorder={true}

                        />


                      </Box>


                    </Tab.Pane>

                    <Tab.Pane eventKey="third">

                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#e3e6ee",
                          },
                        }}
                        style={{ height: '100%', width: "100%" }}
                      >
                        <DataGrid
                          sx={{
                            // disable cell selection style
                            '.MuiDataGrid-cell:focus': {
                              outline: 'none'
                            },
                            // pointer cursor on ALL rows
                            '& .MuiDataGrid-row:hover': {
                              cursor: 'pointer'
                            }
                          }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "UnenrolList" },
                              printOptions: {
                                pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "UnenrolList"
                              },
                              records_l,
                              showQuickFilter: true
                            },


                          }}
                          initialState={{
                            ...records_l.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          showQuickFilter={true}

                          pageSizeOptions={[10, 25, 50, 100]}

                          loading={loading}
                          rows={records_l.map((row, index) => ({ ...row, id: `row-${index}` }))}
                          columns={columns_l}
                          rowHeight={60} density="compact"
                          onRowClick={(params) => handleUnEnrollSessionViewClick(params.row)}
                          disableRowSelectionOnClick
                          showCellVerticalBorder={true}
                          showColumnVerticalBorder={true}

                        />


                      </Box>



                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">

                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#e3e6ee",
                          },
                        }}
                        style={{ height: '100%', width: "100%" }}
                      >
                        <DataGrid
                          sx={{
                            // disable cell selection style
                            '.MuiDataGrid-cell:focus': {
                              outline: 'none'
                            },
                            // pointer cursor on ALL rows
                            '& .MuiDataGrid-row:hover': {
                              cursor: 'pointer'
                            }
                          }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "PaymentList" },
                              printOptions: {
                                pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "PaymentList"
                              },
                              records_p,
                              showQuickFilter: true
                            },


                          }}
                          initialState={{
                            ...records_p.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          showQuickFilter={true}

                          pageSizeOptions={[10, 25, 50, 100]}

                          loading={loading}
                          rows={records_p.map((row, index) => ({ ...row, id: `row-${index}` }))}
                          columns={columns_p}
                          rowHeight={60} density="compact"
                          disableRowSelectionOnClick
                          showCellVerticalBorder={true}
                          showColumnVerticalBorder={true}

                        />


                      </Box>

                    </Tab.Pane>
                    <Tab.Pane eventKey="fifth">

                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#e3e6ee",
                          },
                        }}
                        style={{ height: '100%', width: "100%" }}
                      >
                        <DataGrid
                          sx={{
                            // disable cell selection style
                            '.MuiDataGrid-cell:focus': {
                              outline: 'none'
                            },
                            // pointer cursor on ALL rows
                            '& .MuiDataGrid-row:hover': {
                              cursor: 'pointer'
                            }
                          }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "PaymentHistoryList" },
                              printOptions: {
                                pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "PaymentHistoryList"
                              },
                              records_ph,
                              showQuickFilter: true
                            },


                          }}
                          initialState={{
                            ...records_ph.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          showQuickFilter={true}

                          pageSizeOptions={[10, 25, 50, 100]}

                          loading={loading}
                          rows={records_ph.map((row, index) => ({ ...row, id: `row-${index}` }))}
                          columns={columns_ph}
                          rowHeight={60} density="compact"


                        />


                      </Box>

                    </Tab.Pane>



                    <Tab.Pane eventKey="sixth">
                      <div className='mt-3 mb-3 d-flex justify-content-end align-items-end'>

                        {StudentData && StudentData.count_xero_api && parseInt(StudentData.count_xero_api) > 0 ?
                          <Button variant="contained" className="primary-filled rounded-10 p-2" onClick={() => handleClick_student_xero()} >
                            Xero Journal
                          </Button> : null
                        }
                        {StudentData && StudentData.count_qb_api && parseInt(StudentData.count_qb_api) > 0 ?
                          <Button
                            variant="contained"
                            className="primary-filled rounded-10 p-2"
                            onClick={() => handleClick_student_quickbook()}
                          >
                            QB Journal
                          </Button>
                          : null}
                      </div>

                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#e3e6ee",
                          },
                        }}
                        style={{ height: '100%', width: "100%" }}
                      >
                        <DataGrid
                          sx={{
                            // disable cell selection style
                            '.MuiDataGrid-cell:focus': {
                              outline: 'none'
                            },
                            // pointer cursor on ALL rows
                            '& .MuiDataGrid-row:hover': {
                              cursor: 'pointer'
                            }
                          }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "PaymentList" },
                              printOptions: {
                                pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "PaymentList"
                              },
                              StripePayment_record,
                              showQuickFilter: true
                            },


                          }}
                          initialState={{
                            ...StripePayment_record.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          showQuickFilter={true}

                          pageSizeOptions={[10, 25, 50, 100]}

                          loading={loading}
                          rows={StripePayment_record.map((row, index) => ({ ...row, id_row: `${index + 1}` }))}
                          columns={columns_stripePayment}
                          rowHeight={60} density="compact"
                          disableRowSelectionOnClick
                          showCellVerticalBorder={true}
                          showColumnVerticalBorder={true}

                        />


                      </Box>



                    </Tab.Pane>

                    <Tab.Pane eventKey="seventh">
                      {/* <div className='mt-3 mb-3 d-flex justify-content-end align-items-end'>

                        
                      </div> */}

                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#e3e6ee",
                          },
                        }}
                        style={{ height: '100%', width: "100%" }}
                      >
                        <DataGrid
                          sx={{
                            // disable cell selection style
                            '.MuiDataGrid-cell:focus': {
                              outline: 'none'
                            },
                            // pointer cursor on ALL rows
                            '& .MuiDataGrid-row:hover': {
                              cursor: 'pointer'
                            }
                          }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "DepositList" },
                              printOptions: {
                                pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "PaymentList"
                              },
                              records_deposit,
                              showQuickFilter: true
                            },


                          }}
                          initialState={{
                            ...records_deposit.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          showQuickFilter={true}

                          pageSizeOptions={[10, 25, 50, 100]}

                          loading={loading}
                          rows={records_deposit.map((row, index) => ({ ...row, id: `row-${index}` }))}
                          columns={columns_deposit}
                          rowHeight={60} density="compact"
                          disableRowSelectionOnClick
                          showCellVerticalBorder={true}
                          showColumnVerticalBorder={true}

                        />


                      </Box>



                    </Tab.Pane>
                    <Tab.Pane eventKey="eight">
                      {/* <div className='mt-3 mb-3 d-flex justify-content-end align-items-end'>

                        
                      </div> */}

                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#e3e6ee",
                          },
                        }}
                        style={{ height: '100%', width: "100%" }}
                      >
                        <DataGrid
                          sx={{
                            // disable cell selection style
                            '.MuiDataGrid-cell:focus': {
                              outline: 'none'
                            },
                            // pointer cursor on ALL rows
                            '& .MuiDataGrid-row:hover': {
                              cursor: 'pointer'
                            }
                          }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "OfflinePaymentList" },
                              printOptions: {
                                pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "PaymentList"
                              },
                              records_deposit,
                              showQuickFilter: true
                            },


                          }}
                          initialState={{
                            ...records_offline.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          showQuickFilter={true}

                          pageSizeOptions={[10, 25, 50, 100]}

                          loading={loading}
                          rows={records_offline.map((row, index) => ({ ...row, id: `row-${index}` }))}
                          columns={columns_offline}
                          rowHeight={60} density="compact"
                          disableRowSelectionOnClick
                          showCellVerticalBorder={true}
                          showColumnVerticalBorder={true}

                        />


                      </Box>



                    </Tab.Pane>
                    <Tab.Pane eventKey="nine">
                      {/* <div className='mt-3 mb-3 d-flex justify-content-end align-items-end'>

                        
                      </div> */}

                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#e3e6ee",
                          },
                        }}
                        style={{ height: '100%', width: "100%" }}
                      >
                        <DataGrid
                          sx={{
                            // disable cell selection style
                            '.MuiDataGrid-cell:focus': {
                              outline: 'none'
                            },
                            // pointer cursor on ALL rows
                            '& .MuiDataGrid-row:hover': {
                              cursor: 'pointer'
                            }
                          }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "OnlinePaymentList" },
                              printOptions: {
                                pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "PaymentList"
                              },
                              records_online,
                              showQuickFilter: true
                            },


                          }}
                          initialState={{
                            ...records_online.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          showQuickFilter={true}

                          pageSizeOptions={[10, 25, 50, 100]}

                          loading={loading}
                          rows={records_online.map((row, index) => ({ ...row, id: `row-${index}` }))}
                          columns={columns_online}
                          rowHeight={60} density="compact"
                          disableRowSelectionOnClick
                          showCellVerticalBorder={true}
                          showColumnVerticalBorder={true}

                        />


                      </Box>



                    </Tab.Pane>
                    <Tab.Pane eventKey="ten">
                      {/* <div className='mt-3 mb-3 d-flex justify-content-end align-items-end'>

                        
                      </div> */}

                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#e3e6ee",
                          },
                        }}
                        style={{ height: '100%', width: "100%" }}
                      >
                        <DataGrid
                          sx={{
                            // disable cell selection style
                            '.MuiDataGrid-cell:focus': {
                              outline: 'none'
                            },
                            // pointer cursor on ALL rows
                            '& .MuiDataGrid-row:hover': {
                              cursor: 'pointer'
                            }
                          }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "OnlinePaymentList" },
                              printOptions: {
                                pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "RefundList"
                              },
                              records_refund,
                              showQuickFilter: true
                            },


                          }}
                          initialState={{
                            ...records_refund.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          showQuickFilter={true}

                          pageSizeOptions={[10, 25, 50, 100]}

                          loading={loading}
                          rows={records_refund.map((row, index) => ({ ...row, id: `row-${index}` }))}
                          columns={columns_refund}
                          rowHeight={60} density="compact"
                          disableRowSelectionOnClick
                          showCellVerticalBorder={true}
                          showColumnVerticalBorder={true}

                        />


                      </Box>



                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>

          </Card.Body>

        </Card>

        {/* --------------Section End--------------- */}




        {/* --------------Page End--------------- */}


        {/* <AddEntrolmentModal
          show={modalShow1}
          onHide={() => setModalShow1(false)}
        /> */}
        {/* <EditEntrolmentModal
          show={modalShow2}
          onHide={() => setModalShow2(false)}
        /> */}


      </div>


    </>
  );


};

export default SessionList;
