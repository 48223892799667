/*
Purpose: Edit details of Session Time
Designed: BY R.Suryadeva
Date:30/10/2023 
Project: TCMS
*/

import { useNavigate, NavLink, useLocation } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";

import "react-datepicker/dist/react-datepicker.css"; // Import the default styles

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

import moment from "moment";

import axios from "axios";
import {
  security_key,
  options_isholiday,
  options_days_sessTime,
  options_holidays,
  BASE_URL,
} from "../global";
import Select from "react-select";

const options_default = [
  { value: "", label: "---Select---" },
  { value: "Online", label: "Online" },
  { value: "In centre", label: "In centre" },
];

const basicSchema = yup.object().shape({
 
  sest_to_date: yup
    .string()
    .test("is-valid-date", "Please provide a valid date.", function (value) {
      const parsedDate = dayjs(value, "YYYY-MM-DD");
      return parsedDate.isValid();
    })
    .required("To date is required."),
  sest_max_student: yup
    .number()
    .integer()
    .min(1, "Minimum value 1 required.")
    .required("Maximum No. of Students is required."),

  sest_day: yup.mixed().required("Day  is required."),

   sest_start_time: yup
      .string() // Input value should be a string
      .required("Start time is required.") // Time of contact is required, and an error message is provided if not present
      .test("is-valid-time", "Please provide a valid time.", function (value) {
        // Check if the value is a valid time
        return dayjs(value, "HH:mm:ss").isValid();
      }),
    sest_end_time: yup
      .string()
      .required("End Time  is required.")
      .test(
        "is-valid-time",
        "End Time must be after Start Time",
        function (value) {
          const sest_start_time = moment(this.parent.sest_start_time, "HH:mm");
          const sest_end_time = moment(value, "HH:mm");
          return sest_end_time.isAfter(sest_start_time);
        }
      )
      .test("is-valid-time", "Please provide a valid time.", function (value) {
        // Check if the value is a valid time
        return dayjs(value, "HH:mm:ss").isValid();
      }),
    sest_description: yup.string().min(2, "Minimum 2 characters is required.").max(40, "Maximum 40 characters is required.").notRequired(),
    fk_tut_key: yup.mixed().required("Tutor  is required."),
    //sest_skip_holidays: yup.boolean().oneOf([true], "Please check the checkbox").required("Please check the checkbox"),
});

function EditSessionExample() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [SessionTimeData, setSessionTimeData] = useState(false);
  const [loading, setLoading] = useState(true);
//console.log(SessionTimeData)
  const formRef = useRef(null);

  const navigate = useNavigate();

  const location = useLocation();
  const [extractedSestKey, setextractedSestKey] = useState("");
  useEffect(() => {
    const { pk_sest_key } = location.state || {};

    const extractedSestKey = pk_sest_key;

    if (extractedSestKey !== "" && extractedSestKey !== undefined) {
      setextractedSestKey(extractedSestKey);
    } else {
      navigate("/list-session-time");
    }
  }, [location]);

  const handleSave = () => {
    navigate("/list-session-time");
  };
  const handleStudentViewClick = async (e) => {
    navigate(`/student-profile`, {
      state: { pk_student_key: `${e.fk_stu_key}` },
    });
  };

  const [options_tutor, setOptions] = useState([]);
  useEffect(() => {
    axios
      .post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "getAllTutorbycenterSess",
        center_key: sessionStorage.getItem("key"),
      })
      .then((response) => {
        // Assuming the API response is an array of objects with 'id' and 'value' properties
        const formattedOptions = response.data.data.map((option) => ({
          value: option.pk_tut_key,
          label: option.tut_fname + " " + option.tut_surname,
        }));
        setOptions(formattedOptions);
      })
      .catch((error) => {
        console.error("Error fetching options from API: ", error);
      });
  }, []);

  useEffect(() => {
    fetchSingleSesstionTimeData(extractedSestKey);
  }, [extractedSestKey]);

  const fetchSingleSesstionTimeData = async (id) => {
    try {
      const response = await axios.post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "getSingleSessionTime",
        pk_sest_id: id,
      });

      if (response.data.success === true) {
        if (response.data.data.length > 0) {
          setSessionTimeData(response.data.data[0]);
        }
      } else {
        // Handle unsuccessful response, e.g., show an alert to the user.
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const initialValues = {
    sest_name: SessionTimeData ? SessionTimeData.sest_name : "",
    sest_start_time: SessionTimeData ? SessionTimeData.sest_start_time : "",
    sest_end_time: SessionTimeData ? SessionTimeData.sest_end_time : "",
    sest_duration: SessionTimeData ? SessionTimeData.sest_duration : "",
    sest_from_date: SessionTimeData ? SessionTimeData.sest_from_date : "",
    sest_to_date: SessionTimeData ? SessionTimeData.sest_to_date : "",
    sest_type: SessionTimeData ? SessionTimeData.sest_type : "",
    fk_tut_key: SessionTimeData ? SessionTimeData.fk_tut_key : "",
    // sest_skip_holidays: SessionTimeData
    //   ? SessionTimeData.sest_skip_holidays
    //   : "",
      sest_skip_holidays: String(SessionTimeData ? SessionTimeData.sest_skip_holidays : ""),

    sest_is_holidays: SessionTimeData ? SessionTimeData.sest_is_holidays : "",
    sest_day: SessionTimeData ? SessionTimeData.sest_day : "",
    sest_max_student: SessionTimeData ? SessionTimeData.sest_max_student : "",
    sest_description: SessionTimeData ? SessionTimeData.sest_description : "",
    fk_cent_key: sessionStorage.getItem("key"),
    updated_by: sessionStorage.getItem("key"),
    pk_sest_key: SessionTimeData ? SessionTimeData.pk_sest_key : "",
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: basicSchema,
    // onSubmit,
    onSubmit: async (formValues) => {
      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "updateexistingSessionTime",
          ...formValues,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1 || response.data.data === 4) {
            toast.success("Success! Your form was submitted", {
              autoClose: 3000, // 3 seconds in this example
            });

            // After 3 seconds, redirect to another page
            setTimeout(function () {
              navigate("/list-session-time");
            }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

            setIsSubmitting(false); // Re-enable the submit button
          } else if (response.data.data === 2) {
            toast.error(
              "The tutor already assigned this date or time. Try again another date or time.",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );

            setIsSubmitting(false); // Re-enable the submit button
          } else if (response.data.data === 3) {
            toast.error(
              "The desired day does not exist within the date range.",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );

            setIsSubmitting(false); // Re-enable the submit button
          } else if (response.data.data === 5) {
            toast.error(
              "This session is not available in the upcoming days.",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );

            setIsSubmitting(false); // Re-enable the submit button
          }else if (response.data.data === 6) {
            toast.error(
              "Failure! Unable to update tutor. Try again later",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );

            setIsSubmitting(false); // Re-enable the submit button
          }  else {
            toast.error("Failure! Unable to update session. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button
          }
        } else {
          toast.error("Failure! Unable to update session. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmitting(false); // Re-enable the submit button
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to update session. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmitting(false); // Re-enable the submit button
      } finally {
        setIsSubmitting(false); // Re-enable the submit button
      }
    },
  });

  // Now, you can set variables as needed:
  const values = formik.values;
  const handleBlur = formik.handleBlur;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const errors = formik.errors;
  const handleSubmit = formik.handleSubmit;
  const setTouched = formik.setTouched;
  const resetForm = formik.resetForm;
  const setValues = formik.setValues;
  const handleReset = formik.handleReset;

  const resetFormFields = (resetForm, setValues, initialValues) => {
    resetForm(); // Reset Formik-controlled fields
  };

  //Api call start
  const [data, setItems] = useState([]);

  useEffect(() => {
    fetchData(extractedSestKey);
  }, [extractedSestKey]);

  const fetchData = async (id) => {
    try {
      const response = await axios.post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "getAllStudentEditSessionTime",
        pk_sest_key: id,
        fk_cent_key: sessionStorage.getItem("key"),
      });

      if (response.data.success === true) {
        setRecords(response.data.data);
        setItems(response.data.data);
        setLoading(false);
      } else {
        //alert message provide
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //Api call End

  // // Sample columns configuration
  // const columns = [
  //     {
  //         name: 'ID#',
  //         selector: row => row.pk_student_id,
  //         sortable: true
  //     },
  //     {
  //         name: 'Student Name',
  //         selector: row => row.st_first_name + " " + row.st_surname,
  //         sortable: true
  //     },
  //     {
  //         id: 'dob',
  //         name: 'Date Of Birth',
  //         selector: 'st_dob',
  //         cell: row => moment(row.st_dob, 'YYYY-MM-DD').format('DD-MM-YYYY'),
  //         sortable: true
  //     },
  //     {
  //         name: 'Gender',
  //         selector: row => row.st_gender,
  //         sortable: true
  //     },
  //     {
  //         name: 'Year Level',
  //         selector: row => row.st_year_level,
  //         sortable: true
  //     },

  //     {
  //         name: 'Subjects',
  //         selector: row => row.subject_name == null || row.subject_name == '' ? '-' : row.subject_name,
  //         sortable: true
  //     },

  //     { name: "id", selector: (row) => row.fk_stu_key, omit: true }
  // ];

  // Sample columns configuration
  const columns = [
    {
      headerName: "ID#",
      field: "ID#",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.pk_student_id == null || params.row.pk_student_id == ""
            ? "-"
            : params.row.pk_student_id
        }`;
      },
    },
    {
      headerName: "Student Name",
      field: "Student Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          (params.row.st_first_name == null ||
            params.row.st_first_name == "") &&
          (params.row.st_surname == null || params.row.st_surname == "")
            ? "-"
            : params.row.st_first_name + " " + params.row.st_surname
        }`;
      },
    },
    {
      headerName: "Date Of Birth",
      field: "Date Of Birth",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.st_dob == null || params.row.st_dob == ""
            ? "-"
            : moment(params.row.st_dob, "YYYY-MM-DD").format("DD-MM-YYYY")
        }`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === "-" || valueB === "-") {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === "-" ? 1 : -1;
        }

        const dateA = moment(valueA, "DD-MM-YYYY");
        const dateB = moment(valueB, "DD-MM-YYYY");

        return direction === "asc" ? dateA - dateB : dateB - dateA;
      },
    },
    {
      headerName: "Gender",
      field: "Gender",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.st_gender == null || params.row.st_gender == ""
            ? "-"
            : params.row.st_gender
        }`;
      },
    },
    {
      headerName: "Year Level",
      field: "Year Level",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.st_year_level == null || params.row.st_year_level == ""
            ? "-"
            : params.row.st_year_level
        }`;
      },
    },

    {
      headerName: "Subjects",
      field: "Subjects",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.subject_name == null || params.row.subject_name == ""
            ? "-"
            : params.row.subject_name
        }`;
      },
    },
  ];

  const [records, setRecords] = useState(data);

  function handleFilter(event) {
    const inputValue = event.target.value.trim().toLowerCase();
    const newData = data.filter((row) => {
      return (
        row.pk_student_id.toString().includes(inputValue) ||
        (row.st_first_name + " " + row.st_surname)
          .toLowerCase()
          .includes(inputValue) ||
        moment(row.st_dob).format("DD-MM-YYYY").includes(inputValue) ||
        row.st_gender.toLowerCase().includes(inputValue) ||
        row.st_year_level.toString().toLowerCase().includes(inputValue) ||
        (row.subject_name == null || row.subject_name == ""
          ? "-"
          : row.subject_name
        )
          .toString()
          .toLowerCase()
          .includes(inputValue)
      );
    });
    setRecords(newData);
  }

  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      const firstErrorField = Object.keys(formik.errors)[0];
      //const firstErrorFieldId = `${firstErrorField}Id`;

      const errorFieldElement = document.getElementById(firstErrorField);

      if (errorFieldElement) {
        errorFieldElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      } else {
        console.error(
          `Element with ID ${firstErrorField} not found in the DOM.`
        );
      }
      //document.getElementById(Object.keys(formik.errors)[0]).focus();
    }
  }, [formik]);

  return (
    <>
      {/* React alert */}
      <ToastContainer position="top-center" />
      {/* center form */}
      <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
        <div className="">
          <div className="mb-3 d-flex justify-content-between align-items-center">
            <div>
              <h5 className="page-title"> Edit Session Time </h5>
              <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb" className="my-breadcrumbs">
                  <NavLink underline="hover" color="inherit" to="/calendar">
                    {" "}
                    Home
                  </NavLink>

                  <Typography color="text.primary">Session</Typography>
                </Breadcrumbs>
              </div>
            </div>
            <div></div>
          </div>

          <Stack>
            <div className="my-card p-4 bg-white">
              <div className="section-heading border-bottom pb-3 mb-3">
                <h6 className="mb-0"> Session Time  </h6>
                {/* <small className="text-danger"> * Session name cannot be changed.</small> */}
              </div>
              <Row>
                {/* --------------sest_ Session Name--------------- */}

                {/* <Col
                  lg="4"
                  md="6"
                  sm="12"
                  className="position-relative"
                  // style={{ display: "none" }}
                >
                  <h6 className="form-lable ">
                    Session Name
                   
                  </h6>
                  <p className="form-control pe-none">{values.sest_name}</p>
                </Col> */}

                {/* --------------sest_ Session Name--------------- */}

                {/* <Col lg="4" md="6" sm="12" className="position-relative mb-3">
                  <h6 className="form-lable">Tutor</h6>
                  <Form.Control
                    type="text"
                    name="fk_tut_key"
                    id="fk_tut_key"
                    value={
                      options_tutor.find(
                        (option) =>
                          option.value &&
                          values.fk_tut_key &&
                          option.value.toString() ===
                            values.fk_tut_key.toString()
                      )?.label || ""
                    }
                    onChange={(e) => e.preventDefault()}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, fk_tut_key: true });
                    }}
                    className={
                      errors.fk_tut_key && touched.fk_tut_key
                        ? "input-error"
                        : ""
                    }
                    readOnly
                  />
                </Col> */}

                <Col lg="4" md="6" sm="12" className='position-relative mb-3'>
                    <h6 className="form-lable required-field">Tutor</h6>
                      <Select menuPlacement="auto"  options={options_tutor}
                        name="fk_tut_key"
                        id="fk_tut_key"
                        value={options_tutor.find(option => option.value && values.fk_tut_key && option.value.toString() === values.fk_tut_key.toString())}

                        onChange={(selectedOption) => {
                            const event = {
                                target: {
                                    name: "fk_tut_key",
                                    value: selectedOption["value"],
                                },
                            };
                            handleChange(event);
                        }}
                        onBlur={(event) => {
                            handleBlur(event);
                            setTouched({ ...touched, fk_tut_key: true });
                        }}
                        className={errors.fk_tut_key && touched.fk_tut_key ? "input-error" : ""}
                    />
                    {errors.fk_tut_key && touched.fk_tut_key && <p className="error">{errors.fk_tut_key}</p>}
                </Col>


                {/* --------------sest_ Session Name--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative mb-3">
                  <h6 className="form-lable">Session Type</h6>

                  <Form.Control
                    type="text"
                    name="sest_type"
                    id="sest_type"
                    value={
                      options_default.find(
                        (option) =>
                          option.value &&
                          values.sest_type &&
                          option.value.toString() ===
                            values.sest_type.toString()
                      )?.label || ""
                    }
                    onChange={(e) => e.preventDefault()}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, sest_type: true });
                    }}
                    className={"mb-0"}
                    readOnly
                  />
                </Col>

                <Col lg="4" md="6" sm="12" className="position-relative mb-3">
                  <h6 className="form-lable"> Class</h6>
                  <Form.Control
                    type="text"
                    name="sest_is_holidays"
                    id="sest_is_holidays"
                    value={
                      options_isholiday.find(
                        (option) =>
                          option.value &&
                          values.sest_is_holidays &&
                          option.value.toString() ===
                            values.sest_is_holidays.toString()
                      )?.label || ""
                    }
                    onChange={(e) => e.preventDefault()}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, sest_is_holidays: true });
                    }}
                    className={"mb-0"}
                    disabled
                  />
                </Col>

                {/* --------------sest_ Session Name--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable">From Date</h6>
                  <LocalizationProvider
                    className={"form-control w-100"}
                    dateAdapter={AdapterDayjs}
                  >
                    <Form.Control
                      type="text"
                      value={dayjs(values.sest_from_date, "YYYY-MM-DD").format(
                        "DD-MM-YYYY"
                      )}
                      readOnly
                      size="sm"
                      id="sest_from_date"
                      className={
                        errors.sest_from_date && touched.sest_from_date
                          ? "input-error mb-0 w-100"
                          : "mb-0 w-100"
                      }
                    />
                  </LocalizationProvider>
                </Col>

                {/* --------------Session Name--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">To Date</h6>

                  <LocalizationProvider
                    className={"form-control"}
                    dateAdapter={AdapterDayjs}
                  >
                    <DatePicker
                      views={["year", "month", "day"]}
                      value={dayjs(values.sest_to_date, "YYYY-MM-DD")}
                      onChange={(date) => {
                        const event = {
                          target: {
                            name: "sest_to_date",
                            value:
                              date != null
                                ? dayjs(date, "YYYY-MM-DD").format("YYYY-MM-DD")
                                : date,
                          },
                        };

                        handleChange(event);
                      }}
                      onBlur={(event) => {
                        handleBlur(event);
                        setTouched({ ...touched, sest_to_date: true });
                      }}
                      //onBlur={handleBlur, setTouched({ ...touched, sest_day: true });}
                      name="sest_to_date"
                      //id="sest_to_date"
                      slotProps={{
                        textField: {
                          readOnly: true,
                          size: "small",
                          id: "sest_to_date",
                        },
                      }}
                      className={
                        errors.sest_to_date && touched.sest_to_date
                          ? "input-error mb-0 w-100"
                          : "mb-0 w-100"
                      }
                      // minDate={
                      //   values.sest_from_date
                      //     ? dayjs(values.sest_from_date, "YYYY-MM-DD")
                      //     : dayjs()
                      // }
                      minDate={values.sest_from_date && dayjs(values.sest_from_date, 'YYYY-MM-DD').isAfter(dayjs()) ? dayjs(values.sest_from_date, 'YYYY-MM-DD') : dayjs()}

                      maxDate={
                        values.sest_is_holidays == 2 && values.sest_from_date
                          ? dayjs(values.sest_from_date, "YYYY-MM-DD")
                          : null
                      }
                      //renderInput={(params) => <TextField {...params} />}
                      format="DD-MM-YYYY"
                    />
                    {errors.sest_to_date && touched.sest_to_date && (
                      <span className="error">{errors.sest_to_date}</span>
                    )}
                  </LocalizationProvider>
                </Col>

                {/* --------------sest_ Session Name--------------- */}

                <Col lg="4" md="6" sm="12" className="mb-3 position-relative">
                  <h6 className="form-lable ">Day</h6>
                  <Form.Control
                    type="text"
                    name="sest_day"
                    id="sest_day"
                    value={
                      options_days_sessTime.find(
                        (option) =>
                          option.value &&
                          values.sest_day &&
                          option.value.toString() === values.sest_day.toString()
                      )?.label || ""
                    }
                    readOnly
                    disabled
                  />
                </Col>

                {/* -------------- Start Time--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">Start Time</h6>

                  {/*<LocalizationProvider
                    className={"form-control"}
                    dateAdapter={AdapterDayjs}
                  >
                    <Form.Control
                      type="text"
                      value={dayjs(values.sest_start_time, "HH:mm:ss").format(
                        "hh:mm A"
                      )}
                      readOnly // Make it non-editable
                      size="sm" // Small size for the input
                      id="sest_start_time"
                      className={"mb-0 w-100"}
                    />
                  </LocalizationProvider> */}
                  <LocalizationProvider
                    className={"form-control"}
                    dateAdapter={AdapterDayjs}
                  >
                    <TimePicker
                      value={dayjs(values.sest_start_time, "HH:mm:ss")}
                      onChange={(time) => {
                        const event = {
                          target: {
                            name: "sest_start_time",
                            value:
                              time != null
                                ? dayjs(time, "HH:mm:ss").format("HH:mm:ss")
                                : time,
                          },
                        };

                        handleChange(event);
                      }}
                      onBlur={(event) => {
                        handleBlur(event);
                        setTouched({ ...touched, sest_start_time: true });
                      }}
                      //id="sest_start_time"
                      slotProps={{
                        textField: {
                          readOnly: true,
                          size: "small",
                          id: "sest_start_time",
                        },
                      }}
                      className={
                        errors.sest_start_time && touched.sest_start_time
                          ? "input-error w-100 h-auto"
                          : "w-100 h-auto"
                      }
                    />
                    {errors.sest_start_time && touched.sest_start_time && (
                      <span className="error">{errors.sest_start_time}</span>
                    )}
                  </LocalizationProvider>
                </Col>
                {/* --------------End Time--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">End Time</h6>

                  {/* <LocalizationProvider
                    className={"form-control"}
                    dateAdapter={AdapterDayjs}
                  >
                    <Form.Control
                      type="text"
                      value={dayjs(values.sest_end_time, "HH:mm:ss").format(
                        "hh:mm A"
                      )}
                      readOnly
                      size="sm" // Small size for the input
                      id="sest_start_time"
                      className={"mb-0 w-100"}
                    />
                  </LocalizationProvider> */}
                  <LocalizationProvider
                    className={"form-control"}
                    dateAdapter={AdapterDayjs}
                  >
                    <TimePicker
                      value={dayjs(values.sest_end_time, "HH:mm:ss")}
                      onChange={(time) => {
                        const event = {
                          target: {
                            name: "sest_end_time",
                            value:
                              time != null
                                ? dayjs(time, "HH:mm:ss").format("HH:mm:ss")
                                : time,
                          },
                        };

                        handleChange(event);
                      }}
                      // onChange={handleChange}
                      onBlur={(event) => {
                        handleBlur(event);
                        setTouched({ ...touched, sest_end_time: true });
                      }}
                      // id="sest_end_time"
                      slotProps={{
                        textField: {
                          readOnly: true,
                          size: "small",
                          id: "sest_end_time",
                        },
                      }}
                      className={
                        errors.sest_end_time && touched.sest_end_time
                          ? "input-error  w-100 h-auto"
                          : "w-100 h-auto"
                      }
                    />
                    {errors.sest_end_time && touched.sest_end_time && (
                      <span className="error">{errors.sest_end_time}</span>
                    )}
                  </LocalizationProvider>
                </Col>

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable">Duration</h6>
                  <Form.Control
                    type="text"
                    id="sest_duration"
                    value={
                      values.sest_end_time &&
                      values.sest_start_time &&
                      values.sest_end_time != "Invalid Date" &&
                      values.sest_start_time != "Invalid Date"
                        ? moment
                            .utc(
                              moment(values.sest_end_time, "HH:mm").diff(
                                moment(values.sest_start_time, "HH:mm")
                              )
                            )
                            .format("HH:mm") // Format the duration as HH:mm
                        : "00.00"
                    }
                    readOnly
                  />
                </Col>

                <Col
                  lg="4"
                  md="6"
                  sm="12"
                  className="mb-3 position-relative"
                  style={{
                    display: values.sest_is_holidays == 2 ? "none" : "block",
                  }}
                >
                  <h6 className="form-lable">Skip Holidays</h6>
                  <Select
                    menuPlacement="auto"
                    options={options_holidays}
                    value={options_holidays.find(
                      (option) =>
                        option.value != null &&
                        values.sest_skip_holidays != null &&
                        option.value === values.sest_skip_holidays
                    )}
                    name="sest_skip_holidays"
                    id="sest_skip_holidays"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "sest_skip_holidays",
                          value: selectedOption["value"],
                        },
                      };
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, sest_skip_holidays: true });
                    }}
                    className={
                      errors.sest_skip_holidays && touched.sest_skip_holidays
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.sest_skip_holidays && touched.sest_skip_holidays && (
                    <p className="error">{errors.sest_skip_holidays}</p>
                  )}
                </Col>

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">
                    Maximum No. of Students
                  </h6>
                  <Form.Control
                    type="number"
                    value={values.sest_max_student}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="sest_max_student"
                    min={
                      SessionTimeData
                        ? SessionTimeData.student_count != "" &&
                          SessionTimeData.student_count != 0
                          ? SessionTimeData.student_count
                          : 1
                        : 1
                    }
                    max="100"
                    className={
                      errors.sest_max_student && touched.sest_max_student
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.sest_max_student && touched.sest_max_student && (
                    <p className="error">{errors.sest_max_student}</p>
                  )}
                </Col>

                <Col lg="4" md="6" sm="12" className='position-relative '>
                  <h6 className="form-lable">Session Description</h6>
                  <Form.Control
                      as="textarea" rows={1}

                      value={values.sest_description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="sest_description"
                      className={errors.sest_description && touched.sest_description ? "input-error control_textarea" : "control_textarea mb-0"}
                  />
                  {errors.sest_description && touched.sest_description && <p className="error">{errors.sest_description}</p>}
              </Col>
              </Row>
            </div>
          </Stack>

          {/* --------------Session Time End--------------- */}

          <Stack gap={2} className="mt-4">
            <div className="text-end">
              <Button
                variant="contained"
                className="primary-gray me-2"
                onClick={handleSave}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                className="primary-filled "
                disabled={isSubmitting}
              >
                Save
              </Button>
            </div>
          </Stack>
        </div>
      </Form>

      <Card className="my-card mt-4  p-1 bg-white">
        <Card.Header> Assigned Students </Card.Header>
        <Card.Body>
          <Row>
            <Col xs="12">
              <div className="">
                <Stack>
                  <Row>
                    <Col xs="12">
                      <div className="">
                        <Row className="justify-content-center">
                          {/* --------------Table Content Start--------------- */}
                          <Col sm={12}>
                            <div className="d-flex flex-wrap justify-content-end align-items-center mb-4">
                              <div class="form-group has-search my-auto">
                                <span class="fa fa-search form-control-feedback"></span>
                                <input
                                  type="text"
                                  class="form-control mb-0"
                                  onChange={handleFilter}
                                />
                              </div>
                            </div>
                          </Col>
                          {/* --------------Table Content Start--------------- */}

                          {/* --------------Table Content Start--------------- */}
                          <Col sm={12}>
                            <Box
                              sx={{
                                height: "fit-content",
                                width: "100%",
                                "& .actions": {
                                  color: "text.secondary",
                                },
                                "& .textPrimary": {
                                  color: "text.primary",
                                },
                                "& .super-app-theme--header": {
                                  backgroundColor: "#e3e6ee",
                                },
                              }}
                              style={{ height: "100%", width: "100%" }}
                            >
                              <DataGrid
                                sx={{
                                  // disable cell selection style
                                  ".MuiDataGrid-cell:focus": {
                                    outline: "none",
                                  },
                                  // pointer cursor on ALL rows
                                  "& .MuiDataGrid-row:hover": {
                                    cursor: "pointer",
                                  },
                                }}
                                //sx={{ border: "none", m: 2 }}
                                autoHeight
                                slots={{
                                  loadingOverlay: LinearProgress,
                                  //noRowsOverlay: CustomNoRowsOverlay,
                                  toolbar: GridToolbar,
                                  // toolbar: EditToolbar,
                                }}
                                slotProps={{
                                  toolbar: {
                                    csvOptions: { fileName: "StudentList" },
                                    printOptions: {
                                      pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`,
                                      disableToolbarButton: true,
                                      hideFooter: true,
                                      hideToolbar: true,
                                      fileName: "StudentList",
                                    },
                                    records,
                                    showQuickFilter: true,
                                  },
                                }}
                                initialState={{
                                  ...records.initialState,
                                  pagination: {
                                    paginationModel: { pageSize: 10 },
                                  },
                                }}
                                showQuickFilter={true}
                                pageSizeOptions={[10, 25, 50, 100]}
                                loading={loading}
                                rows={records.map((row, index) => ({
                                  ...row,
                                  id: `row-${index}`,
                                }))}
                                columns={columns}
                                rowHeight={60}
                                density="compact"
                                disableRowSelectionOnClick
                                showCellVerticalBorder={true}
                                showColumnVerticalBorder={true}
                                onRowClick={(params) =>
                                  handleStudentViewClick(params.row)
                                }
                              />
                            </Box>
                          </Col>
                          {/* --------------Table Content Start--------------- */}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Stack>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default EditSessionExample;
