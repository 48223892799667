

/*
Purpose: list details of refunds with use datatable
Designed by KTH
Date:24/08/2023 
Project: TCMS
*/

import { useState, useEffect } from "react";
import { useLocation, NavLink, useNavigate } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import {
    security_key, options_countries, BASE_URL
} from "../global";

import axios from 'axios';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Select from 'react-select';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';



function AdminFinanceRefunds() {
    const location = useLocation();
    useEffect(() => {
      // Store the previous pathname in sessionStorage
      sessionStorage.setItem('previousPageAdminPathname', location.pathname);
    }, [location.pathname]);
    const [loading, setLoading] = useState(true);
    const [option_centre, setOptions] = useState([]);
    const [option_YearLevel, setYearLevel] = useState([]);


    const [IsType, SetType] = useState('1');
    const [IsCountry, SetCountry] = useState('');
    const [IsCentre, SetCentre] = useState('');
    const [IsLevel, SetLevel] = useState('');

    const options_filter_type = [
        { value: '1', label: 'All' },
        { value: '2', label: 'Countries' },
        { value: '3', label: 'Centres' },
      ];


    const [data, setItems] = useState([]);

    useEffect(() => {
        fetchData();
        fetchCentreData();
        fetchYearLevelData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "getAdminRefundsReport",
                type: IsType,
                country: IsCountry,
                centre: IsCentre,
                filter_date: IsLevel
            });
           
            if (response.data.success === true) {
               
                let datas = response.data.data;
                // Filter invoices: include the last one and those with amount_paid > 0
                let filterData = datas.filter((report, index) => {
                  return parseInt(report.totalRefunds) > 0;
                });

                setRecords(filterData);
                setItems(filterData);
                setLoading(false);
            } else {
                //alert message provide
                setLoading(false);
                setRecords([]);
                setItems([]);
            }


        } catch (error) {
            setRecords([]);
            setItems([]);
            console.error('Error fetching data:', error);
        }

        
    };
    const fetchCentreData = async () => {
      

        try {
            const response_centre = await axios.post(BASE_URL+'API/api_index.php', {
              security_key: security_key,
              mode: 'getAllCenterGroupByCentre'
            });
           
            if(response_centre.data.success===true){
                const formattedOptions = response_centre.data.data.map(option => ({
                    value: option.pk_cent_key,
                    label: option.cent_name
                  }));
                  // formattedOptions.unshift({
                  //   value: '',
                  //   label: '--Select--'
                  // });
                  setOptions(formattedOptions);
            }else{
              //alert message provide
              setLoading(false);
            }
            
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const fetchYearLevelData = async () => {
      

        try {
            const response_year_level = await axios.post(BASE_URL+'API/api_index.php', {
              security_key: security_key,
              mode: 'getNetworkYearLevelReports',
              type: IsType,
              country: IsCountry,
              centre: IsCentre
            });
           
            if(response_year_level.data.success===true){
                const formattedYearLevelOptions = response_year_level.data.data.map(option => ({
                    value: option.level_id,
                    label: option.level_name
                  }));
                  formattedYearLevelOptions.unshift({
                    value: '',
                    label: '--Select--'
                  });
                  setYearLevel(formattedYearLevelOptions);
            }else{
              //alert message provide
              setLoading(false);
            }
            
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    //Api call End

    
    // Sample columns configuration
    const columns = [

        {

            headerName: 'Center Name',
            field: "Center Name",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.cent_name == null || params.row.cent_name == '' ? '-' : params.row.cent_name}`;
            },
        }, {
            headerName: 'Amount',
            field: "Amount",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.totalAmount == null || params.row.totalAmount == '' ? '-' : (params.row.totalAmount/100).toFixed(2)}`;
            },

        }, {
            headerName: 'No of Students',
            field: "No of Students",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.totalRefunds == null || params.row.totalRefunds == '' ? '-' : params.row.totalRefunds}`;
            },

        }, {
            headerName: 'Reasons',
            field: "Reasons",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.reasonsString == null || params.row.reasonsString == '' ? '-' :  params.row.reasonsString}`;
            },

        }


       
        
       

    ];



    const [records, setRecords] = useState(data);
    // function handleFilter(event) {
    //     const inputValue = event.target.value.trim().toLowerCase();
    //     const newData = data.filter(row => {
    //         return (
    //             (row.enrol_disc_reason == null || row.enrol_disc_reason == '' ? '-' : row.enrol_disc_reason).toLowerCase().includes(inputValue) ||
    //             (row.st_first_name == null || row.st_first_name == '' ? '-' : row.st_first_name + ' ' + row.st_surname).toLowerCase().includes(inputValue) ||
    //             (row.enrol_start_date == null ? '-' : Moment(row.enrol_start_date).format('DD-MM-YYYY')).toLowerCase().includes(inputValue) ||
    //             (row.sest_to_date == null ? '-' : Moment(row.sest_to_date).format('DD-MM-YYYY')).toLowerCase().includes(inputValue) ||
    //             (row.sest_duration == null || row.sest_duration == '' ? '-' : row.sest_duration).toLowerCase().includes(inputValue) ||
    //             (row.subject_name == null || row.subject_name == '' ? '-' : row.subject_name).toString().toLowerCase().includes(inputValue)
    //         );
    //     });
    //     setRecords(newData);
    // }

    // function handleClick(event) {
    //     event.preventDefault();
    //     console.info('You clicked a breadcrumb.');
    // }

    //Define the CSV data and filename
    // const csvData = records.map(row => ({
    //     'Unenrollment Reson': row.enrol_disc_reason == null || row.enrol_disc_reason == '' ? '-' : row.enrol_disc_reason,
    //     'Student Name': row.st_first_name == null || row.st_first_name == '' ? '-' : row.st_first_name + ' ' + row.st_surname,
    //     'Enroll From': row.enrol_start_date == null ? '-' : Moment(row.enrol_start_date).format('DD-MM-YYYY'),
    //     'Enroll To': row.sest_to_date == null ? '-' : Moment(row.sest_to_date).format('DD-MM-YYYY'),
    //     'Duration': row.sest_duration == null || row.sest_duration == '' ? '-' : row.sest_duration,
    //     'Subject': row.subject_name == null || row.subject_name == '' ? '-' : row.subject_name
    // }));
    // const csvFileName = 'unenrolled_report.csv';
    // const [IsType, SetType] = useState('');
    // const [IsCountry, SetCountry] = useState('');
    // const [IsCentre, SetCentre] = useState('');
    useEffect(() => {
        fetchData();
        //fetchYearLevelData();
      }, [IsType,IsCountry,IsCentre, IsLevel]);
      useEffect(() => {
       // fetchData();
       SetLevel('');
        fetchYearLevelData();
      }, [IsType,IsCountry,IsCentre]);

    return (
        <div className="">

            {/* --------------Page Start--------------- */}

            <div className="mb-3 d-flex justify-content-between align-items-center">
                <div>
                    <h5 className='page-title'> Refunds </h5>

                    <div role="presentation" >
                        <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                            <NavLink underline="hover" color="inherit" to='/admin/admin-list'> Home</NavLink>
                            <NavLink underline="hover" color="inherit" to='/admin/AdminReport'> Reports</NavLink>
                            <Typography color="text.primary">Refunds </Typography>
                        </Breadcrumbs>
                    </div>
                </div>
                <div className='d-flex align-items-center gap-2'>
                    {/* <Button onClick={() => navigate('/reports')} className="secondary-filled" variant="contained">
                        Back
                    </Button> */}

                </div>

            </div>
            {/* --------------Section Start--------------- */}

            <Card className='card my-card mb-3'>

                <Card.Body>

                    {/* -------------Content Start--------------- */}

                    <Row>
                        <Col xs="12">
                            <div className="">
                            <Row className=" mb-3">
                            <Col lg="4" md="4" sm="12" className='position-relative'>
                                    <h6 className="form-label ">Type</h6>
                                    
                                    <Select menuPlacement="auto" 
                                        options={options_filter_type}
                                        name="type"
                                        value={options_filter_type.find(option => option.value === IsType)}
                                        //value={IsType}
                                        id="type"
                                        onChange={(selectedOption) => {
                                            // const event = {
                                            //     target: {
                                            //         name: "f_type",
                                            //         value: selectedOption["value"],
                                            //     },
                                            // };
                                            SetType(selectedOption["value"]);
                                            if (selectedOption["value"] === 1) {
                                                const allCountries = options_countries.map(country => country.value);
                                                SetCountry(allCountries);
                                                // const countryEvent = {
                                                //     target: {
                                                //         name: "country_id",
                                                //         value: allCountries,
                                                //     },
                                                // };
                                                // handleChange(countryEvent);
                                            }
                                           // handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                        // handleBlur(event);
                                        // setTouched({ ...touched, f_type: true });
                                        }}
                                       // className={errors.f_type && touched.f_type ? "input-error" : ""}
                                    />
                                    {/* {errors.f_type && touched.f_type && <span className="error">{errors.f_type}</span>} */}
                                    </Col>
                                    {parseInt(IsType) === 2 && (
                                    <> <Col lg="4" md="4" sm="12" className='position-relative'>
                                    <h6 className="form-label ">Countries</h6>
                                    <Select menuPlacement="auto" 
                                        options={options_countries}
                                        name="country_id"
                                        value={options_countries.find(option => option.value === IsCountry)}
                                        //value={IsCountry}
                                        id="country_id"
                                       // isMulti
                                        onChange={(selectedOption) => {
                                            SetCountry(selectedOption['value'])
                                            // const event = {
                                            //     target: {
                                            //         name: "country_id",
                                            //         value: selectedOption,
                                            //     },
                                            // };
                                            // handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                        // handleBlur(event);
                                        // setTouched({ ...touched, country_id: true });
                                        }}
                                        // className={errors.country_id && touched.country_id ? "input-error" : ""}
                                    />
                                    {/* {errors.country_id && touched.country_id && <span className="error">{errors.country_id}</span>} */}
                                    </Col>
                                    </> )}
                                    {parseInt(IsType) === 3 && (
                                        <>
                                    <Col lg="4" md="4" sm="12" className='position-relative'>
                                    <h6 className="form-label ">Centres</h6>
                                    <Select menuPlacement="auto" 
                                        options={option_centre}
                                        name="fk_cent_key"
                                        value={option_centre.find(option => option.value === IsCentre)}
                                        //value={IsCentre}
                                        id="fk_cent_key"
                                        //isMulti
                                        onChange={(selectedOption) => {
                                            SetCentre(selectedOption['value']);
                                            // const event = {
                                            //     target: {
                                            //         name: "fk_cent_key",
                                            //         value: selectedOption,
                                            //     },
                                            // };
                                            // handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                        // handleBlur(event);
                                        // setTouched({ ...touched, fk_cent_key: true });
                                        }}
                                        // className={errors.fk_cent_key && touched.fk_cent_key ? "input-error" : ""}
                                    />
                                    {/* {errors.fk_cent_key && touched.fk_cent_key && <span className="error">{errors.fk_cent_key}</span>} */}
                                    </Col>
                                    </> )}
                                    <Col lg="4" md="4" sm="12" className='position-relative'>
                                    <h6 className="form-label ">Date</h6>
                                    <LocalizationProvider className={'form-control'} dateAdapter={AdapterDayjs}>
                                        <DatePicker  views={['year', 'month', 'day']}

                                            value={dayjs(IsLevel, 'YYYY-MM-DD')}
                                            onChange={(date) =>  {
                                                SetLevel(date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date);
                                               
                                            }}
                                            onBlur={(event) => {
                                              
                                            }}
                                            name="date"
                                            slotProps={{
                                                textField: { readOnly: true, size: 'small',
                                                    id: 'date',
                                                },
                                                }}
                                            className={"w-100"}
                                            maxDate={dayjs()}
                                            format="DD-MM-YYYY"
                                        />
                                    </LocalizationProvider>
                                    </Col>
                                    
                                    <Col lg="4" md="4" sm="12" className='position-relative' style={{display:'none'}}>
                                    <h6 className="form-label ">Year Level</h6>
                                    <Select menuPlacement="auto" 
                                        options={option_YearLevel}
                                        name="yearLevel"
                                        value={option_YearLevel.find(option => option.value === IsLevel)}
                                        //value={IsCentre}
                                        id="yearLevel"
                                        //isMulti
                                        onChange={(selectedOption) => {
                                            SetLevel(selectedOption['value']);
                                            // const event = {
                                            //     target: {
                                            //         name: "fk_cent_key",
                                            //         value: selectedOption,
                                            //     },
                                            // };
                                            // handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                        // handleBlur(event);
                                        // setTouched({ ...touched, fk_cent_key: true });
                                        }}
                                        // className={errors.fk_cent_key && touched.fk_cent_key ? "input-error" : ""}
                                    />
                                    {/* {errors.fk_cent_key && touched.fk_cent_key && <span className="error">{errors.fk_cent_key}</span>} */}
                                    </Col>
                                    
                                    
                                    
                                    
                                     </Row>
                                <Row className="justify-content-center">

                                    {/* --------------Table Content Start--------------- */}
                                    {/* <Col sm={12}>

                                        <div className="d-flex flex-wrap justify-content-between mb-3">
                                            <CSVLink
                                                data={csvData}
                                                filename={csvFileName}
                                                // className="btn btn-primary"
                                                target="_blank"
                                            >
                                                <i class="fa-solid fa-file-csv my-auto" style={{ fontSize: '24px' }}></i>
                                            </CSVLink>

                                            <div class="form-group has-search my-auto">
                                                <span class="fa fa-search form-control-feedback"></span>
                                                <input type="text" class="form-control mb-0" onChange={handleFilter} />
                                            </div>


                                        </div>

                                    </Col> */}
                                    {/* --------------Table Content Start--------------- */}



                                    {/* --------------Table Content Start--------------- */}
                                    {/* <Col sm={12}>

                                        <DataTable
                                            progressPending={loading}
                                            columns={columns}
                                            data={records}
                                            fixedHeader
                                            pagination
                                            customStyles={customeStyle}
                                            className="cursor-pointer  list-cus-h"
                                        />

                                    </Col> */}

                                    <Box
                                        sx={{
                                            height: "fit-content",
                                            width: "100%",
                                            "& .actions": {
                                                color: "text.secondary",
                                            },
                                            "& .textPrimary": {
                                                color: "text.primary",
                                            },
                                            "& .super-app-theme--header": {
                                                backgroundColor: "#f4f4f4",
                                            },
                                            "& .MuiDataGrid-row": {
                                                border: '1px solid #eff0f2'
                                            },
                                            "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                                                outline: "none !important",
                                            },
                                            "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                                            {
                                                outline: "none !important",
                                            },
                                        }}
                                        style={{ height: '100%', width: "100%" }}
                                    >
                                       <DataGrid
                                    sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                                            //sx={{ border: "none", m: 2 }}
                                            autoHeight
                                            slots={{
                                                loadingOverlay: LinearProgress,
                                                //noRowsOverlay: CustomNoRowsOverlay,
                                                toolbar: GridToolbar,
                                                // toolbar: EditToolbar,
                                            }}
                                            slotProps={{
                                                toolbar: {
                                                    csvOptions: { fileName: "RefundReport" },
                                                    printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "RefundReport" },
                                                    records,
                                                    showQuickFilter: true
                                                },


                                            }}
                                            initialState={{
                                                ...records.initialState,
                                                pagination: { paginationModel: { pageSize: 10 } },
                                            }}
                                            showQuickFilter={true}

                                            pageSizeOptions={[10, 25, 50, 100]}

                                            loading={loading}
                                            rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                                            columns={columns}
                                            rowHeight={60} density="compact"
                                            disableRowSelectionOnClick
                                            showCellVerticalBorder={true}
                                            showColumnVerticalBorder={true}

                                        />

                                    </Box>
                                    {/* --------------Table Content Start--------------- */}

                                </Row>

                            </div>
                        </Col>
                    </Row>

                    {/* --------------Content End--------------- */}


                </Card.Body>

            </Card>

            {/* --------------Section End--------------- */}




            {/* --------------Page End--------------- */}

        </div>
    );
}

export default AdminFinanceRefunds;