/*
Purpose: list details of student with use datatable
Designed by R.Sanjai
Date:24/08/2023 
Project: TCMS


UpdateBy:KTH
updateDate:30/08/2023 
Purpose: include API
*/

import { useState, useCallback, useEffect, useRef } from "react";

import { useNavigate, NavLink } from "react-router-dom";

import { Row, Col, Card, Form, Modal} from 'react-bootstrap';


import axios from 'axios';

import Breadcrumbs from '@mui/material/Breadcrumbs';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import React from 'react';
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { FiEye, FiEyeOff } from "react-icons/fi"; 
import { security_key, BASE_URL } from "../global";


function ListParent() {
  const formRef = useRef(null);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [showDelete, setDeleteShow] = useState(false);
  const [data_delete, setDeleteLabelData] = useState([]);
  const [isUserDeleteSubmitting, setIsUserDeleteSubmitting] = useState(false);


  const handleDeleteClick = (row) => {

    const updatedData = { ...row };
    setDeleteLabelData(updatedData);

    setDeleteShow(true);
  };

  const [showVisible, setVisibleShow] = useState(false);
  const [data_visible, setVisibleLabelData] = useState([]);
  const [isVisibleSubmitting, setIsVisibleSubmitting] = useState(false);


  const handleVisibleClick = (row) => {
    const updatedData = { ...row };
    setVisibleLabelData(updatedData);

    setVisibleShow(true);
  };

  const [passwordVisible, setPasswordVisible] = useState(false);


  const togglePasswordVisibility = () => {
      setPasswordVisible(!passwordVisible);
  };

  const [ConpasswordVisible, setConPasswordVisible] = useState(false);


  const toggleConPasswordVisibility = () => {
      setConPasswordVisible(!ConpasswordVisible);
  };


  const [StudentData, setStudentData] = useState([]);

  const toggleModalpass = (row) => {
    const updatedData = { ...row };
    setStudentData(updatedData);

    setPassShowModal(!showModalPass);
  };

  const [isSubmittingPass, setIsSubmittingPass] = useState(false);

  const [showModalPass, setPassShowModal] = useState(false);
 

  //  const passwordRules = /^(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
  const validationSchema3 = yup.object().shape({
      pa_login_id: yup.string()
      .required("Username is required.")
      .min(2, "Minimum 2 characters is required.")
      .max(30, "Maximum 30 characters is required.")
      .matches(/^[^'"`\s’]+$/, "Avoid spaces, apostrophe & quotes."),
      pa_password: yup.string().min(5, "Minimum 5 characters is required.").required("Password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
      pa_conform_password: yup
          .string()
          .oneOf([yup.ref("pa_password"), null], "Password is not matching")
          .required("Confirm password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),

  });



  const initialValues3 = {

      pk_par_key: StudentData ? StudentData.pk_par_key : '',

      /*tbl_center_auth*/
      pa_login_id: StudentData ? StudentData.pa_login_id : '',
      pa_password: StudentData ? StudentData.pa_direct_password : '',
      pa_conform_password: '',
      fk_cent_key: sessionStorage.getItem('key'),
      updated_by: sessionStorage.getItem('key'),
  };

  const formik3 = useFormik({
      initialValues: initialValues3,
      validationSchema: validationSchema3,
      enableReinitialize: true,
      onSubmit: async (values) => {
          setIsSubmittingPass(true);


          try {
              const response = await axios.post(BASE_URL + "API/api_index.php", {
                  security_key: security_key,
                  mode: "updateParentAuth_new",
                  ...values,
              });

              if (response.data.success === true) {
                  // Reset the form
                  //formRef.current.reset();
                  if (response.data.data === 1) {
                      toast.success("Success! Manage Login.", {
                          autoClose: 3000, // 3 seconds in this example
                      });

                      setTimeout(function () {
                          window.location.reload(false);
                          setIsSubmittingPass(false); // Re-enable the submit button
                      }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

                    



                  } else if (response.data.data === 2) {

                      toast.error("Parents / Guardians username is not available", {
                          autoClose: 3000, // 3 seconds in this example
                      });

                      setIsSubmittingPass(false); // Re-enable the submit button

                  } else {
                      toast.error("Failure! Unable to update manage login. Try again later", {
                          autoClose: 3000, // 3 seconds in this example
                      });

                      setIsSubmittingPass(false); // Re-enable the submit button
                  }

              } else {

                  toast.error("Failure! Unable to update manage login. Try again later", {
                      autoClose: 3000, // 3 seconds in this example
                  });

                  setIsSubmittingPass(false); // Re-enable the submit button

              }
          } catch (error) {
              console.error("Error fetching data:", error);
              toast.error("Failure! Unable to update manage login. Try again later", {
                  autoClose: 3000, // 3 seconds in this example
              });

              setIsSubmittingPass(false); // Re-enable the submit button

          } finally {
              setIsSubmittingPass(false); // Re-enable the submit button

          }

      },
  });

  function onSubmitDelete() {
    setIsUserDeleteSubmitting(true);

    const responsedelete = axios.post(BASE_URL + "API/api_index.php", {

      security_key: security_key,
      updated_by: sessionStorage.getItem('key'),
      mode: "deleteParent",
      pk_par_key: data_delete.pk_par_key
    }).then((responsedelete) => {

      if (responsedelete.data.data == 1) {

        setDeleteShow(false)
        // Handle the successful response here
        toast.success("Success! Parent has been inactivated.", {
          autoClose: 3000, // 3 seconds in this example
        });

        fetchData();

        setIsUserDeleteSubmitting(false);

      } else {
        toast.error("Failure! Unable to Parent has been inactive. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsUserDeleteSubmitting(false);
      }


    })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to Parent has been inactive. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsUserDeleteSubmitting(false);
      });
  }




  function onSubmitVisible() {
    setIsVisibleSubmitting(true);

    const responseVisible = axios.post(BASE_URL + "API/api_index.php", {

      security_key: security_key,
      updated_by: sessionStorage.getItem('key'),
      mode: "VisibleParent",
      pk_par_key: data_visible.pk_par_key
    }).then((responseVisible) => {

      if (responseVisible.data.data == 1) {

        setVisibleShow(false)
        // Handle the successful response here
        toast.success("Success! Parent has been activated.", {
          autoClose: 3000, // 3 seconds in this example
        });

        fetchData();

        setIsVisibleSubmitting(false);

      } else {
        toast.error("Failure! Unable to Parent has been active. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsVisibleSubmitting(false);
      }


    })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to Parent has been active. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsVisibleSubmitting(false);
      });
  }



  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);

  const [showPopupGeneratePayment, setShowPopupGeneratePayment] = useState(false);
  const [DataGP, setDataGP] = useState([]);


  const handleRowClick = async (e) => {
    navigate(`/ListParentStudent`, { state: { pk_par_key: `${e.pk_par_key}` } });
  }




  const basicSchema2 = yup.object().shape({
    payment_emailid: yup.string().email("Please enter a valid email address").required("Email is required.").matches(/^[^$&+,:;=?@#|'<>.^*()%!-]+/, 'Input cannot start with a special character').test('no-dot', 'Please enter a valid email address', function (value) {
      return value && value.includes('.');
    }),

  });
  const initialValues2 = {
    /*tbl_center_master*/
    payment_emailid: DataGP ? DataGP.par_emailid != '' && DataGP.par_emailid != null ? DataGP.par_emailid : '' : '', //Centre name-1

    created_by: sessionStorage.getItem('key'),
    visibility: "1",
  };


  // const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched, resetForm, setValues } = useFormik({
  const formik2 = useFormik({
    initialValues: initialValues2,
    validationSchema: basicSchema2,
    enableReinitialize: true,
    // onSubmit,

    onSubmit: async (formValues) => {
      setIsSubmittingPayment(true);

      try {
        const responseSE = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          fk_cent_key: sessionStorage.getItem('key'),
          mode: "parentPaymentMail",
          pk_par_key: DataGP.pk_par_key,
          ...formValues,
        });

        if (responseSE.data.success === true) {

          // Reset the form
          //formRef.current.reset();
          if (responseSE.data.data === 1) {
            toast.success("Success! Your payment request mail has been successfully sent.", {
              autoClose: 3000, // 3 seconds in this example
            });


            setIsSubmittingPayment(false);
            setShowPopupGeneratePayment(false);
            window.open(responseSE.data.dataRes, '_blank', 'noopener,noreferrer');

          } else {
            toast.error("Failure! Your payment request mail has been not sent. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setTimeout(function () {
              setIsSubmittingPayment(false);
              setShowPopupGeneratePayment(false)
            }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
          }

        } else {
          

          if (responseSE.data.data === 2) {
            toast.error("Failure! Your payment request mail has been not sent. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });
          } else {



            toast.error("Failure! Your payment request mail has been not sent. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });
          }

          setTimeout(function () {
            setIsSubmittingPayment(false);
            setShowPopupGeneratePayment(false)
          }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Your payment request mail has been not sent. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setTimeout(function () {
          setIsSubmittingPayment(false);
          setShowPopupGeneratePayment(false)
        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
      } finally {
        setTimeout(function () {
          setIsSubmittingPayment(false);
          setShowPopupGeneratePayment(false)
        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
      }

    },
  });
  // Sample columns configuration
  const columns = [
    {

      headerName: 'Name',
      field: "Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.par_firstname == null || params.row.par_firstname == '' ? '-' : params.row.par_firstname + ' ' + params.row.par_surname}`;
      },

    },
    {
      headerName: 'Student Count',
      field: "Student Count",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.student_count == null || params.row.student_count == '' ? '-' : params.row.student_count}`;
      },
    },
    {
      headerName: 'User Name',
      field: "User Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.pa_login_id == null || params.row.pa_login_id == '' ? '-' : params.row.pa_login_id}`;
      },

    },
    {
      headerName: 'Email',
      field: "Email",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.par_emailid == null || params.row.par_emailid == '' ? '-' : params.row.par_emailid}`;
      },
    },
    { 
      headerName: 'Phone',
      field: "Phone",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.par_phone == null || params.row.par_phone == '' ? '-' : params.row.par_phone}`;
      },
      },
      // {

      //   field: "Payment link",
      //  // field: "actions",
      //   type: "actions",
  
      //   //field: "#",
      //   //  type: "actions",
      //   headerName: "Payment link",
      //   headerClassName: "super-app-theme--header",
      //   width: 150,
      //   disableExport: false,
      // disableColumnFilter: false,
      // disableColumnMenu: false,
      // filterable: true,
      // sortable: true,
      // headerAlign:"left",
      // valueGetter: (params) => {
      //   return `${params.row.resultCode == 'Authorised' ? 'Authorised' : 'Not Authorised'}`;
      // },
      //   renderCell: (params) => {
      //     const row = params.row;
  
      //     if (row.resultCode == 'Authorised') {
      //       return (
      //         <div >
  
      //          <p className="text-success p-1 my-2 autr-btn"> Authorised</p>
               
      //         </div>
      //       );
      //     }
      //     else {
      //       return (
      //         <div >
  
      //           {parseInt(row.par_visibility) == 1 ?
      //           <> <Button variant="outlined-red" onClick={() => openPopupGP(row)} className="primary-blue my-2" disabled={isSubmittingPayment}> Send Payment Link</Button>
      //             </> :
      //            null}
                  
      //         </div>
      //       );
      //     }
  
      //   }
      // },
    {

      field: "actions",
      type: "actions",

      //field: "#",
      //  type: "actions",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 230,
      disableExport: false,
    disableColumnFilter: false,
    disableColumnMenu: false,
    filterable: true,
    sortable: true,
    headerAlign:"left",
    valueGetter: (params) => {
      return `${parseInt(params.row.par_visibility)==1? 'Active' : 'Inactive'}`;
    },
      renderCell: (params) => {
        const row = params.row;

        if (row.resultCode == 'Authorised') {
          return (
            <div className='cus-prnt-tbl-parent'>

             {/* <p className="text-success p-1 my-2 autr-btn"> Authorized</p> */}

              {parseInt(row.par_visibility) == 1 ?
                <span className='active-btn my-auto cursor-pointer' onClick={() => handleDeleteClick(row)} >
                  <DoneIcon className="icon-blue  btn-icon me-1" />
                  Active
                </span> :
                <span className='deactive-btn my-auto cursor-pointer' onClick={() => handleVisibleClick(row)} >
                  <CloseIcon className=" btn-icon me-1" />
                  Inactive
                </span>}
                <Button variant="outlined-red" onClick={() => toggleModalpass(row)} className="primary-blue my-2" > Manage login</Button>

            </div>
          );
        }
        else {
          return (
            <div className='cus-prnt-tbl-parent'>

             

              {parseInt(row.par_visibility) == 1 ?
              <>
                <span className='active-btn my-auto cursor-pointer' onClick={() => handleDeleteClick(row)} >
                  <DoneIcon className="icon-blue  btn-icon me-1" />
                  Active
                </span></> :
                <span className='deactive-btn my-auto cursor-pointer' onClick={() => handleVisibleClick(row)} >
                  <CloseIcon className=" btn-icon me-1" />
                  Inactive
                </span>}
                <Button variant="outlined-red" onClick={() => toggleModalpass(row)} className="primary-blue my-2" > Manage login</Button>
            </div>
          );
        }

      }
    },
  ];

  // Sample data source

  //Api call start
  const [data1, setItems] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
        security_key: security_key,
        mode: "getAllParentbyCenterParent",
        fk_cent_key: sessionStorage.getItem('key')
      });
      if (response.data.success === true) {
        setRecords(response.data.data);
        setItems(response.data.data);
        setLoading(false);

      } else {
        //alert message provide
        setLoading(false);

      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  //Api call End


  const [records, setRecords] = useState([]);

  const closePopupGeneratePayment = () => {
    setShowPopupGeneratePayment(false);
  };



  //search Functionality End

  return (
<>
      <ToastContainer position="top-center" />

    <div className="page-wrapper">

    <Modal show={showModalPass} onHide={toggleModalpass} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered >
                <Modal.Header closeButton>
                    <Modal.Title>Manage Login</Modal.Title>
                </Modal.Header>
                <Form onSubmit={formik3.handleSubmit}>
                    <Modal.Body>

                        {/* --------------Date--------------- */}

                        <div className='section-heading border-bottom pb-3 mb-3'>
                            <h6 className='mb-0'>Parent App Login</h6>
                        </div>
                        <Row>


                            {/* --------------Student Username--------------- */}

                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Username</h6>
                                <Form.Control type="text"
                                    name="pa_login_id"
                                    //readOnly
                                    value={formik3.values.pa_login_id}
                                    onChange={formik3.handleChange}
                                    // onBlur={handleBlur}
                                    id="pa_login_id"
                                    onBlur={formik3.handleBlur}
                                    className={formik3.errors.pa_login_id && formik3.touched.pa_login_id ? "input-error" : ""}
                                />
                                {formik3.errors.pa_login_id && formik3.touched.pa_login_id && <p className="error">{formik3.errors.pa_login_id}</p>}
                            </Col>

                            {/* --------------Student Password--------------- */}


                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Password</h6>
                                <Form.Control type={passwordVisible ? "text" : "password"}

                                    value={formik3.values.pa_password}
                                    onChange={formik3.handleChange}
                                    onBlur={formik3.handleBlur}
                                    id="pa_password"
                                    className={formik3.errors.pa_password && formik3.touched.pa_password ? "input-error" : ""}
                                    autocomplete="off"
                                />
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className="password-toggle-button pswicon" title="show / hide"
                                >
                                    {passwordVisible ? <FiEye /> : <FiEyeOff />}

                                </button>
                                {formik3.errors.pa_password && formik3.touched.pa_password && <p className="error">{formik3.errors.pa_password}</p>}
                            </Col>


                            {/* --------------Student Confirm Password--------------- */}

                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Confirm Password</h6>
                                <Form.Control type={ConpasswordVisible ? "text" : "password"}
                                    value={formik3.values.pa_conform_password}
                                    onChange={formik3.handleChange}
                                    onBlur={formik3.handleBlur}
                                    id="pa_conform_password"
                                    className={formik3.errors.pa_conform_password && formik3.touched.pa_conform_password ? "input-error" : ""}
                                    autocomplete="off"
                                />
                                <button
                                    type="button"
                                    onClick={toggleConPasswordVisibility}
                                    className="password-toggle-button pswicon" title="show / hide"
                                >
                                    {ConpasswordVisible ? <FiEye /> : <FiEyeOff />}

                                </button>
                                {formik3.errors.pa_conform_password && formik3.touched.pa_conform_password && <p className="error">{formik3.errors.pa_conform_password}</p>}
                            </Col>

                        </Row>


                    </Modal.Body>
                    <Modal.Footer>

                        <div className="mx-auto d-flex gap-2" >

                            <Button variant="contained" className="primary-gray" onClick={toggleModalpass}>Cancel</Button>
                            <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingPass}>Submit</Button>

                        </div>

                    </Modal.Footer>
                </Form>
            </Modal>
        <Modal show={showDelete} variant="danger">

        <Modal.Header>Parent</Modal.Header>

        <Modal.Body>
          <p>Are you sure? You want change to inactivate this parent? </p>

        </Modal.Body>
        <Modal.Footer className='pt-0'>
          <div className="mx-auto d-flex gap-2" >

            <Button variant="contained" className="primary-gray" onClick={() => setDeleteShow(false)}>Cancel</Button>
            <Button variant="contained" type='submit' className="primary-filled " disabled={isUserDeleteSubmitting} onClick={() => onSubmitDelete()} >Yes</Button>
          </div>

        </Modal.Footer>

      </Modal>

      <Modal show={showVisible} variant="danger">

        <Modal.Header>Parent</Modal.Header>

        <Modal.Body>
          <p>Are you sure? You want change to activate this parent? </p>

        </Modal.Body>
        <Modal.Footer className='pt-0'>
          <div className="mx-auto d-flex gap-2" >

            <Button variant="contained" className="primary-gray" onClick={() => setVisibleShow(false)}>Cancel</Button>
            <Button variant="contained" type='submit' className="primary-filled " disabled={isVisibleSubmitting} onClick={() => onSubmitVisible()} >Yes</Button>
          </div>

        </Modal.Footer>

      </Modal>

      {showPopupGeneratePayment && (
        <Dialog open={showPopupGeneratePayment} onClose={closePopupGeneratePayment}>
          <DialogTitle>Send Mail ({DataGP.par_firstname}   {DataGP.par_surname})</DialogTitle>
          <DialogContent>

            <Form autoComplete="off" onSubmit={formik2.handleSubmit} ref={formRef}>
              <Form.Group >
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  id="payment_emailid"

                  value={formik2.values.payment_emailid}
                  onChange={formik2.handleChange}
                  onBlur={formik2.handleBlur}
                  className={formik2.errors.payment_emailid && formik2.touched.payment_emailid ? "input-error mb-0 " : ""}
                />
                {formik2.errors.payment_emailid && formik2.touched.payment_emailid && <span className="error clearfix">{formik2.errors.payment_emailid}</span>}




                <Button variant="contained" className="primary-gray" onClick={() => setShowPopupGeneratePayment(false)}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingPayment} >Send</Button>
              </Form.Group>
            </Form>
          </DialogContent>
        </Dialog>
      )}

      {/* --------------Page Start--------------- */}


      {/* --------------Section Start--------------- */}

      <div className="mb-3 d-flex justify-content-between align-items-center">
        <div>
          <h5 className='page-title'> Parents </h5>

          <div role="presentation" >
            <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
              <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>



              <Typography color="text.primary">Parents</Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div>
          <div className='d-flex align-items-center gap-2'>
            {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}


            {/* <NavLink className="primary-filled rounded-10 p-2" to='/add-tutor'>
              <AddIcon className="btn-icon" /> New Tutor</NavLink> */}

          </div>
        </div>
      </div>
      <Card className='my-card bg-white'>

        <Card.Body>

          {/* -------------Content Start--------------- */}

          <Row>
            <Col xs="12">
              <div className="">

                <Row className="justify-content-center">

               



                  {/* --------------Table Content Start--------------- */}
                  <Col sm={12}>

                    <Box
                      sx={{
                        height: "fit-content",
                        width: "100%",
                        "& .actions": {
                          color: "text.secondary",
                        },
                        "& .textPrimary": {
                          color: "text.primary",
                        },
                        "& .super-app-theme--header": {
                          backgroundColor: "#e3e6ee",
                        },
                      }}
                      style={{ height: '100%', width: "100%" }}
                    >
                      <DataGrid
                      sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiDataGrid-row:hover': {
                          cursor: 'pointer'
                        }
                      }}
                        //sx={{ border: "none", m: 2 }}
                        autoHeight
                        slots={{
                          loadingOverlay: LinearProgress,
                          //noRowsOverlay: CustomNoRowsOverlay,
                          toolbar: GridToolbar,
                          // toolbar: EditToolbar,
                        }}
                        slotProps={{
                          toolbar: {
                            csvOptions: { fileName: "ParentList" },
                            printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "ParentList" },
                            records,
                            showQuickFilter: true
                          },


                        }}
                        initialState={{
                          ...records.initialState,
                          pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        showQuickFilter={true}

                        pageSizeOptions={[10, 25, 50, 100]}

                        loading={loading}
                        rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                        columns={columns}
                        rowHeight={60} density="compact"
                        onRowClick={(params) => handleRowClick(params.row)}
                        disableRowSelectionOnClick
                      showCellVerticalBorder= {true}
                      showColumnVerticalBorder= {true}

                      />


                    </Box>




                  </Col>
                  {/* --------------Table Content Start--------------- */}

                </Row>

              </div>
            </Col>
          </Row>

          {/* --------------Content End--------------- */}


        </Card.Body>

      </Card>

      {/* --------------Section End--------------- */}




      {/* --------------Page End--------------- */}

    </div>
    </>
  );
}

export default ListParent;