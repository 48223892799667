/*
Purpose: list details of report with use datatable
Developer: BY KTH
Date:22/08/2023 
Project: TCMS
*/

import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Moment from "moment";
import axios from "axios";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import {
    security_key,
    BASE_URL
} from "../global";

function DailyMerchantPayments() {
    const [loading, setLoading] = useState(true);
    
    const [data, setItems] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.post(BASE_URL + "API/api_index.php", {
                security_key: security_key,
                mode: "getDailyPGPaymentsReport",
                fk_cent_key: sessionStorage.getItem("key"),
            });
            
            if (response.data.success === true) {
                setRecords(response.data.data);
                setItems(response.data.data);
                setLoading(false);
            } else {
                //alert message provide
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    //Api call End

    
    const columns = [
        {
            headerName: "Lesson Fees(" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
            field: "amount",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
              return `${params.row.amount && params.row.amount != '' ?  (params.row.amount / 100).toFixed(2) : '-'}`;
            },
          },
        {
    
          headerName: "Payment Gateway Fee(" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
          field: "Fee",
          headerClassName: "super-app-theme--header",
          flex: 1,
          valueGetter: (params) => {
            if (params.row.amount && params.row.amount !== '') {
              const amount = params.row.amount / 100;
              const additionalAmount = amount * 0.027;
              const finalAmount = additionalAmount + 0.30;
              return finalAmount.toFixed(2);
            } else {
              return '-';
            }
          },
        },
        {
    
          headerName: 'Adjustments (Refunds, Credits, Dispute etc)',
          field: "refunded",
          headerClassName: "super-app-theme--header",
          flex: 1,
          valueGetter: (params) => {
            return `${params.row.refunded && params.row.refunded === true && params.row.refunded  != '' ? 'Refunds' : 'Credits'}`;
          },
        },
        {
          headerName: "Nett Settlement(" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
          field: "Nett_Settlement",
          headerClassName: "super-app-theme--header",
          flex: 1,
          valueGetter: (params) => {
            if (params.row.amount && params.row.amount !== '') {
              const amount = params.row.amount / 100;
              const additionalAmount = amount * 0.027;
              const finalAmount = amount - (additionalAmount + 0.30);
              return finalAmount.toFixed(2);
            } else {
              return '0';
            }
          },
        },
        {
          headerName: "Expected Payout Date",
          field: "created",
          headerClassName: "super-app-theme--header",
          flex: 1,
          valueGetter: (params) => {
            return `${params.row.created && params.row.created  != '' ? Moment.unix(params.row.created).format('DD-MM-YYYY') : '-'}`;
          },
        }
    
    
      ];

    const [records, setRecords] = useState(data);


    return (
        <div className="">
            {/* --------------Page Start--------------- */}

            <div className="mb-3 d-flex justify-content-between align-items-center">
                <div>
                    <h5 className="page-title"> Daily Merchant Statement Reports </h5>

                    <div role="presentation">
                        <Breadcrumbs aria-label="breadcrumb" className="my-breadcrumbs">
                            <NavLink underline="hover" color="inherit" to="/calendar">
                                {" "}
                                Home
                            </NavLink>
                            <NavLink underline="hover" color="inherit" to="/reports">
                                {" "}
                                Reports
                            </NavLink>
                            <Typography color="text.primary">Daily Merchant Statement Reports </Typography>
                        </Breadcrumbs>
                    </div>
                </div>
                <div className="d-flex align-items-center gap-2">
                    {/* <Button onClick={() => navigate('/reports')} className="secondary-filled" variant="contained">
            Back
          </Button> */}
                </div>
            </div>
            {/* --------------Section Start--------------- */}

            <Card className="card my-card mb-3">
                <Card.Body>
                    {/* -------------Content Start--------------- */}

                    <Row>
                        <Col xs="12">
                            <div className="">
                                <Row className="justify-content-center">
                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        <div className="d-flex flex-wrap justify-content-between mb-3">
                                            {/* <CSVLink
                                                data={csvData}
                                                filename={csvFileName}
                                                // className="btn btn-primary"
                                                target="_blank"
                                            >
                                                <i class="fa-solid fa-file-csv my-auto" style={{ fontSize: "24px" }}></i>
                                            </CSVLink>

                                            <div class="form-group has-search my-auto">
                                                <span class="fa fa-search form-control-feedback"></span>
                                                <input type="text" class="form-control mb-0" onChange={handleFilter} />
                                            </div> */}
                                        </div>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}

                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        {/* <DataTable
                                            progressPending={loading}
                                            columns={columns}
                                            data={records}
                                            fixedHeader
                                            pagination
                                            customStyles={customeStyle}
                                            className="cursor-pointer  list-cus-h"
                                        /> */}

                                    <Box
                                        sx={{
                                        height: "fit-content",
                                        width: "100%",
                                        "& .actions": {
                                            color: "text.secondary",
                                        },
                                        "& .textPrimary": {
                                            color: "text.primary",
                                        },
                                        "& .super-app-theme--header": {
                                            backgroundColor: "#e3e6ee",
                                        },
                                        }}
                                        style={{ height: '100%', width: "100%" }}
                                    >
                                    <DataGrid
                                                sx={{
                                                // disable cell selection style
                                                '.MuiDataGrid-cell:focus': {
                                                    outline: 'none'
                                                },
                                                // pointer cursor on ALL rows
                                                '& .MuiDataGrid-row:hover': {
                                                    cursor: 'pointer'
                                                }
                                                }}
                                        //sx={{ border: "none", m: 2 }}
                                        autoHeight
                                        slots={{
                                            loadingOverlay: LinearProgress,
                                            //noRowsOverlay: CustomNoRowsOverlay,
                                            toolbar: GridToolbar,
                                            // toolbar: EditToolbar,
                                        }}
                                        slotProps={{
                                            toolbar: {
                                            csvOptions: { fileName: "StatementList" },
                                            printOptions: { pageStyle: `@media print {
                                                    @page {
                                                        size: A4 landscape;
                                                        margin: 0;
                                                    }
                                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "StatementList" },
                                                    records,
                                            showQuickFilter: true
                                            },


                                        }}
                                        initialState={{
                                            ...records.initialState,
                                            pagination: { paginationModel: { pageSize: 10 } },
                                        }}
                                        showQuickFilter={true}

                                        pageSizeOptions={[10, 25, 50, 100]}

                                        loading={loading}
                                        rows={records.map((row, index) => ({ ...row, id: `${index+1}` }))}
                                        columns={columns}
                                        rowHeight={60} density="compact"
                                        disableRowSelectionOnClick
                                        showCellVerticalBorder= {true}
                                        showColumnVerticalBorder= {true}

                                        />


                                    </Box>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    {/* --------------Content End--------------- */}
                </Card.Body>
            </Card>

            {/* --------------Section End--------------- */}

            {/* --------------Page End--------------- */}
        </div>
    );
}

export default DailyMerchantPayments;
