

/*
Purpose: list details of student with use datatable
Designed by KTH
Date:24/08/2023 
Project: TCMS
*/

import { useState, useEffect } from "react";
import { useLocation, NavLink, useNavigate } from 'react-router-dom';
import { Row, Col,Card } from 'react-bootstrap';
import {
    security_key, BASE_URL
} from "../global";

import axios from 'axios';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Moment from 'moment';


function ReportCentreUnenrolment() {
    const location = useLocation();
  useEffect(() => {
    // Store the previous pathname in sessionStorage
    sessionStorage.setItem('previousPagePathname', location.pathname);
  }, [location.pathname]);
    const [loading, setLoading] = useState(true);

    const [data, setItems] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "getUnenrollmentReports",
                fk_cent_key: sessionStorage.getItem('key')
            });
           
            if (response.data.success === true) {
                setRecords(response.data.data);
                setItems(response.data.data);
                setLoading(false);
            } else {
                //alert message provide
                setLoading(false);
            }


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    //Api call End
    const calculateDuration = (startTime,endTime) => {
        const startTimeObject = new Date(`1970-01-01T${startTime}`);
        const endTimeObject = new Date(`1970-01-01T${endTime}`);
    
        // Check if start time is earlier than end time
        if (startTimeObject < endTimeObject) {
          // Calculate the time difference in milliseconds
          const timeDifference = endTimeObject - startTimeObject;
    
          // Convert the time difference to hours, minutes, and seconds
          const hours = Math.floor(timeDifference / 3600000);
          const minutes = Math.floor((timeDifference % 3600000) / 60000);
          const seconds = Math.floor((timeDifference % 60000) / 1000);
    
          // Format the duration
          return `${hours}:${minutes}`;
        } else {
          // Return an error message if the start time is not earlier than the end time
          return '0';
        }
      };
    // Sample columns configuration
    const columns = [

        {

            headerName: 'Reason for Unenrollment',
            field: "enrol_disc_reason",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.enrol_disc_reason == null || params.row.enrol_disc_reason == '' ? '-' : params.row.enrol_disc_reason}`;
            },
        }, {
            headerName: 'Student Name',
            field: "Student Name",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.st_first_name || ''} ${params.row.st_surname || ''}`;
            },

        },
        {

            headerName: 'Enrolled From',
            field: "enrol_start_date",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.enrol_start_date == null || params.row.enrol_start_date == '' ? '-' : Moment(params.row.enrol_start_date,'YYYY-MM-DD').format('DD-MM-YYYY')}`;
            },
        }, {

            headerName: 'Enrolled To',
            field: "sest_to_date",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.sest_to_date == null || params.row.sest_to_date == '' ? '-' : Moment(params.row.sest_to_date,'YYYY-MM-DD').format('DD-MM-YYYY')}`;
            },
        },
        {

            headerName: 'Enrollment Duration(HH:MM)',
            field: "sest_duration",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.sest_start_time == null || params.row.sest_start_time == '' ? '-' : calculateDuration(params.row.sest_start_time,params.row.sest_end_time)}`;
            },
        },
        {
            headerName: 'Subject',
            field: "subject_name",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.subject_name == null || params.row.subject_name == '' ? '-' : params.row.subject_name}`;
            },
        },

    ];



    const [records, setRecords] = useState(data);
   

    return (
        <div className="">

            {/* --------------Page Start--------------- */}

            <div className="mb-3 d-flex justify-content-between align-items-center">
                <div>
                    <h5 className='page-title'> Unenrolled Reports  </h5>

                    <div role="presentation" >
                        <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                            <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>
                            <NavLink underline="hover" color="inherit" to='/reports'> Reports</NavLink>
                            <Typography color="text.primary">Unenrolled Reports </Typography>
                        </Breadcrumbs>
                    </div>
                </div>
                <div className='d-flex align-items-center gap-2'>
                    {/* <Button onClick={() => navigate('/reports')} className="secondary-filled" variant="contained">
                        Back
                    </Button> */}

                </div>

            </div>
            {/* --------------Section Start--------------- */}

            <Card className='card my-card mb-3'>

                <Card.Body>

                    {/* -------------Content Start--------------- */}

                    <Row>
                        <Col xs="12">
                            <div className="">

                                <Row className="justify-content-center">

                                    {/* --------------Table Content Start--------------- */}
                                    {/* <Col sm={12}>

                                        <div className="d-flex flex-wrap justify-content-between mb-3">
                                            <CSVLink
                                                data={csvData}
                                                filename={csvFileName}
                                                // className="btn btn-primary"
                                                target="_blank"
                                            >
                                                <i class="fa-solid fa-file-csv my-auto" style={{ fontSize: '24px' }}></i>
                                            </CSVLink>

                                            <div class="form-group has-search my-auto">
                                                <span class="fa fa-search form-control-feedback"></span>
                                                <input type="text" class="form-control mb-0" onChange={handleFilter} />
                                            </div>


                                        </div>

                                    </Col> */}
                                    {/* --------------Table Content Start--------------- */}



                                    {/* --------------Table Content Start--------------- */}
                                    {/* <Col sm={12}>

                                        <DataTable
                                            progressPending={loading}
                                            columns={columns}
                                            data={records}
                                            fixedHeader
                                            pagination
                                            customStyles={customeStyle}
                                            className="cursor-pointer  list-cus-h"
                                        />

                                    </Col> */}

                                    <Box
                                        sx={{
                                            height: "fit-content",
                                            width: "100%",
                                            "& .actions": {
                                                color: "text.secondary",
                                            },
                                            "& .textPrimary": {
                                                color: "text.primary",
                                            },
                                            "& .super-app-theme--header": {
                                                backgroundColor: "#f4f4f4",
                                            },
                                            "& .MuiDataGrid-row": {
                                                border: '1px solid #eff0f2'
                                            },
                                            "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                                                outline: "none !important",
                                            },
                                            "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                                            {
                                                outline: "none !important",
                                            },
                                        }}
                                        style={{ height: '100%', width: "100%" }}
                                    >
                                       <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                                            //sx={{ border: "none", m: 2 }}
                                            autoHeight
                                            slots={{
                                                loadingOverlay: LinearProgress,
                                                //noRowsOverlay: CustomNoRowsOverlay,
                                                toolbar: GridToolbar,
                                                // toolbar: EditToolbar,
                                            }}
                                            slotProps={{
                                                toolbar: {
                                                    csvOptions: { fileName: "UnenrolmentReport" },
                                                    printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "UnenrolmentReport" },
                                                    records,
                                                    showQuickFilter: true
                                                },


                                            }}
                                            initialState={{
                                                ...records.initialState,
                                                pagination: { paginationModel: { pageSize: 10 } },
                                            }}
                                            showQuickFilter={true}

                                            pageSizeOptions={[10, 25, 50, 100]}

                                            loading={loading}
                                            rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                                            columns={columns}
                                            rowHeight={60} density="compact"
                                            disableRowSelectionOnClick
                                            showCellVerticalBorder={true}
                                            showColumnVerticalBorder={true}

                                        />

                                    </Box>
                                    {/* --------------Table Content Start--------------- */}

                                </Row>

                            </div>
                        </Col>
                    </Row>

                    {/* --------------Content End--------------- */}


                </Card.Body>

            </Card>

            {/* --------------Section End--------------- */}




            {/* --------------Page End--------------- */}

        </div>
    );
}

export default ReportCentreUnenrolment;