import React from 'react';

import ReactDOM from 'react-dom/client';


import reportWebVitals from './reportWebVitals';

import App from './App';
import '@fortawesome/fontawesome-free/css/all.min.css';



const root = ReactDOM.createRoot(document.getElementById('root'));
//const hist = createBrowserHistory();


root.render(
    <App />
);


reportWebVitals();
